import React, { useContext, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import callApi from '../api/api';
import { tokenIsValid, TokenContext } from '../auth/token'
import { ErrorListContext, logErrorString } from '../error/errorList'

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import { SubscriptionStatusContext, restrictPage } from '../context/subscriptionStatus'
import SubscriptionExpiredMessage from '../components/SubscriptionExpiredMessage'

import NotLoggedInMessage from '../components/NotLoggedInMessage'
import FileUploader from '../components/FileUploader'


function CollectionsCreateFromImportPage(props) {
  
  const [localFilename, setLocalFilename] = useState("");
  const [filenameError, setFilenameError] = useState(true);
  const [remoteFilename, setRemoteFilename] = useState("");
  
  const [token, setToken] = useContext(TokenContext);
  const [errorList, setErrorList] = useContext(ErrorListContext);
  const [subscriptionStatus, /*setSubscriptionStatus*/] = useContext(SubscriptionStatusContext);

  const navigate = useNavigate();
  
  const location = useLocation();

  // do not allow to proceed if not logged in
  //
  if (!tokenIsValid(token)) {
    return <NotLoggedInMessage />;
  }
  // nor if subscription expired and page is restricted
  //
  if (restrictPage(subscriptionStatus, "CollectionsCreateFromImportPage")) {
    return <SubscriptionExpiredMessage />;
  }
  
  const collectionName = location.state['collectionName'];
  
  //
  //
  const fileChanged = (localName, remoteName) => {
	console.log("fileChanged");
	console.log(localName);
	console.log(remoteName);
	
    setLocalFilename(localName);
    setFilenameError(setLocalFilename.length === 0);
    setRemoteFilename(remoteName);
  };
  
  //
  //
  const validateImport = async (event) => {
	console.log("validateImport");
    event.preventDefault();
	
	console.log("remoteFilename: " + remoteFilename)
	
	const data = {
	  "action": "create_job",
	  "payload": {
	    "job_name" : "import",
		"job_params": {
		  'mode': 'validateOnly',
		  'collection_name': collectionName,
		  'file_name': remoteFilename,
		}
	  }
	};
	
	const retVal = await callApi(token, setToken, data);
	// we'll get back the following:
	//  'collection_id' - will be None
	//  'error_list' - will be a list - could be empty
	console.log(retVal.data);
	logErrorString(retVal.errorMessage, errorList, setErrorList)
	
	const jobId = retVal.data['job_id']
	console.log(jobId);
	const started = retVal.data['started']
	console.log(started);
	
	if (jobId === null) {
	  console.log("FAILED TO CREATE - TODO ADD ALERT");
	} else {
	  navigate("/tasks/" + jobId);
	}
  };
  
  //
  //
  const completeImport = async (event) => {
	console.log("completeImport");
    event.preventDefault();
	
	console.log("remoteFilename: " + remoteFilename)
	
	const data = {
	  "action": "create_job",
	  "payload": {
	    "job_name" : "import",
		"job_params": {
		  'mode': 'validateAndImport',
		  'collection_name': collectionName,
		  'file_name': remoteFilename,
		}
	  }
	};
	
	const retVal = await callApi(token, setToken, data);
	console.log(retVal.data)
	logErrorString(retVal.errorMessage, errorList, setErrorList)	
	
	const jobId = retVal.data['job_id']
	console.log(jobId);
	const started = retVal.data['started']
	console.log(started);
	
	if (jobId === null) {
	  console.log("FAILED TO CREATE - TODO ADD ALERT");
	} else {
	  navigate("/tasks/" + jobId);
	}
  }
  
  //
  //
  const cancelHandler = (event) => {
	console.log("cancelHandler");
    event.preventDefault();
	navigate("/collections");
  };
  
  // set up helper text if needed
  //
  let filenameRequired = true;
  let filenameHelperText = "";
  if (filenameError === true) {
	filenameHelperText = "A unique name must be specified.";
  }
  
  return (
    <Grid container justifyContent="center">
      <Stack spacing={2}>
        <Grid container justifyContent="center">
		  <h1>Create Collection: {collectionName}</h1>
		</Grid>
        <Grid container justifyContent="center">
	      <Card variant="outlined">
	        <Box m={2}>
		      <FormControl>
		        <Stack spacing={2} >
				  <p>Select the filename to import from:</p>
			      <FileUploader
				    buttonText="Select file"
					destination='import'
				    useSelectedFileName={true}
					fileChanged={fileChanged}
				  />
		          <TextField
					id="Import-Filename"
					value={localFilename}
			        required={filenameRequired}
			        error={filenameError}
			        helperText={filenameHelperText}
			        sx={{mb: 3}}
			        multiline
			        maxRows={4}
					InputProps={{
					  readOnly: true,
					}}
		          />
				  <Grid container justifyContent="center">
				    <Stack direction="row" spacing={2}>
					  <Button variant="contained" disabled={filenameError} onClick={validateImport}>Validate</Button>
					  <Button variant="contained" disabled={filenameError} onClick={completeImport}>Import</Button>
					  <Button variant="contained" onClick={cancelHandler}>Cancel</Button>
				    </Stack>
				  </Grid>
				</Stack>
		      </FormControl>
			</Box>
		  </Card>
		</Grid>
	  </Stack>
    </Grid>
  );
}

export default CollectionsCreateFromImportPage;
