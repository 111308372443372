import React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';


function SupportReferenceManageEIPage() {
  return (
	<Box sx={{ width: 550 }}>
	  <Typography>
		When you click on an entry in the list of Example Items the page to view the individual Example Item is displayed. This page consists of the following elements
		<ul><li>A block showing the attributes for the Collectable Set linked with the Example Item, but only if this Example Item is part of a set, with a button to go and view that Collectable Set
		</li><li>A block showing the attributes for the Example Set this Example Item is part of, but only if this Example Item is part of a set, with a button to go and view that Example Set
		</li><li>A block showing the attributes for the Collectable Item, with a button to go and view that Collectable Item
		</li><li>A block showing the attributes for the Example Item, with buttons to be able to edit or delete it
		</li></ul>
	  </Typography>
	  <br/><br/>
	</Box>
  );
};

export default SupportReferenceManageEIPage;
