import React from 'react';
import { Link } from 'react-router-dom';

import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';


function SubscriptionExpiredMessage () {
  return (
    <Grid container justifyContent="center">
	  <Stack spacing={2}>
	    <Grid container justifyContent="center">
		  <h1>Invalid Page</h1>
	    </Grid>
	    <Grid container justifyContent="center">
		  <Stack justifyContent="space-between" alignItems="center" spacing={2}>
		    <p>This page is not available as your TRIAL subscription has expired.</p>
	        <p>Please go to <Link to="/account">Account</Link> to set up a new subscription.</p>
		  </Stack>
	    </Grid>
	  </Stack>
    </Grid>
  );
};

export default SubscriptionExpiredMessage;
