import React, { useState } from 'react';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';

function TabbedPages({tabs, minWidth}) {
  const [activeContentIndex, setActiveContentIndex] = useState(0);
  
  const Tag = tabs[activeContentIndex]["Child"];
  
  function buttonStyle (idx) {
    let style = "";
    if (activeContentIndex === idx) {
	  style = {minWidth: minWidth, backgroundColor: "#1976d2",};
    } else {
	  style = {minWidth: minWidth, backgroundColor: "#eaeaea",};
    }
	return style;
  }
  
  function buttonVariant (idx) {
    let variant = "";
    if (activeContentIndex === idx) {
	  variant = "contained";
    } else {
	  variant = "outlined";
    }
	return variant;
  }
  
  return (
    <Grid container justifyContent="center">
      <Stack spacing={2}>
        <Grid container justifyContent="center">
	    </Grid>
        <Grid container justifyContent="center">
	      <Stack direction="row" spacing={1}>
		    {tabs.map((tabItem, idx) => (
              <Button
			    style={buttonStyle(idx)}
                variant={buttonVariant(idx)}
                onClick={() => setActiveContentIndex(idx)}
              >
			    {tabItem["Name"]}
              </Button>
		    ))}
          </Stack>
	    </Grid>
        <Grid container justifyContent="center">
		  <Tag />
	    </Grid>
	  </Stack>
	</Grid>
  );
}

export default TabbedPages;
