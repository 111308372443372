import React, { useContext, useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import callApi from '../api/api';
import { tokenIsValid, TokenContext } from '../auth/token';
import { ErrorListContext, logErrorString } from '../error/errorList'

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';

import { SubscriptionStatusContext, restrictPage } from '../context/subscriptionStatus'
import SubscriptionExpiredMessage from '../components/SubscriptionExpiredMessage'

import NotLoggedInMessage from '../components/NotLoggedInMessage'


function CollectionsCreateFromCopyPage() {
  
  const [originalCollectionId, setOriginalCollectionId] = useState(0);
  const [modeValue, setModeValue] = React.useState('examples');
  const [collections, setCollections] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  
  const [token, setToken] = useContext(TokenContext);
  const [errorList, setErrorList] = useContext(ErrorListContext);
  const [subscriptionStatus, /*setSubscriptionStatus*/] = useContext(SubscriptionStatusContext);
  
  const navigate = useNavigate();
  
  const location = useLocation();

  const fetchDataCallback = useCallback( async () => {
	const data = {
	  "action": "get_collection_list",
	  "payload": { }
	};
	
	const retVal = await callApi(token, setToken, data);
	console.log(retVal)
	setCollections(retVal.data);
	if (retVal.data.length > 0) {
	  setOriginalCollectionId(retVal.data[0]['id']);
	} else {
	  setOriginalCollectionId(0);
	}
		
	setDataLoaded(true);
	logErrorString(retVal.errorMessage, errorList, setErrorList)
	
  }, [token, setToken]);

  useEffect(() => {
	console.log("CollectionsCreateFromCopyPage - useEffect");
	fetchDataCallback();
  }, [fetchDataCallback])
  
  // do not allow to proceed if not logged in
  //
  if (!tokenIsValid(token)) {
    return <NotLoggedInMessage />;
  }
  // nor if subscription expired and page is restricted
  //
  if (restrictPage(subscriptionStatus, "CollectionsCreateFromCopyPage")) {
    return <SubscriptionExpiredMessage />;
  }
  
  const collectionName = location.state['collectionName'];
  
  //
  //
  const createHandler = async (event) => {
	console.log("createHandler");
    event.preventDefault();

	console.log(originalCollectionId);
	console.log(modeValue);
	
	// send request to create the collection to the backend
	let data = {
	  "action": "create_job",
	  "payload": {
	    "job_name" : "copy_collection",
		"job_params": {
		  'id': originalCollectionId,
		  'Name': collectionName,
		  'Mode': modeValue,
		}
	  }
	};
	console.log(data);
	
	const retVal = await callApi(token, setToken, data);
	console.log(retVal);
	logErrorString(retVal.errorMessage, errorList, setErrorList)
	
	const jobId = retVal.data['job_id']
	console.log(jobId);
	const started = retVal.data['started']
	console.log(started);
	
	if (jobId === null) {
	  console.log("FAILED TO CREATE - TODO ADD ALERT");
	} else {
	  navigate("/tasks/" + jobId);
	}
  };
  
  //
  //
  const cancelHandler = (event) => {
	console.log("cancelHandler");
    event.preventDefault();
	navigate("/collections");
  };
  
  //
  //
  if (dataLoaded === false) {
    return (
	  <Grid container justifyContent="center">
		<Stack spacing={2}>
		  <Grid container justifyContent="center">
			<h1>Create Collection: {collectionName}</h1>
		  </Grid>
		  <Grid container justifyContent="center">
            <CircularProgress />
		  </Grid>
		</Stack>
	  </Grid>
	);
  }
  
  //
  //
  let collectionsJSX = (
    <Grid container justifyContent="center">
      <p>There are no collections available.</p>
	</Grid>
  );
  if (collections.length > 0) {
	collectionsJSX = (
	  <>
	  <FormLabel id="copy-item-select-label">Which collection do you want to copy:</FormLabel>
	  <Select
		labelId="copy-item-select-label"
		id="copy-item-select"
		value={originalCollectionId}
		onChange={(event) => {
		  setOriginalCollectionId(event.target.value);
		  }}
	  >
	    {collections.map((collection) => (
		  <MenuItem value={collection['id']}>{collection['Name']}</MenuItem>
		))}
	  </Select>
	  <FormLabel id="copy-mode-group-label">How much of the original collection do you want to copy:</FormLabel>
	  <RadioGroup
		aria-labelledby="copy-mode-group-label"
		value={modeValue}
		name="copy-mode-group"
		onChange={(event) => {setModeValue(event.target.value);}}
	  >
		<FormControlLabel value="definitions" control={<Radio />} label="Just the collection and attribute definitions." />
		<FormControlLabel value="collectables" control={<Radio />} label="Definitions and collectables information." />
		<FormControlLabel value="examples" control={<Radio />} label="Everything including examples information." />
	  </RadioGroup>
	  </>
    );
  }
  
  return (
    <Grid container justifyContent="center">
      <Stack spacing={2}>
        <Grid container justifyContent="center">
		  <h1>Create Collection: {collectionName}</h1>
		</Grid>
        <Grid container justifyContent="center">
	      <Card variant="outlined">
	        <Box m={2}>
		      <FormControl>
		        <Stack spacing={1} >
				  {collectionsJSX}
				  <Grid container justifyContent="center">
					<Stack direction='row' spacing={1} >
					  <Button variant="contained" disabled={collections.length === 0} type='submit' onClick={createHandler}>Create</Button>
					  <Button variant="contained" type='submit' onClick={cancelHandler}>Cancel</Button>
					</Stack>
				  </Grid>
		        </Stack>
              </FormControl>
		    </Box>
		  </Card>
	    </Grid>
      </Stack>
	</Grid>
  );
}

export default CollectionsCreateFromCopyPage;
