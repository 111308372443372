import React, { useState } from 'react';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';

import StyledCard from '../components/StyledCard'


function Wizard(props) {
  
  const [activeStep, setActiveStep] = useState(0);
  
  //
  //
  const handleNext = () => {
	console.log("Wizard: handleNext");
	const actionFunction = props.actionFunctions[activeStep]
	if (actionFunction !== null) {
	  actionFunction();
	}
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  
  //
  //
  const handleBack = () => {
	console.log("Wizard: handleBack");
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  
  const getStepContentFunction = props.stepContentFunctions[activeStep];
  const stepContentJSX = getStepContentFunction();
  
  return (
    <>
	  <Grid container justifyContent="center">
        <StyledCard>
	      <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
	        <Button
		      variant="contained"
		      startIcon={<KeyboardDoubleArrowLeftIcon/>}
	          disabled={activeStep === 0}
	          onClick={handleBack}
	        >
	          Back
	        </Button>
            <Stepper activeStep={activeStep} alternativeLabel>
              {props.stepTitles.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
	        <Button
		      variant="contained"
		      endIcon={<KeyboardDoubleArrowRightIcon/>}
	          disabled={activeStep === props.stepTitles.length - 1}
	          onClick={handleNext}
	        >
	          Next
	        </Button>
          </Stack>
	    </StyledCard>
	  </Grid>
      <Grid container justifyContent="center">
	    {stepContentJSX}
	  </Grid>
	</>
  );
}

export default Wizard;
