import React, { useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { tokenIsValid, TokenContext } from '../auth/token'

import Constants from '../Constants'
import {
  CurrentCollectionContext,
  checkCollectableItemsValidForCollection,
  checkExampleSetsValidForCollection
  } from '../context/currentCollection'

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';

import { SubscriptionStatusContext, restrictPage } from '../context/subscriptionStatus'
import SubscriptionExpiredMessage from '../components/SubscriptionExpiredMessage'

import NotLoggedInMessage from '../components/NotLoggedInMessage'
import EntityDetailViewUpdate from '../components/EntityDetailViewUpdate'
import EntityList from '../components/EntityList'
import EntityTitle from '../components/EntityTitle'


function CollectableSetDetailPage(props) {

  const [token, /*setToken*/] = useContext(TokenContext);
  const [currentCollection, /*setCurrentCollection*/] = useContext(CurrentCollectionContext);
  const [subscriptionStatus, /*setSubscriptionStatus*/] = useContext(SubscriptionStatusContext);

  const navigate = useNavigate();
  
  const params = useParams();

  if (!tokenIsValid(token)) {
    return <NotLoggedInMessage />;
  }
  // nor if subscription expired and page is restricted
  //
  if (restrictPage(subscriptionStatus, "CollectableSetDetailPage")) {
    return <SubscriptionExpiredMessage />;
  }
  
  //
  //
  const changeCollectableItemsHandler = (event) => {
	navigate("/collections/" + params.collectionId + "/cs/" + params.collectableSetId + "/ci");
  }
  
  //
  //
  const createExampleSetHandler = async (event) => {
	console.log("createExampleSetHandler");
    event.preventDefault();
	
	navigate("/collections/" + params.collectionId + "/es/" + params.collectableSetId + "/create");
  };
 
  // set up variables for View mode
  //
  let headerText = "View";
  let primaryDisplayMode = Constants.DisplayModeView;
  //
  // or for Edit mode
  //
  if (props.editMode === true) {
	  headerText = "Update";
	  primaryDisplayMode = Constants.DisplayModeUpdate;
  }
  
  let collectableItemsJSX = "";
  if (checkCollectableItemsValidForCollection(currentCollection, params.collectionId)) {
    collectableItemsJSX = (
      <Grid container justifyContent="center">
	    <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
		  <EntityTitle
		    title="Collectable Items"
		    entityType={Constants.CollectableItemEntity}
			variant="h5"
		  />
		  <Button variant="contained" onClick={changeCollectableItemsHandler}>Change</Button>
		</Stack>
		<EntityList
		  entityType="CIforCS"
		  title=""
		  getDataAction="get_collectable_set_linked_ci_list"
		  entityId={params.collectableSetId}
		  collectionId={params.collectionId}
		  baseUrlPath={"/collections/" + params.collectionId + "/ci/"}
		  height={275}
		  enableCheckboxSelection={false}
		  getNavigatableId={(id) => {
			// will be of format N_M
			// where N is the ci_id (we want)
			// and M is the sequence number of the CI linked to this CS
			// we want to return first part as an integer
			return (parseInt(id));
		  }}
	    />
	  </Grid>
	);
  }
  
  let exampleSetsJSX = "";
  if (checkExampleSetsValidForCollection(currentCollection, params.collectionId)) {
	exampleSetsJSX = (
	  <Grid container justifyContent="center">
		<Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
		  <EntityTitle
		    title="Example Sets"
		    entityType={Constants.ExampleSetEntity}
			variant="h5"
		  />
		  <Button variant="contained" onClick={createExampleSetHandler}>Create</Button>
		</Stack>
		<EntityList
		  entityType="ESforCS"
		  title=""
		  getDataAction="get_collectable_set_linked_es_list"
		  entityId={params.collectableSetId}
		  collectionId={params.collectionId}
		  baseUrlPath={"/collections/" + params.collectionId + "/es/"}
		  height={275}
		  enableCheckboxSelection={false}
		/>
	  </Grid>
	);
  }
  
  // user is logged in so proceed to display values
  //
  // CS => EntityDetailViewUpdate </p>
  // CI => EntityList </p>
  // ES => EntityList </p>
  //
  return (
	<Stack spacing={2}>
      <Grid container justifyContent="center">
		<EntityTitle
		  title={headerText + " Collectable Set"}
		  entityType={Constants.CollectableSetEntity}
		  variant="h4"
		/>
	  </Grid>
      <Grid container justifyContent="center">
	    <EntityDetailViewUpdate
		  title="Collectable Set attributes:"
	      displayMode={primaryDisplayMode}
		  entityId={params.collectableSetId}
		  collectionId={params.collectionId}
		  getDataActionName = "get_collectable_set"
		  saveDataActionName = "update_collectable_set"
		  deleteActionName = "delete_collectable_set"
		  baseUrlPath = {"/collections/"+params.collectionId+"/cs/"}
	    />
	  </Grid>
	  {collectableItemsJSX}
	  {exampleSetsJSX}
	</Stack>
  );
}

export default CollectableSetDetailPage;
