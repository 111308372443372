import React, { useContext } from 'react';

import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';

import { tokenIsValid, TokenContext } from '../auth/token'

import { SubscriptionStatusContext, restrictPage } from '../context/subscriptionStatus'
import SubscriptionExpiredMessage from '../components/SubscriptionExpiredMessage'

import NotLoggedInMessage from '../components/NotLoggedInMessage'
import FAQSet from '../components/FAQSet'

import howToItems from '../support_data/how_to_data.json'


function SupportHowToPage() {
  
  const [token, /*setToken*/] = useContext(TokenContext);
  const [subscriptionStatus, /*setSubscriptionStatus*/] = useContext(SubscriptionStatusContext);
  
  // do not allow to proceed if not logged in
  //
  if (!tokenIsValid(token)) {
    return <NotLoggedInMessage />;
  }
  // nor if subscription expired and page is restricted
  //
  if (restrictPage(subscriptionStatus, "SupportReferencePage")) {
    return <SubscriptionExpiredMessage />;
  }
  
  return (
    <Grid container justifyContent="center">
      <Stack spacing={2}>
        <Grid container justifyContent="center">
	        <h1>Your Support - How To</h1>
	    </Grid>
        <Grid container justifyContent="center">
		  <FAQSet faqItems={howToItems} />
	    </Grid>
	  </Stack>
	</Grid>
  );
}

export default SupportHowToPage;
