import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { Elements } from '@stripe/react-stripe-js';
import { useStripe } from '@stripe/react-stripe-js';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { stripePromise } from '../StripeConstants'
import { tokenIsValid, TokenContext } from '../auth/token'

import { SubscriptionStatusContext, restrictPage } from '../context/subscriptionStatus'
import SubscriptionExpiredMessage from '../components/SubscriptionExpiredMessage'

import NotLoggedInMessage from '../components/NotLoggedInMessage'


function ConfirmedPayment(props) {
  
  const stripe = useStripe();
  const [message, setMessage] = useState('Please wait...');

  useEffect(() => {
    if (!stripe) {
      return;
    }
    
    const accountLinkJSX = (
      <Grid container justifyContent="center">
	    <Typography>
	      Go to <Link to="/account" color="inherit">Account</Link> for more information.
	    </Typography>
	  </Grid>
    );
    
    // Retrieve the PaymentIntent
    stripe
      .retrievePaymentIntent(props.clientSecret)
      .then(({paymentIntent}) => {
        // Inspect the PaymentIntent `status` to indicate the status of the payment
        // to your customer.
        //
        // Some payment methods will [immediately succeed or fail][0] upon
        // confirmation, while others will first enter a `processing` state.
        //
        // [0]: https://stripe.com/docs/payments/payment-methods#payment-notification
        switch (paymentIntent.status) {
          case 'succeeded':
            setMessage(
			  <Grid container justifyContent="center">
			    <Stack spacing={0}>
				  <Grid container justifyContent="center">
				    <h3>Success!</h3>
				  </Grid>
				  <Grid container justifyContent="center">
			        <p>Your initial payment has succeeded and your card details saved for future payments.</p>
				  </Grid>
				  {accountLinkJSX}
				</Stack>
			  </Grid>
			);
            break;

          case 'processing':
            setMessage(
			  <Grid container justifyContent="center">
			    <Stack spacing={0}>
				  <Grid container justifyContent="center">
				    <h3>Payment is pending...</h3>
				  </Grid>
				  <Grid container justifyContent="center">
			        <p>Your initial payment is still being processed and we'll contact you if there are any issues.</p>
				  </Grid>
				  {accountLinkJSX}
				</Stack>
			  </Grid>
			);
            break;

          case 'requires_payment_method':
            // Redirect your user back to your payment page to attempt collecting
            // payment again
            setMessage(
			  <Grid container justifyContent="center">
			    <Stack spacing={0}>
				  <Grid container justifyContent="center">
				    <h3>Payment failed.</h3>
				  </Grid>
			        <p>Please try another payment option.</p>
				  <Grid container justifyContent="center">
				  </Grid>
				  {accountLinkJSX}
				</Stack>
			  </Grid>
			);
            break;
		  default:
		    console.log("ConfirmedPayment: value not handled: " + paymentIntent.status)
        }
      });
  }, [stripe, props.clientSecret]);
  
  return message;
}


function AccountPaymentCompletePage() {
  
  const [token, /*setToken*/] = useContext(TokenContext);
  const [subscriptionStatus, /*setSubscriptionStatus*/] = useContext(SubscriptionStatusContext);

  if (!tokenIsValid(token)) {
    return <NotLoggedInMessage />;
  }
  // nor if subscription expired and page is restricted
  //
  if (restrictPage(subscriptionStatus, "AccountPaymentCompletePage")) {
    return <SubscriptionExpiredMessage />;
  }

  // Retrieve the "payment_intent_client_secret" query parameter appended to
  // your return_url by Stripe.js
  const clientSecret = new URLSearchParams(window.location.search).get(
    'payment_intent_client_secret'
  );
  
  const options = {
    // passing the PaymentIntent's client secret
    clientSecret: clientSecret,
    // Fully customizable with appearance API.
    appearance: {/*...*/},
  };  
  
  return (
    <Grid container justifyContent="center">
      <Stack spacing={2}>
        <Grid container justifyContent="center">
          <h1>Your Account</h1>
	    </Grid>
        <Grid container justifyContent="center">
	      <Card variant="outlined"  sx={{ width: 350 }}>
	        <Box m={2}>
			  <Stack spacing={2}>
			    <Elements stripe={stripePromise} options={options}>
				  <ConfirmedPayment clientSecret={clientSecret} />
			    </Elements>
			  </Stack>
		    </Box>
	      </Card>
	    </Grid>
	  </Stack>
	</Grid>
  );
}

export default AccountPaymentCompletePage;
