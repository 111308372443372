import React from 'react';


import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';

import { allIconsLookup } from '../icons/icons';


const rowHeaders = [
  ["Planned", "Sets",],
  ["Planned", "No Sets",],
  ["Catlog",  "Sets",],
  ["Catlog",  "No Sets",],
  ["Ad-Hoc",  "Sets",],
  ["Ad-Hoc",  "No Sets",],
];

const columnTexts = [
  ["Collection",  ""],
  ["Collectable", "Set"],
  ["Collectable", "Item"],
  ["Example",     "Set"],
  ["Example",     "Item"],
];

function SupportReferenceAppendixImportExportPage() {
  return (
	<Box sx={{ width: 550 }}>
      <Grid>
	    <Stack spacing={3}>
	      {allIconsLookup.map((row, i) => (
	        <Stack direction="row" spacing={2}>
	          {row.map((itemIcon, j) => (
	            <Box sx={{ width: 90 }}>
		          <Grid container justifyContent="center">
                    <img src={itemIcon} width="40" height="40" alt="" />
			      </Grid>
		          <Grid container justifyContent="center">
			        {j===0 ? rowHeaders[i][0] : columnTexts[j-1][0]}
			      </Grid>
		          <Grid container justifyContent="center">
					{j===0 ? rowHeaders[i][1] : columnTexts[j-1][1]}
			      </Grid>
			    </Box>
		      ))}
		    </Stack>
	        ))}
	    </Stack>
	  </Grid>
	  <br/><br/>
	</Box>
  );
};

export default SupportReferenceAppendixImportExportPage;
