import { createContext } from 'react';

export const ReloadDataContext = createContext();


// Use these lines in code
//
/*
import { ReloadDataContext, triggerDataReload } from '../context/reloadData'
  
  const [reloadData, setReloadData] = useContext(ReloadDataContext);
    
    // trigger reload of data from the database
	// do this after call to 'navigate'
    triggerDataReload(reloadData, setReloadData);
*/


export function triggerDataReload (orignalValue, setFunction) {
  console.log("triggerDataReload");
  console.log(orignalValue);
  console.log(setFunction);
  let value = orignalValue + 1;
  setFunction(value);
}
