import React, { useState, useEffect } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { ErrorBoundary } from "react-error-boundary";

import { getToken, saveToken, TokenContext } from './auth/token'
import { ErrorListContext, getInitErrorList } from './error/errorList'
import { CurrentCollectionContext, initialCurrentCollectionInfo } from './context/currentCollection'
import { ReloadDataContext } from './context/reloadData'
import { SubscriptionStatusContext } from './context/subscriptionStatus'

import "./styles.css";

import ErrorPage from './pages/Error';
import HomePage from './pages/Home';

import { muiConfig } from './config/config';

/* Register and Login */
import RegisterPage from './pages/Register';
import VerifyPage from './pages/Verify';
import LoginPage from './pages/Login';
import ResetPasswordPage from './pages/ResetPassword'
import RequirePasswordPage from './pages/RequirePassword'
import ChangePasswordPage from './pages/ChangePassword'

/* Collections */
import CollectionsPage from './pages/Collections';
import CollectionDetailPage from './pages/CollectionDetail';
import CollectionsCreatePage from './pages/CollectionsCreate';
import CollectionsCreateFromScratchPage from './pages/CollectionsCreateFromScratch';
import CollectionsCreateFromCopyPage from './pages/CollectionsCreateFromCopy';
import CollectionsCreateFromTemplatePage from './pages/CollectionsCreateFromTemplate';
import CollectionsCreateFromImportPage from './pages/CollectionsCreateFromImport';

/* Collectable Sets */
import CollectableSetsPage from './pages/CollectableSetsPage'
import CollectableSetDetailPage from './pages/CollectableSetDetail'
import CollectableSetChangeItemsPage from './pages/CollectableSetChangeItems'
import CollectableSetCreatePage from './pages/CollectableSetCreate'

/* Collectable Items */
import CollectableItemsPage from './pages/CollectableItemsPage'
import CollectableItemDetailPage from './pages/CollectableItemDetail'
import CollectableItemCreatePage from './pages/CollectableItemCreate'

/* Example Sets */
import ExampleSetsPage from './pages/ExampleSetsPage'
import ExampleSetDetailPage from './pages/ExampleSetDetail'
import ExampleSetCreatePage from './pages/ExampleSetCreate'
import ExampleSetCreateAdHocPage from './pages/ExampleSetCreateAdHoc'

/* Example Items */
import ExampleItemsPage from './pages/ExampleItemsPage'
import ExampleItemDetailPage from './pages/ExampleItemDetail'
import ExampleItemCreatePage from './pages/ExampleItemCreate'

/* Attributes */
import AttributeDefinitionsPage from './pages/AttributeDefinitions'
import AttributeDefinitionDetailPage from './pages/AttributeDefinitionDetail'

/* Account Management */
import AccountPage from './pages/Account';
import PackageSelectionPage from './pages/PackageSelection'
import AccountPaymentPage from './pages/AccountPayment'
import AccountPaymentCompletePage from './pages/AccountPaymentComplete'

/* Support */
import SupportPage from './pages/Support';
import SupportItemsPage from './pages/SupportItems'
import SupportItemPage from './pages/SupportItem'
import SupportItemCreatePage from './pages/SupportItemCreate'
import SupportReferencePage from './pages/SupportReference'
import SupportHowToPage from './pages/SupportHowTo'
import PingPage from './pages/Ping';
import SupportErrorLogPage from './pages/SupportErrorLog'
import SupportTermsPage from './pages/SupportTerms'
import SupportPrivacyPage from './pages/SupportPrivacy'

/* Reports */
import ReportsPage from './reports/Reports';
import TestReportPage from './reports/TestReport';
import CollectionStatisticsReport from './reports/CollectionStatistics';
import AttributeUsageReport from './reports/AttributeUsage';

/* Tasks */
import TasksPage from './pages/Tasks';
import TaskDetailPage from './pages/TaskDetail';


import RootLayout from './pages/Root';

import { LicenseInfo } from '@mui/x-license-pro';
LicenseInfo.setLicenseKey(muiConfig['info']);


function DisplayErrorMessage({ error, resetErrorBoundary }) {
  // Call resetErrorBoundary() to reset the error boundary and retry the render.

  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre style={{ color: "red" }}>{error.message}</pre>
    </div>
  );
}


function App() {
  const [token, setToken] = useState(getToken());
  const [errorList, setErrorList] = useState(getInitErrorList());
  const [currentCollection, setCurrentCollection] = useState(initialCurrentCollectionInfo());
  const [reloadData, setReloadData] = useState(0);
  const [subscriptionStatus, setSubscriptionStatus] = useState({subs_type:'', subs_end:''});
  
  useEffect(() => {
    const handleGlobalError = (event) => {
      console.error(event.error);
    };
    window.addEventListener('error', handleGlobalError);
    return () => {
      window.removeEventListener('error', handleGlobalError);
    };
  }, []);
  
  useEffect(() => {
    saveToken(token);
  }, [token])
  
  const router = createBrowserRouter([
    {
      path: '/',
      element: <RootLayout />,
      errorElement: <ErrorPage />,
      children: [
        { path: '/', element: <HomePage /> },
        { path: '/register', element: <RegisterPage /> },
        { path: '/verify', element: <VerifyPage /> },
        { path: '/login', element: <LoginPage /> },
        { path: '/reset-password', element: <ResetPasswordPage /> },
        { path: '/require-password', element: <RequirePasswordPage /> },
        { path: '/change-password', element: <ChangePasswordPage /> },
		
		/* Collections */
        { path: '/collections', element: <CollectionsPage /> },
        { path: '/collections/:collectionId', element: <CollectionDetailPage editMode={false} /> },
        { path: '/collections/:collectionId/update', element: <CollectionDetailPage editMode={true} /> },
        { path: '/collections/create', element: <CollectionsCreatePage /> },
        { path: '/collections/create/from_scratch', element: <CollectionsCreateFromScratchPage /> },
        { path: '/collections/create/from_copy', element: <CollectionsCreateFromCopyPage /> },
        { path: '/collections/create/from_template', element: <CollectionsCreateFromTemplatePage /> },
        { path: '/collections/create/from_import', element: <CollectionsCreateFromImportPage /> },
		
		/* Collectable Sets */
        { path: '/collections/:collectionId/cs', element: <CollectableSetsPage /> },
        { path: '/collections/:collectionId/cs/:collectableSetId', element: <CollectableSetDetailPage editMode={false} /> },
        { path: '/collections/:collectionId/cs/:collectableSetId/update', element: <CollectableSetDetailPage editMode={true} /> },
        { path: '/collections/:collectionId/cs/:collectableSetId/ci', element: <CollectableSetChangeItemsPage /> },
        { path: '/collections/:collectionId/cs/create', element: <CollectableSetCreatePage /> },
		
		/* Collectable Items */
        { path: '/collections/:collectionId/ci', element: <CollectableItemsPage /> },
        { path: '/collections/:collectionId/ci/:collectableItemId', element: <CollectableItemDetailPage editMode={false} /> },
        { path: '/collections/:collectionId/ci/:collectableItemId/update', element: <CollectableItemDetailPage editMode={true} /> },
        { path: '/collections/:collectionId/ci/create', element: <CollectableItemCreatePage /> },
		
		/* Example Sets */
        { path: '/collections/:collectionId/es', element: <ExampleSetsPage /> },
        { path: '/collections/:collectionId/es/:exampleSetId', element: <ExampleSetDetailPage editMode={false} /> },
        { path: '/collections/:collectionId/es/:exampleSetId/update', element: <ExampleSetDetailPage editMode={true} /> },
        { path: '/collections/:collectionId/es/:collectableSetId/create', element: <ExampleSetCreatePage /> },
        { path: '/collections/:collectionId/es/create', element: <ExampleSetCreateAdHocPage /> },
		
		/* Example Items */
        { path: '/collections/:collectionId/ei', element: <ExampleItemsPage /> },
        { path: '/collections/:collectionId/ei/:exampleItemId', element: <ExampleItemDetailPage editMode={false} /> },
        { path: '/collections/:collectionId/ei/:exampleItemId/update', element: <ExampleItemDetailPage editMode={true} /> },
        { path: '/collections/:collectionId/ei/:collectableItemId/create', element: <ExampleItemCreatePage /> },
        { path: '/collections/:collectionId/ei/create', element: <ExampleItemCreatePage isAdHoc={true}/> },
		
		/* Attributes */
        { path: '/collections/:collectionId/attributes', element: <AttributeDefinitionsPage /> },
        { path: '/collections/:collectionId/attributes/create/:entityType', element: <AttributeDefinitionDetailPage editMode={true} isCreating={true} /> },
        { path: '/collections/:collectionId/attributes/:attributeDefinitionId', element: <AttributeDefinitionDetailPage editMode={false} isCreating={false} /> },
        { path: '/collections/:collectionId/attributes/:attributeDefinitionId/update', element: <AttributeDefinitionDetailPage editMode={true} isCreating={false} /> },
		
		/* Account Management */
        { path: '/account', element: <AccountPage /> },
        { path: '/account/package', element: <PackageSelectionPage /> },
        { path: '/account/payment', element: <AccountPaymentPage /> },
        { path: '/account/payment_complete', element: <AccountPaymentCompletePage /> },
		
		/* Support */
        { path: '/support', element: <SupportPage /> },
        { path: '/support/support_items', element: <SupportItemsPage /> },
        { path: '/support/support_items/:itemId', element: <SupportItemPage /> },
        { path: '/support/support_items/create', element: <SupportItemCreatePage /> },
        { path: '/support/reference', element: <SupportReferencePage /> },
        { path: '/support/how_to', element: <SupportHowToPage /> },
        { path: '/support/ping', element: <PingPage /> },
        { path: '/support/error_log', element: <SupportErrorLogPage /> },
        { path: '/support/terms', element: <SupportTermsPage /> },
        { path: '/support/privacy', element: <SupportPrivacyPage /> },
		
		/* Reports */
        { path: '/reports', element: <ReportsPage /> },
        { path: '/reports/standard', element: <ReportsPage /> },
        { path: '/reports/standard/test_report', element: <TestReportPage /> },
	  //            NOTE - put other reports below here...
        { path: '/reports/standard/collection_statistics', element: <CollectionStatisticsReport /> },
        { path: '/reports/standard/attribute_usage', element: <AttributeUsageReport /> },
      //{ path: '/reports/standard/XXX', element: <XXXReportPage /> },
	    
		/* Tasks */
        { path: '/tasks', element: <TasksPage /> },
        { path: '/tasks/:jobId', element: <TaskDetailPage /> },
      ],
    }
  ]);

  return (
	<ErrorBoundary
      FallbackComponent={DisplayErrorMessage}
      onReset={(details) => {
        // Reset the state of your app so the error doesn't happen again
      }}
    >
    <ErrorListContext.Provider value={[errorList, setErrorList]}>
	  <TokenContext.Provider value={[token, setToken]}>
	    <CurrentCollectionContext.Provider value={[currentCollection, setCurrentCollection]}>
		  <ReloadDataContext.Provider value={[reloadData, setReloadData]}>
		    <SubscriptionStatusContext.Provider value={[subscriptionStatus, setSubscriptionStatus]}>
		      <RouterProvider router={router} />
			</SubscriptionStatusContext.Provider>
		  </ReloadDataContext.Provider>
		</CurrentCollectionContext.Provider>
	  </TokenContext.Provider>
	</ErrorListContext.Provider>
	</ErrorBoundary>
  );
}

export default App;
