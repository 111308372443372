import React, { useState, useContext, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import callApi from '../api/api';
import { TokenContext } from '../auth/token';
import { ErrorListContext, logErrorString } from '../error/errorList'
import { ReloadDataContext } from '../context/reloadData'

import Constants from '../Constants';

import AttributeDefinitionInList from '../components/AttributeDefinitionInList';
import StyledCard from '../components/StyledCard';


function AttributeDefinitionsList({entityType, tabIndex}) {

  const params = useParams();
  const [token, setToken] = useContext(TokenContext);

  const navigate = useNavigate();

  const [attributes, setAttributes] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  
  const [errorList, setErrorList] = useContext(ErrorListContext);
  const [reloadData, /*setReloadData*/] = useContext(ReloadDataContext);

  const fetchDataCallback = useCallback( async () => {
	const data = {
	  "action": "get_attribute_list",
	  "payload": {
		'collection_id': params.collectionId,
		'table_id': entityType
		}
	};
	
	const retVal = await callApi(token, setToken, data);
	console.log(retVal)
	setAttributes(retVal.data);
	setDataLoaded(true);
	logErrorString(retVal.errorMessage, errorList, setErrorList)
 }, [token, setToken, entityType, params.collectionId]);

  useEffect(() => {
	fetchDataCallback();
  }, [fetchDataCallback,reloadData])
  
  //
  //
  const createAttributeHandler = async (event) => {
	console.log("createAttributeHandler");
    event.preventDefault();
	// Note - passes "tabIndex" into location.state so can return back to correct tab when done creating new attribute
	navigate(
	  "/collections/" + params.collectionId + "/attributes/create/" + entityType,
	  {state: { 'tabIndex': tabIndex}}
	);
  };
  
  let listJSX = (
    <CircularProgress />
  );
  if (dataLoaded === true) {
	if (attributes.length === 0) {
	  listJSX = (
        <StyledCard>
	      <Stack spacing={2} alignItems="center">
	        <p/>
	        <Typography>There are no attributes for {Constants.EntityTypeName(entityType)} yet.</Typography>
	        <Typography>Click on "Create" to get started...</Typography>
	        <p/>
	      </Stack>
	    </StyledCard>
	  );
	} else {
	  listJSX = (
	    <Stack spacing={1}>
		  {attributes.map((attribute, i) => (
		    <Grid item sx={{minWidth: '350px', maxWidth: '650px'}} >
			  <AttributeDefinitionInList attribute={attribute} tabIndex={tabIndex} />
		    </Grid>
		  ))}
	    </Stack>
	  );
	}
  }

  return (
    <Stack spacing={1}>
	  <Grid container justifyContent="center">
		<Button variant="contained" onClick={createAttributeHandler}>Create</Button>
	  </Grid>
	  <Grid container justifyContent="center" >
	    {listJSX}
	  </Grid>
    </Stack>
  );
}

export default AttributeDefinitionsList;
