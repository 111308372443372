import React, { useContext, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import Constants from '../Constants'
import callApi from '../api/api';
import { tokenIsValid, TokenContext } from '../auth/token'
import { ErrorListContext, logErrorString } from '../error/errorList'
import { ReloadDataContext, triggerDataReload } from '../context/reloadData'

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { SubscriptionStatusContext, restrictPage } from '../context/subscriptionStatus'
import SubscriptionExpiredMessage from '../components/SubscriptionExpiredMessage'

import NotLoggedInMessage from '../components/NotLoggedInMessage'
import EntityDetailViewUpdate from '../components/EntityDetailViewUpdate'
import Wizard from '../components/Wizard'
import EntityTitle from '../components/EntityTitle'
import StyledCard from '../components/StyledCard'


function ExampleSetCreateAdHocPage(props) {

  const [updatedESValues, setUpdatedESValues] = useState({});
  const [exampleSetId, setExampleSetId] = useState(0);
  
  const [token, setToken] = useContext(TokenContext);
  const [errorList, setErrorList] = useContext(ErrorListContext);
  const [reloadData, setReloadData] = useContext(ReloadDataContext);
  const [subscriptionStatus, /*setSubscriptionStatus*/] = useContext(SubscriptionStatusContext);
  
  const params = useParams();

  const navigate = useNavigate();

  // do not allow to proceed if not logged in
  //
  if (!tokenIsValid(token)) {
    return <NotLoggedInMessage />;
  }
  // nor if subscription expired and page is restricted
  //
  if (restrictPage(subscriptionStatus, "ExampleSetCreateAdHocPage")) {
    return <SubscriptionExpiredMessage />;
  }
   
  //
  //
  const saveExampleSetAttributes = async () => {
	console.log("saveExampleSetAttributes");
    console.log(updatedESValues);
	
	// add in id for the entity to be updated
	updatedESValues["collection_id"] = params.collectionId;
	console.log(updatedESValues);
	
	// send request to update the entity to the backend
	let data = {
	  "action": 'create_example_set',
	  "payload": updatedESValues
	};
	
	const retVal = await callApi(token, setToken, data);
	console.log(retVal)
	logErrorString(retVal.errorMessage, errorList, setErrorList)
	
	let newExampleSetId = retVal.data['id']
	console.log(newExampleSetId);
	setExampleSetId(newExampleSetId);
    
    // trigger reload of data from the database
    triggerDataReload(reloadData, setReloadData);
  };
  
  //
  //
  const step0JSX = () => {
	return (
	  <EntityDetailViewUpdate
	    title="Example Set attributes:"
	    displayMode={Constants.DisplayModeCreate}
	    entityId={""}
	    collectionId={params.collectionId}
	    getDataActionName = "get_example_set"
	    saveDataActionName = {null}
	    deleteActionName = {null}
	    baseUrlPath = {null}
	    setParentUpdatedValues = {setUpdatedESValues}
		noRemainFlag = {true}
		noCancelButton = {true}
	  />
	);
  }
  
  //
  //
  const step1aJSX = () => {
	return (
	  <StyledCard>
	    <Grid container justifyContent="center">
	      <Typography>
		    The Example Set has now been created.
		  </Typography>
	    </Grid>
		<br/>
	    <Grid container justifyContent="center">
	      <Typography>
		    In the next step fill in the attributes for each Example Item and cick on Create button.
		  </Typography>
	    </Grid>
	    <Grid container justifyContent="center">
	      <Typography>
		    Do this multiple times to create all the Example Items for the set.
		  </Typography>
	    </Grid>
		<br/>
	    <Grid container justifyContent="center">
	      <Typography>
		    Once you've done this for all Example Items then click on Next button.
		  </Typography>
	    </Grid>
	  </StyledCard>
	);
  }
  
  //
  //
  const step1JSX = () => {
    
    const esId = {
	  "example_set_id": exampleSetId,
    };
	
	return (
	  <Stack spacing={2}>
	  <StyledCard>
	    <Stack spacing={0}>
	      <Grid container justifyContent="center">
	        <Typography>
		      Remember to fill in the form and cick on the Create button below,
		    </Typography>
	      </Grid>
	      <Grid container justifyContent="center">
	        <Typography>
			  for <b>each</b> Example Item you want to create.
		    </Typography>
	      </Grid>
		</Stack>
	  </StyledCard>
	  <EntityDetailViewUpdate
		title="Example Item attributes:"
		displayMode={Constants.DisplayModeCreate}
		entityId={''}
		collectionId={params.collectionId}
		additionalEntityId={esId}
		getDataActionName = "get_example_item"
		saveDataActionName = "create_example_item"
		deleteActionName = {null}
		baseUrlPath = {null}
		noRemainFlag = {true}
		forceRemainFlag = {true}
		noCancelButton = {true}
	  />
	  </Stack>
	);
  }
  
  //
  //
  const handleGotoExampleSet = () => {
	navigate("/collections/" + params.collectionId + "/es/" + exampleSetId);
  }
  
  //
  //
  const step2JSX = () => {
	return (
	  <StyledCard>
	  <Stack spacing={2}>
	    <Grid container justifyContent="center">
          <Typography> </Typography>
	    </Grid>
	    <Grid container justifyContent="center">
		    <Typography>All done!</Typography>
	    </Grid>
	    <Button
		  variant="contained"
		  onClick={handleGotoExampleSet}
	    >
		  View Example Set
	    </Button>
      </Stack>
	  </StyledCard>
	);
  }
  
  const stepTitles = [
    'Set attributes for Example Set',
	'Instruction to create Example Items',
    'Create all the Example Items to add to the set',
    'Finished',
  ];
  const actionFunctions = [
    saveExampleSetAttributes, null, null
  ]
  const stepContentFunctions = [
    step0JSX, step1aJSX, step1JSX, step2JSX,
  ]
  
  return (
	<Stack spacing={2}>
      <Grid container justifyContent="center">
		<EntityTitle
		  title="Create Example Set"
		  entityType={Constants.ExampleSetEntity}
		  variant="h4"
		/>
	  </Grid>
	  <Wizard
	    stepTitles={stepTitles}
	    actionFunctions={actionFunctions}
		stepContentFunctions={stepContentFunctions}
	  />
    </Stack>
  );
}

export default ExampleSetCreateAdHocPage;
