
export const PageRestrictionDefinitions = [

  {"name":"AccountPage",						"canBeRestricted":false},
  {"name":"AccountPaymentPage",					"canBeRestricted":false},
  {"name":"AccountPaymentCompletePage",			"canBeRestricted":false},
  {"name":"AttributeDefinitionDetailPage",		"canBeRestricted":true},
  {"name":"AttributeDefinitionsPage",			"canBeRestricted":true},
  {"name":"AttributeUsageReport",				"canBeRestricted":true},
  {"name":"ChangePasswordPage",					"canBeRestricted":false},
  {"name":"CollectableItemCreatePage",			"canBeRestricted":true},
  {"name":"CollectableItemDetailPage",			"canBeRestricted":true},
  {"name":"CollectableItemsPage",				"canBeRestricted":true},
  {"name":"CollectableSetChangeItemsPage",		"canBeRestricted":true},
  {"name":"CollectableSetCreatePage",			"canBeRestricted":true},
  {"name":"CollectableSetDetailPage",			"canBeRestricted":true},
  {"name":"CollectableSetsPage",				"canBeRestricted":true},
  {"name":"CollectionDetailPage",				"canBeRestricted":true},
  {"name":"CollectionStatisticsReport",			"canBeRestricted":true},
  {"name":"CollectionsPage",					"canBeRestricted":true},
  {"name":"CollectionsCreate",					"canBeRestricted":true},
  {"name":"CollectionsCreateFromCopyPage",		"canBeRestricted":true},
  {"name":"CollectionsCreateFromImportPage",	"canBeRestricted":true},
  {"name":"CollectionsCreateFromScratchPage",	"canBeRestricted":true},
  {"name":"CollectionsCreateFromTemplate",		"canBeRestricted":true},
  {"name":"ExampleItemCreatePage",				"canBeRestricted":true},
  {"name":"ExampleItemDetailPage",				"canBeRestricted":true},
  {"name":"ExampleItemsPage",					"canBeRestricted":true},
  {"name":"ExampleSetCreatePage",				"canBeRestricted":true},
  {"name":"ExampleSetCreateAdHocPage",			"canBeRestricted":true},
  {"name":"ExampleSetDetailPage",				"canBeRestricted":true},
  {"name":"ExampleSetsPage",					"canBeRestricted":true},
  {"name":"PackageSelectionPage",				"canBeRestricted":false},
  {"name":"PingPage",							"canBeRestricted":false},
  {"name":"ReportsPage",						"canBeRestricted":true},
  {"name":"SupportPage",						"canBeRestricted":false},
  {"name":"SupportErrorLogPage",				"canBeRestricted":false},
  {"name":"SupportItemPage",					"canBeRestricted":false},
  {"name":"SupportItemCreatePage",				"canBeRestricted":false},
  {"name":"SupportItemsPage",					"canBeRestricted":false},
  {"name":"SupportReferencePage",				"canBeRestricted":false},
  {"name":"SupportHowToPage",					"canBeRestricted":false},
  {"name":"TaskDetailPage",						"canBeRestricted":true},
  {"name":"TasksPage",							"canBeRestricted":true},
  {"name":"TestReport",							"canBeRestricted":true},

];


/*
	NOTE - the following pages are deliberately not listed above as they do not have controls on DisplayNames

		ErrorPage
		HomePage
		LoginPage
		RegisterPage
		RequirePasswordPage
		ResetPasswordPage
		RootLayout
		VerifyPage
*/


//######################################################################################################
//
//import { SubscriptionStatusContext, restrictPage } from '../context/subscriptionStatus'
//import SubscriptionExpiredMessage from '../components/SubscriptionExpiredMessage'
//
//
//  const [subscriptionStatus, /*setSubscriptionStatus*/] = useContext(SubscriptionStatusContext);
//
//
//  // do not allow to proceed if not logged in
//  //
//  if (!tokenIsValid(token)) {
//    return <NotLoggedInMessage />;
//  }
//  // nor if subscription expired and page is restricted
//  //
//  if (restrictPage(subscriptionStatus, "XXXXXX")) {
//    return <SubscriptionExpiredMessage />;
//  }
//
//######################################################################################################
