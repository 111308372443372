import React from 'react';

import TileGrid from '../components/TileGrid'


// How
// Provide summary of how it works.

function HomeInfoHowItWorks () {
  const content = [
    [
	  ["Create", "Each of your collections requires a unique name (but only across your own collections) which you specify when you create it.\n-\nThen you decide the structure you want to use from 3 options: a catalog, a planned collection, or an ad-hoc (unplanned) collection.\n-\nIn addition you specify whether your collection will include sets and not just individual items - this would allow you to store additional information about the sets themselves as well as the items within each set.\n-\nThere are multiple options in how the new collection can be created: using one of our templates, import from Excel, copy some or all of an existing collection, or build up from scratch", 425, ],
	],
    [
	  ["Configure", "Once the collection is created the next step is to define or update the data fields (which we call attributes).\n-\nThese hold information for each of the types of items and sets you could collect, as you would find in a catalog and we call Collectables, and for the items and sets you actually own, which we call Examples.", 425, ],
	],
    [
	  ["Manage", "Now you are ready to use the Your Collections portal to create entries to record information about your collection, as well as update that information as your collection grows and changes.\n-\nYou can also browse / search your collection information using the comprehensive and flexible filtering options.", 425, ],
	],
  ];
  return (
	<TileGrid content={content} />
  );
}

export default HomeInfoHowItWorks;
