import React from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import attribute_6 from '../support_data/attribute_6.png'


function SupportReferenceAttrTypesPage() {
  return (
	<Box sx={{ width: 550 }}>
	  <Typography>
	    The following are the different types of attributes you can create
		<ul><li>Text – can contain any text
		</li><li>Date – set a date by entering the date or using a date picker
		</li><li>Number – allows only integer numbers to be entered
		</li><li>Dropdown – allows one of a set of defined options to be selected
		</li><li>Flag – allows either a Yes or No option to be selected
		</li><li>Image – allows upload of an image file
		</li></ul>
		Note that when creating a Dropdown attribute, the list of options needs to be defined as well.
	  </Typography>
	  <br />
      <Grid container justifyContent="center">
        <img src={attribute_6} width="411" height="271" alt="" />
	  </Grid>
	  <br/>
	  <Typography>
	    <ul><li>To add an option, type in the text and click Add button
		</li><li>To remove an option, click on the option text and drag it to outside the white background area and release the mouse button.
		</li><li>To change the order of the options, click on an option and drag it up or down in the list, dropping it where you want it to be placed.
		</li></ul>
		NOTE - other attribute types will be added in future updates to the portal.
	  </Typography>
	  <br/><br/>
	</Box>
  );
};

export default SupportReferenceAttrTypesPage;
