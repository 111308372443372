import React from 'react';

import Typography from '@mui/material/Typography';


function FlagAttributeConfiguration (props) {
	// for Flag we ignore the props.editMode passed in
	return (
	  <Typography>
	    No configuration required for Flag attributes
	  </Typography>
	);
}

export default FlagAttributeConfiguration;
