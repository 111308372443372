import React from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import attribute_5 from '../support_data/attribute_5.png'


function SupportReferenceAttrChangePage() {
  return (
	<Box sx={{ width: 550 }}>
	  <Typography>
	    You can click on an attribute from the list for the currently selected entity type to view how that attribute is defined.
	  </Typography>
	  <br />
      <Grid container justifyContent="center">
        <img src={attribute_5} width="551" height="537" alt="" />
	  </Grid>
	  <br/>
	  <Typography>
	    To change the definition, click on the Edit button and update the definition as required, then click on Save button.
		<br/><br/>Note that at this stage you cannot change the type of an attribute once it is created but will be able to in a future update to the portal.
		<br/><br/>To delete the attribute, click on the Delete button and you will be asked to confirm that that is what you want. Once deleted you will be returned to the list of attributes for the currently selected entity type.
		<br/><br/>WARNING – if you delete an attribute from an entity type, then all information stored against the entities themselves will be deleted and cannot be recovered. For example, if you have an attribute called “Date of purchase” defined for Example Items, and you delete that attribute, then all information relating to the date of purchase for all the items in your collection will be lost.
	  </Typography>
	  <br/><br/>
	</Box>
  );
};

export default SupportReferenceAttrChangePage;
