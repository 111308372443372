import React from 'react';
import { Link } from 'react-router-dom';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import CollectionInList from '../components/CollectionInList';
import StyledCard from '../components/StyledCard';


function CollectionsList({collections}) {
  
  console.log("collections")
  console.log(collections)
  
  let collectionListJSX = ""
  if (collections.length === 0) {
    collectionListJSX = (
      <StyledCard>
	  <Stack spacing={2} alignItems="center">
	    <p/>
	    <Typography>Click on "Create Collection" to get started...</Typography>
        <Stack direction="row" spacing={1}>
	      <Typography>For help go to our</Typography>
		  <Link to="/support">Support</Link>
	      <Typography>page.</Typography>
		</Stack>
	    <p/>
	  </Stack>
	  </StyledCard>
	  );
  } else {
    collectionListJSX = (
	  <Box sx={{ width: '100%', maxWidth: 360 }}>
	    <Stack spacing={2}>
	      {collections.map((collection, i) => (
		    <CollectionInList collection={collection} />
	      ))}
	    </Stack>
	  </Box>
	  );
  }
  
  return (
    <>
	  {collectionListJSX}
	</>
  );
}

export default CollectionsList;
