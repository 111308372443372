import React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';


function SupportReferenceAppendixImportExportPage() {
  return (
	<Box sx={{ width: 550 }}>
	  <Typography>
		Your Collections Online uses a proprietary format Excel spreadsheet when you export a collection, and the same format to create a new collection by importing it.
		<br/><br/>The best way to see the format in action is to export one of your collections and review the resulting Excel.
		<br/><br/>However here is a summary description of the Excel format with an emphasis on how the content should be set up to use to import a collection’s data.
		<br/><br/>The Excel file has several tabs as follows:
		<ul><li>Collection
		</li><li>Attributes
		</li><li>CollectableSets
		</li><li>CollectableItems
		</li><li>CIinCS
		</li><li>ExampleSets
		</li><li>ExampleItems
		</li></ul>
		Each tab and the format and contents of each are described below.
		<br/><br/><u>Collection</u>
		<br/><br/>There is just the header row and one data row in this tab.
		
		<ul><li>id
			<ul><li>for imports this value is ignored
			</li></ul>
		</li><li>Name
			<ul><li>must be unique across all your collections
			</li></ul>
		</li><li>Type
			<ul><li>must be one of "Planned", "Catalog", or "Ad-hoc"
			</li></ul>
		</li><li>Description
			<ul><li>a free text description of your collection
			</li></ul>
		</li><li>HasSets
			<ul><li>either 0 if does not support sets, or 1 if it does
			</li></ul>
		</li><li>(user defined attributes)
			<ul><li>a column for each of the user defined attributes for the Collection, the column header being the name of the attribute, and the value in the data row being the value for each attribute.
			</li></ul>	
		</li></ul>
		<u>Attributes</u>
		<br/><br/>This tab has a header row plus one data row for each of the attributes defined in the collection across all the entity types (collection, collectable sets, collectable items, example sets and example items, as appropriate for the type of collection and whether the collection support sets).
		<ul><li>Name
			<ul><li>unique name for the attribute in the collection
			</li></ul>
		</li><li>Type
			<ul><li>must be one of ‘Text’, ‘Date’, ’Number,’ ‘Dropdown’, ‘Flag’, or ‘Image’ (although Images cannot be imported currently)
			</li></ul>
		</li><li>Table
			<ul><li>is the entity type that the attribute is defined for and must be a number from 0 to 5 
			</li></ul>
			<ul><li>0=Collection, 1=Collectable Sets, 2=Collectable Items, 3=ignored, 4=Example Sets, 5=Example Items
			</li></ul>
		</li><li>IsDisplayed
			<ul><li>whether the attribute value would be shown in the table listing all of a given entity type (e.g. all Collectable Items) and must be 0 for not shown or 1 for it to be shown.
			</li></ul>
		</li><li>IsMandatory
			<ul><li>must be 0 if the attribute is not mandatory or 1 if it is
			</li></ul>
		</li><li>Config
			<ul><li>a representation of the configuration information for Dropdown attribute types or blank for all other types as they don’t need any configuration.
			</li></ul>
		</li><li>Description
			<ul><li>a free text description of the attribute
			</li></ul>
		</li></ul>
		<u>CollectableSets</u>
		<br/><br/>This tab has a header row plus one data row for each of the Collectable Sets in the collection.
		<br/><br/>Note – this tab is only present for Planned or Catalog collections that support sets.
		<ul><li>id
			<ul><li>a unique integer for each Collectable Set – this will be used in other tabs to reference this Collectable Set.
			</li></ul>
		</li><li>(user defined attributes)
			<ul><li>a column for each of the user defined attributes for the Collection, the column header being the name of the attribute, and the value in the data row being the value for each attribute.
			</li></ul>
		</li></ul>
		<u>CollectableItems</u>
		<br/><br/>This tab has a header row plus one data row for each of the Collectable Items in the collection.
		<br/><br/>Note – this tab is only present for Planned or Catalog collections.
		<ul><li>id
			<ul><li>a unique integer for each Collectable Item – this will be used in other tabs to reference this Collectable Item.
			</li></ul>
		</li><li>(user defined attributes)
			<ul><li>a column for each of the user defined attributes for the Collection, the column header being the name of the attribute, and the value in the data row being the value for each attribute.
			</li></ul>
		</li></ul>
		<u>CIinCS</u>
		<br/><br/>This tab has a header row plus one data row for each of the connections between each Collectable Set and the Collectable Items that are part of that Collectable Set.
		<br/><br/>Note – this tab is only present for Planned or Catalog collections that support sets.
		<ul><li>id
			<ul><li>for imports this value is ignored
			</li></ul>
		</li><li>collectable_set_id
			<ul><li>this is the ‘id’ value of a Collectable Set from the CollectableSet tab
			</li></ul>
		</li><li>collectable_item_id
			<ul><li>this is the ‘id’ value of a Collectable Item from the CollectableItem tab
			</li></ul>
		</li><li>Sequence
			<ul><li>This will usually be 1, but will need to be changed where there are multiple copies of the same Collectable Item in the same Collectable Set – in this case set Sequence so that each combination of (collectable_set_id, collectable_item_id, and Sequence) is unique.
			</li></ul>
		</li></ul>
	<u>ExampleSets</u>
		<br/><br/>This tab has a header row plus one data row for each of the Example Sets in the collection.
		<br/><br/>Note – this tab is only present for Planned or Ad-hoc collections that support sets.
		<ul><li>id
			<ul><li>a unique integer for each Example Set – this will be used in other tabs to reference this Example Set.
			</li></ul>
		</li><li>collectable_set_id
			<ul><li>this is the ‘id’ value of a Collectable Set from the CollectableSet tab that this Example Set is an example of in your collection.
			</li></ul>
		</li><li>(user defined attributes)
			<ul><li>a column for each of the user defined attributes for the Collection, the column header being the name of the attribute, and the value in the data row being the value for each attribute.
			</li></ul>
		</li></ul>
		<u>ExampleItems</u>
		<br/><br/>This tab has a header row plus one data row for each of the Example Items in the collection.
		<br/><br/>Note – this tab is only present for Planned or Ad-hoc collections.
		<ul><li>id
			<ul><li>for imports this value is ignored
			</li></ul>
		</li><li>collectable_item_id
			<ul><li>this is the ‘id’ value of a Collectable Item from the CollectableItem tab that this Example Item is an example of in your collection.
			</li></ul>
		</li><li>example_set_id
			<ul><li>this is the ‘id’ value of an Example Set from the ExampleSet tab that this Example Item is part of (if it is part of a set).
			</li></ul>
		</li><li>(user defined attributes)
			<ul><li>a column for each of the user defined attributes for the Collection, the column header being the name of the attribute, and the value in the data row being the value for each attribute.
			</li></ul>
		</li></ul>
	  </Typography>
	  <br/><br/>
	</Box>
  );
};

export default SupportReferenceAppendixImportExportPage;
