import React from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import create_5 from '../support_data/create_5.png'

function SupportReferenceCreatePage() {
  return (
	<Box sx={{ width: 550 }}>
      <Grid container justifyContent="center">
        <img src={create_5} width="500" height="370" alt="" />
	  </Grid>
	  <br/>
	  <Typography>
		When creating by copying from one of your existing collections you will need to first select which collection you want to copy from.
		<br/><br/>The second decision is how much of the original collection do you want to include in the copy.
		<ul><li>Just the collection type, whether its supports sets and the attribute definitions only – so collectables or examples form that original collection, or
		</li><li>All those basic definitions along with all information about the Collectables that had been created in the original collection, or
		</li><li>A complete copy of everything in the original collection.
		</li></ul>
		In all cases, the name of the next collection will be different and as you entered on the previous page.
		<br/><br/>Click on Create button to complete the process and you will be taken to the Collection detail page to view the collection.
	  </Typography>
	  <br/><br/>
	</Box>
  );
};

export default SupportReferenceCreatePage;
