import React, { useContext, useState, useEffect, useCallback } from 'react';
import { useNavigate } from "react-router-dom"

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Checkbox from '@mui/material/Checkbox';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import callApi from '../api/api';
import { TokenContext } from '../auth/token'
import { ErrorListContext, logErrorString } from '../error/errorList'
import { ReloadDataContext, triggerDataReload } from '../context/reloadData'

import Constants from '../Constants'
import AttributeDisplay from '../attributes/AttributeDisplay'
import AlertDialog from '../components/AlertDialog'


function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}


//
// In all cases the following must be provided:
//	getDataActionName
//	title
//	displayMode - which msut be one of the following:
//		Constants.DisplayModeView - with the following also provided:
//			baseUrlPath if set
//			deleteActionName
//			entityId
//		Constants.DisplayModeUpdate - with the following also provided:
//			baseUrlPath if set
//			collectionId if not set
//			entityId
//			saveDataActionName
//		Constants.DisplayModeReference - with the following also provided:
//			baseUrlPath if set
//		Constants.DisplayModeCreate - with the following also provided:
//			baseUrlPath if set
//			collectionId if set
//			saveDataActionName
//

function EntityDetailViewUpdate (props, ref) {
  
  console.log("EntityDetailViewUpdate - top");
  
  const [entityData, setEntityData] = useState([]);
  const [referencedItemId, setReferencedItemId] = useState(null);
  const [attributes, setAttributes] = useState([]);
  const [updatedValues, setUpdatedValues] = useState({});
  const [attributesInError, setAttributesInError] = useState([]);
  const [urlDestination, setUrlDestination] = useState([0, undefined]);
  const [remainFlag, setRemainFlag] = useState(props.forceRemainFlag === true ? true : false);
  const [createdDialogOpenState, setCreatedDialogOpenState] = useState(false);
  const [deleteWarningDialogOpenState, setDeleteWarningDialogOpenState] = useState(false);
  
  const [token, setToken] = useContext(TokenContext);
  const [errorList, setErrorList] = useContext(ErrorListContext);
  const [reloadData, setReloadData] = useContext(ReloadDataContext);

  const navigate = useNavigate();
  
  //
  //
  const fetchDataCallback = useCallback( async () => {
	console.log("fetchDataCallback");
	
	let itemId = props.entityId;
	if ((itemId === '') && (props.displayMode === Constants.DisplayModeCreate)) {
	  itemId = 'TEMPLATE'
	}
	let data = {
	  "action": props.getDataActionName,
	  "payload": { 'id' : itemId }
	};
	if (props.collectionId !== null) {
	  data["payload"]["collection_id"] = props.collectionId;
	}
	console.log(data)
	
	const retVal = await callApi(token, setToken, data);
	console.log(retVal)
	if (Object.hasOwn(retVal.data, 'linked_item_id')) {
	  setReferencedItemId(retVal.data.linked_item_id);
	  setEntityData(retVal.data.attributes);
	} else {
	  setEntityData(retVal.data);
	}
	logErrorString(retVal.errorMessage, errorList, setErrorList)
  }, [props.getDataActionName, props.entityId, props.collectionId, props.displayMode, token, setToken]);

  //
  //
  useEffect(() => {
	console.log("useEffect - fetchDataCallback");
    fetchDataCallback();
  }, [fetchDataCallback, reloadData])
  
  //
  //
  useEffect(() => {
	console.log("useEffect - navigate: " + urlDestination);
	const url = urlDestination[1]
	if (url !== undefined) {
	  navigate(url);
	}
  }, [urlDestination, navigate]);
  
  // Extract the set of attributes each entity will have
  //
  useEffect(() => {
	
	console.log("useEffect - attributes");
    let attrs = Object.keys(entityData);
	
    // we remove the id property that we know must be present as we dont want to show that
    if (attrs.includes('id')) {
	  attrs = attrs.filter( attr => attr !== 'id');
    }
	
	// we allow the parent to also specify additional attributes to be removed
	//
	const attrsToRemove = props.attrsToRemove
	console.log(attrsToRemove);
	if (attrsToRemove !== undefined) {
	  attrsToRemove.forEach((attrToRemove) => {
	    attrs = attrs.filter( attr => attr !== attrToRemove);
	  });
	}
	
	setAttributes(attrs)
	  
  }, [entityData, props.attrsToRemove])
  
  
  // Need this so that when reload going form View to Update
  // it will force a scroll back to the top of the window
  //
  /* ==> REMOVED (for now) while test if can scroll to component from the user of these components...
  useEffect(() => {
	console.log("useEffect - window scroll");
    window.scrollTo({top: 0, left: 0, behavior: "smooth" });
  });
  */
  
  //
  //
  const updateAttributesInError = (isInError, attributeName) => {
	if (isInError) {
	  if (!attributesInError.includes(attributeName)) {
		// should be there, isn't, so add it
		// 
		// note - used slide(0) to create a new copy of the original
		//        so that setAttributesInError sees new array to trigger re-render
		//        if didn't then sees original array with entries changed not triiger re-render
		// note - must use functional set for the state variable to avoid race conditions between attributes
		//
		setAttributesInError((prevList) => {
          let updList = prevList.slice(0);
	      updList.unshift(attributeName);
		  return updList;
		});
	  }
	} else {
	  if (attributesInError.includes(attributeName)) {
		// should not be there, but is, so remove it
		//
		// note - this triggers re-render as filter passes back a new array
		//        so the setAttributesInError sees it as new and triggers re-render
		// note - must use functional set for the state variable to avoid race conditions between attributes
		//
		setAttributesInError((prevList) => {
		  const updList = attributesInError.filter(function (item) {
		    return item !== attributeName;
          });
		  return updList;
		});
	  }
	}
  };
  
  //
  //
  const updatedValuesChanged = (newValues) => {
	setUpdatedValues(newValues);
	if ('setParentUpdatedValues' in props) {
	  props.setParentUpdatedValues(newValues);
	}
  };
  
  //
  // editHandler: only used for:  
  //	Constants.DisplayModeView
  //		baseUrlPath if set
  //		entityId
  //
  const editHandler = (event) => {
	console.log("editHandler");
    event.preventDefault();
	
	if (props.baseUrlPath !== null) {
	  setUrlDestination([getRandomInt(1000), props.baseUrlPath + props.entityId + "/update"]);
	}
  };
   
  //
  // saveHandler: only used for:  
  //	Constants.DisplayModeUpdate
  //		entityId
  //		collectionId if not set
  //		saveDataActionName
  //		baseUrlPath if set
  //
  const saveHandler = async (event) => {
	console.log("saveHandler");
    event.preventDefault();
	
	// add in id for the entity to be updated
    console.log (updatedValues);
	updatedValues['id'] = props.entityId;
	if (props.collectionId !== null) {
	  updatedValues["collection_id"] = props.collectionId;
	}
	console.log(updatedValues);
	
	// send request to update the entity to the backend
	let data = {
	  "action": props.saveDataActionName,
	  "payload": updatedValues
	};
	
	const retVal = await callApi(token, setToken, data);
	console.log(retVal)
	logErrorString(retVal.errorMessage, errorList, setErrorList)
	
	if ('success' in retVal.data) {
	  const success = retVal.data['success']
	  console.log(success);
	  
	  if (success === false) {
		//
		// FUDGE ALERT!
		// - HAS TO BE FOR COLLECTION HERE...
		//
	    const msg = "Failed to update collection - ensure collection name is unique.";
	    logErrorString(msg, errorList, setErrorList);
	    return;
	  }
	}
    
    // trigger reload of data from the database
    triggerDataReload(reloadData, setReloadData);
	
	// now go back to view mode
    console.log("TRIGGER#1 checking: " + props.baseUrlPath);
	if (props.baseUrlPath !== null) {
	  console.log("TRIGGER#1 move to: " + props.baseUrlPath + props.entityId);
	  setUrlDestination([getRandomInt(1000), props.baseUrlPath + props.entityId]);
	}
  };
  
  //
  // cancelHandler: only used for:  
  //	Constants.DisplayModeUpdate
  //	Constants.DisplayModeCreate
  //		baseUrlPath if set
  //
  const cancelHandler = (event) => {
	console.log("cancelHandler");
    event.preventDefault();
	
	// reset tracking of changes and put fields back to the state they were
	updatedValuesChanged({});
	fetchDataCallback();

	// now go back to view mode
	if (props.baseUrlPath !== null) {
	  setUrlDestination([getRandomInt(1000), props.baseUrlPath + props.entityId]);
	}
  }
  
  //
  // deleteHandler: only used for:  
  // 	Constants.DisplayModeView
  //		deleteActionName
  //		entityId
  //		baseUrlPath if set
  //
  const deleteHandler = (event) => {
	console.log("deleteHandler");
    event.preventDefault();
	
	setDeleteWarningDialogOpenState(true);
  }
	
  const deleteAction = async () => {
	console.log("deleteAction");
	
	// send request to update the entity to the backend
	let data = {
	  "action": props.deleteActionName,
	  "payload": {
		  'id': props.entityId
	  }
	};
	console.log(data);
	
	const retVal = await callApi(token, setToken, data);
	console.log(retVal)
	logErrorString(retVal.errorMessage, errorList, setErrorList)
    
    // trigger reload of data from the database
    triggerDataReload(reloadData, setReloadData);
	
    console.log("TRIGGER#1 checking: " + props.baseUrlPath);
	if (props.baseUrlPath !== null) {
	  // now go to back to main list of entities
	  console.log("TRIGGER#2 move to: " + props.baseUrlPath);
	  setUrlDestination([getRandomInt(1000), props.baseUrlPath]);
	}
  }
  
  //
  // createHandler: only used for:  
  // 	Constants.DisplayModeCreate
  //		collectionId if set
  //		saveDataActionName
  //		baseUrlPath if set
  //
  const createHandler = async (event) => {
	console.log("createHandler");
    event.preventDefault();
	
	// add in id for the entity to be updated
    console.log (updatedValues);
	if (props.collectionId !== null) {
	  updatedValues["collection_id"] = props.collectionId;
	}
	if (props.additionalEntityId != null) {
      Object.keys(props.additionalEntityId).map((keyName, i) => (
	    updatedValues[keyName] = props.additionalEntityId[keyName]
      ));
	}
	console.log(updatedValues);
	
	// send request to create the entity to the backend
	let data = {
	  "action": props.saveDataActionName,
	  "payload": updatedValues,
	};
	
	const retVal = await callApi(token, setToken, data);
	console.log(retVal);
	logErrorString(retVal.errorMessage, errorList, setErrorList)
	
	let newEntityId = retVal.data['id']
	console.log(newEntityId);

	// trigger reload of data from the database
	triggerDataReload(reloadData, setReloadData);
  	
	// now go back to view mode
	// but only if user doesn't want to stay on this page
	if (remainFlag) {
	  setCreatedDialogOpenState(true);
	  setTimeout(() => {
        setCreatedDialogOpenState(false);
        window.scrollTo({top: 0, left: 0, behavior: "smooth" });
        }, 1500);
	} else {
	  if (props.baseUrlPath !== null) {
	    setUrlDestination([getRandomInt(1000), props.baseUrlPath + newEntityId]);
	  }
	}
};
  
  //
  // viewHandler: only used for:  
  // 	Constants.DisplayModeReference
  //		baseUrlPath if set
  //
  const viewHandler = (event) => {
	console.log("viewHandler");
    event.preventDefault();
	
	if (props.baseUrlPath !== null) {
	  setUrlDestination([getRandomInt(1000), props.baseUrlPath + referencedItemId]);
	}
  };
  
  // set up variables for View mode
  //
  let card_border_colour = '1px solid black';
  let editViewOrSaveButtonJSX = <Button variant="contained" type='submit' onClick={editHandler}>Edit</Button>
  let cancelButtonJSX = "";
  let deleteButtonJSX = <Button variant="contained" type='submit' onClick={deleteHandler}>Delete</Button>
  let attributeEditMode = false;
  let remainFlagJSX = "";
  
  //
  // or for Edit mode
  //
  if (props.displayMode === Constants.DisplayModeUpdate) {
	  card_border_colour = '2px solid red';
	  editViewOrSaveButtonJSX = <Button variant="contained" type='submit' disabled={attributesInError.length > 0} onClick={saveHandler}>Save</Button>
	  if (props.noCancelButton === undefined || props.noCancelButton === false) {
	    cancelButtonJSX = <Button variant="contained" type='submit' onClick={cancelHandler}>Cancel</Button>
	  }
	  deleteButtonJSX = "";
	  attributeEditMode = true;
  }
  //
  // or for Reference mode
  //
  else if (props.displayMode === Constants.DisplayModeReference) {
	  card_border_colour = 'light grey';
	  if (referencedItemId !== null) {
		editViewOrSaveButtonJSX = <Button variant="contained" type='submit' onClick={viewHandler}>View</Button>
      } else {
		editViewOrSaveButtonJSX = ""
	  }
	  cancelButtonJSX = "";
	  deleteButtonJSX = "";
	  attributeEditMode = false;
  }
  //
  // or for Create mode
  //
  else if (props.displayMode === Constants.DisplayModeCreate) {
	  card_border_colour = '2px solid red';
	  if (props.saveDataActionName !== null) {
	    editViewOrSaveButtonJSX = <Button variant="contained" type='submit' disabled={attributesInError.length > 0} onClick={createHandler}>Create</Button>
	  } else {
		editViewOrSaveButtonJSX = ""
	  }
	  if (props.noCancelButton === undefined || props.noCancelButton === false) {
	    cancelButtonJSX = <Button variant="contained" type='submit' onClick={cancelHandler}>Cancel</Button>
	  }
	  deleteButtonJSX = "";
	  attributeEditMode = true;
	  if (props.noRemainFlag === undefined || props.noRemainFlag === false) {
	    remainFlagJSX = (
          <Stack direction="row" alignItems="center">
		    <Checkbox
		      checked={remainFlag}
              onChange={(e) => {
		        setRemainFlag(e.target.checked);
			  }}
		    />
		    <p>Remain on this page to create another similar item.</p>
		  </Stack>
	    );
	  }
  }
  
  let attributeDataJSX = <Typography>No entry found</Typography>
  if (attributes.length !== 0) {
	console.log("XXXXX attributes = " + attributes);
	console.log("XXXXX entityData = " + JSON.stringify(entityData));
    attributeDataJSX = attributes.map((attribute) => (
			             <AttributeDisplay
                           editMode={attributeEditMode}
                           attributeName={attribute}
                           attributeType={entityData[attribute][0]}
                           attributeValue={entityData[attribute][1]}
						   attributeConfigInfo={entityData[attribute][2]}
						   attributeDescription={entityData[attribute][3]}
						   attributeIsMandatory={entityData[attribute][4] === 1}
                           updatedValues={updatedValues}
                           setUpdatedValues={updatedValuesChanged}
						   updateAttributesInError={updateAttributesInError}
                         />
		               ))
  }
  
  return (
    <div ref={ref}>
	  <Card variant="outlined" sx={{ border: card_border_colour }}>
	    <Box m={2}>
          <form>
	        <Stack sx={{width: '50ch'}} spacing={2} noValidate autoComplete="off">
			  <Typography style={{ fontWeight: 600 }}>
				{props.title}
			  </Typography>
		      {attributeDataJSX}
              <Stack direction='row' spacing={1} >
			    {cancelButtonJSX}
			    {editViewOrSaveButtonJSX}
			    {deleteButtonJSX}
              </Stack>
			  {remainFlagJSX}
    	    </Stack>
          </form>
		</Box>
	  </Card>
	  <AlertDialog
	    openState={createdDialogOpenState}
	    setOpenState={setCreatedDialogOpenState}
	    showButtons={false}
		optionalDialog={null}
	    actionFunction={null}
	    title="Success"
	    text="The item was created successfully, ready to create another..."
	  />
	  <AlertDialog
	    openState={deleteWarningDialogOpenState}
	    setOpenState={setDeleteWarningDialogOpenState}
	    showButtons={true}
	    optionalDialog={null}
	    actionFunction={deleteAction}
	    title="Warning"
	    text="Are you sure you want to delete this?"
	  />
	</div>
  );
}

export default React.forwardRef(EntityDetailViewUpdate);
