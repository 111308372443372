import React, { useContext, useState, useCallback, useEffect } from 'react';
//import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import callApi from '../api/api';
import { tokenIsValid, TokenContext } from '../auth/token'
import { ErrorListContext, logErrorString } from '../error/errorList'
import { ReloadDataContext, triggerDataReload } from '../context/reloadData'

import { SubscriptionStatusContext, restrictPage } from '../context/subscriptionStatus'
import SubscriptionExpiredMessage from '../components/SubscriptionExpiredMessage'

import NotLoggedInMessage from '../components/NotLoggedInMessage'
import AlertDialog from '../components/AlertDialog'


function SupportItemAttribute(props) {
  return (
    <TextField
	  id={props.name}
      label={props.name}
      value={props.value}
      InputProps={{
        readOnly: true,
      }}
	  sx={{mb: 3}}
	  multiline
	  maxRows={4}
    />

  );
}


function SupportItemPage() {
  const [entityData, setEntityData] = useState({});
  const [dataLoaded, setDataLoaded] = useState(false);
  const [enteredComment, setEnteredComment] = useState("");
  const [createdDialogOpenState, setCreatedDialogOpenState] = useState(false);
  const [closeDialogOpenState, setCloseDialogOpenState] = useState(false);
  const [saveDialogOpenState, setSaveDialogOpenState] = useState(false);
  const [closeWarningDialogOpenState, setCloseWarningDialogOpenState] = useState(false);
  
  const [token, setToken] = useContext(TokenContext);
  const [errorList, setErrorList] = useContext(ErrorListContext);
  const [reloadData, setReloadData] = useContext(ReloadDataContext);
  const [subscriptionStatus, /*setSubscriptionStatus*/] = useContext(SubscriptionStatusContext);
  
  const params = useParams();
  
  //const navigate = useNavigate();

  //
  //
  const fetchDataCallback = useCallback( async () => {
	const data = {
	  "action": 'get_support_item',
	  "payload": {
		  'issue_id': params.itemId
	  }
	};
	
	const retVal = await callApi(token, setToken, data);
	console.log("SupportPage: retVal=...")
	console.log(retVal);
	
	setEntityData(retVal.data);
	setDataLoaded(true);
	logErrorString(retVal.errorMessage, errorList, setErrorList)
	
  }, [token, setToken, params.itemId]);

  //
  //
  useEffect(() => {
	fetchDataCallback();
  }, [fetchDataCallback, reloadData])
  
  
  // do not allow to proceed if not logged in
  //
  if (!tokenIsValid(token)) {
    return <NotLoggedInMessage />;
  }
  // nor if subscription expired and page is restricted
  //
  if (restrictPage(subscriptionStatus, "SupportItemPage")) {
    return <SubscriptionExpiredMessage />;
  }
  
  if (dataLoaded === false) {
	return (
      <Grid container justifyContent="center">
	    <CircularProgress />
	  </Grid>
	);
  }
  
  if (!('id' in entityData)) {
	return (
	  <p>Invalid item ID...</p>
	);
  }
  
  //
  //
  const refreshHandler = async (event) => {
	console.log("refreshHandler");
    event.preventDefault();
	
    setCreatedDialogOpenState(true);
    setTimeout(() => {
	  setCreatedDialogOpenState(false);
	  window.scrollTo({top: 0, left: 0, behavior: "smooth" });
	  }, 500);
	
	fetchDataCallback();
  }
  
  //
  //
  let commentsJSX = (
    <p>Nothing yet...</p>
  );
  if (entityData.comments.length > 0) {
	commentsJSX = (
	  entityData.comments.map(comment => (
	    <SupportItemAttribute name='' value={comment} />
	  ))
	);
  }
  
  //
  //
  const closeHandler = async (event) => {
	console.log("closeHandler");
    event.preventDefault();
	
	setCloseWarningDialogOpenState(true);
  }
	
  const closeAction = async () => {
	console.log("closeAction");
	
	// send request
	let data = {
	  "action": "close_support_item",
	  "payload": {
	    "issue_id" : entityData.id,
	  }
	};
	
	setCloseDialogOpenState(true);
	const retVal = await callApi(token, setToken, data);
	setCloseDialogOpenState(false);
	console.log(retVal);
	logErrorString(retVal.errorMessage, errorList, setErrorList)
    
    // trigger reload of data from the database
    triggerDataReload(reloadData, setReloadData);
  }
  
  //
  //
  const clearHandler = (event) => {
	console.log("clearHandler");
    event.preventDefault();
	
	console.log(enteredComment);
	setEnteredComment("");
  }
  
  //
  //
  const saveHandler = async (event) => {
	console.log("saveHandler");
    event.preventDefault();
	
	console.log(enteredComment);
	
	// send request
	let data = {
	  "action": "add_support_item_comment",
	  "payload": {
	    "issue_id" : entityData.id,
		"comment": enteredComment
	  }
	};
	
	setSaveDialogOpenState(true);
	const retVal = await callApi(token, setToken, data);
	setSaveDialogOpenState(false);
	console.log(retVal);
	logErrorString(retVal.errorMessage, errorList, setErrorList)
	
	setEnteredComment("");
    
    // trigger reload of data from the database
    triggerDataReload(reloadData, setReloadData);
  }
  
  //
  //
  let closeButtonJSX = "";
  let addCommentJSX = "";
  if (entityData.status === "Open") {
	closeButtonJSX = (
      <Grid container justifyContent="center">
        <Button variant="contained" type='submit' onClick={closeHandler}>Close</Button>
	  </Grid>
	);
	
	addCommentJSX = (
      <Grid container justifyContent="center">
	    <Card variant="outlined">
	      <Box m={2}>
	        <Stack sx={{width: '50ch'}} spacing={2} noValidate autoComplete="off">
	          <Typography style={{ fontWeight: 600 }}>
		        Add new comment
		      </Typography>
			  <TextField
			    id="Comment"
				value={enteredComment}
				sx={{mb: 3}}
				multiline
				rows={4}
				onChange = {(e) => {
				  setEnteredComment(e.target.value);
				}}
			  />
			  <Grid container justifyContent="center">
				<Stack spacing={2} direction="row">
				  <Button variant="contained" type='submit' onClick={clearHandler}>Clear</Button>
				  <Button variant="contained" type='submit' onClick={saveHandler}>Save</Button>
				</Stack>
			  </Grid>
			</Stack>
		  </Box>
		</Card>
      </Grid>
	);
  }
  
  return (
	<Stack spacing={2}>
      <Grid container justifyContent="center">
	    <h1>Your Support - Contact Us</h1>
	  </Grid>
      <Grid container justifyContent="center">
	    <Stack>
        <Grid container justifyContent="right">
		  <IconButton aria-label="refresh" onClick={refreshHandler}>
			<RefreshIcon />
		  </IconButton>
		</Grid>
	    <Card variant="outlined">
	      <Box m={2}>
	          <Stack sx={{width: '50ch'}} spacing={2} noValidate autoComplete="off">
	            <Typography style={{ fontWeight: 600 }}>
		          Message details
			    </Typography>
			    <SupportItemAttribute name='ID' value={entityData.id} />
			    <SupportItemAttribute name='Title' value={entityData.title} />
			    <SupportItemAttribute name='Description' value={entityData.description} />
			    <SupportItemAttribute name='Type' value={entityData.issuetype} />
			    <SupportItemAttribute name='Status' value={entityData.status} />
			    {closeButtonJSX}
		      </Stack>
          </Box>
	    </Card>
		</Stack>
	  </Grid>
      <Grid container justifyContent="center">
	    <Card variant="outlined">
	      <Box m={2}>
	        <Stack sx={{width: '50ch'}} spacing={2} noValidate autoComplete="off">
	          <Typography style={{ fontWeight: 600 }}>
		        Comments
		      </Typography>
			  {commentsJSX}
			</Stack>
		  </Box>
		</Card>
      </Grid>
	  {addCommentJSX}
	  <AlertDialog
	    openState={createdDialogOpenState}
	    setOpenState={setCreatedDialogOpenState}
	    showButtons={false}
	    optionalDialog={null}
	    actionFunction={null}
	    title=""
	    text="Updating..."
	  />
	  <AlertDialog
	    openState={closeDialogOpenState}
	    setOpenState={setCloseDialogOpenState}
	    showButtons={false}
	    optionalDialog={null}
	    actionFunction={null}
	    title=""
	    text="Closing message..."
	  />
	  <AlertDialog
	    openState={saveDialogOpenState}
	    setOpenState={setSaveDialogOpenState}
	    showButtons={false}
	    optionalDialog={null}
	    actionFunction={null}
	    title=""
	    text="Saving comment..."
	  />
	  <AlertDialog
	    openState={closeWarningDialogOpenState}
	    setOpenState={setCloseWarningDialogOpenState}
	    showButtons={true}
		optionalDialog={null}
	    actionFunction={closeAction}
	    title="Warning"
	    text="Are you sure you want to close this message as you won't be able to update it once it is closed?"
	  />
	</Stack>
  );
}

export default SupportItemPage;
