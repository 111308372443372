import React from 'react';

import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';

import TabbedPages from '../components/TabbedPages'

import HomeInfoOverview from '../components/HomeInfoOverview'
import HomeInfoHowItWorks from '../components/HomeInfoHowItWorks'
import HomeInfoPricing from '../components/HomeInfoPricing'
import HomeInfoNextSteps from '../components/HomeInfoNextSteps'

import GenericPageHeader from '../components/GenericPageHeader'


function HomePage() {
  
  const tabs = [
    {"Name": "Overview", 	 "Child": HomeInfoOverview},
    {"Name": "How it works", "Child": HomeInfoHowItWorks},
    {"Name": "Pricing",      "Child": HomeInfoPricing},
    {"Name": "Next steps",   "Child": HomeInfoNextSteps},
  ];
  
  return (
    <Grid container justifyContent="center">
      <Stack spacing={0}>
	    <Grid container justifyContent="center">
	      <GenericPageHeader />
	    </Grid>
        <TabbedPages
	      tabs={tabs}
	      minWidth='130px'
	    />
	  </Stack>
	</Grid>
  );
}

export default HomePage;
