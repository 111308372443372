import React, { useContext, useState, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import Stack from '@mui/material/Stack';
import { DataGridPro as DataGrid } from '@mui/x-data-grid-pro';

import callApi from '../api/api';
import { tokenIsValid, TokenContext } from '../auth/token'
import { ErrorListContext, logErrorString } from '../error/errorList'
import { ReloadDataContext } from '../context/reloadData'

import { SubscriptionStatusContext, restrictPage } from '../context/subscriptionStatus'
import SubscriptionExpiredMessage from '../components/SubscriptionExpiredMessage'

import NotLoggedInMessage from '../components/NotLoggedInMessage'
import CustomColumnMenu from '../components/CustomColumnMenu'
import AlertDialog from '../components/AlertDialog'


function SupportItemsPage() {
  const [entityData, setEntityData] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [createdDialogOpenState, setCreatedDialogOpenState] = useState(false);
  
  const [token, setToken] = useContext(TokenContext);
  const [errorList, setErrorList] = useContext(ErrorListContext);
  const [reloadData, /*setReloadData*/] = useContext(ReloadDataContext);
  const [subscriptionStatus, /*setSubscriptionStatus*/] = useContext(SubscriptionStatusContext);
  
  const navigate = useNavigate();

  //
  //
  const fetchDataCallback = useCallback( async () => {
	const data = {
	  "action": 'get_support_item_list',
	  "payload": { }
	};
	
	const retVal = await callApi(token, setToken, data);
	console.log("SupportPage: retVal=...")
	console.log(retVal);
	
	setEntityData(retVal.data);
	setDataLoaded(true);
	logErrorString(retVal.errorMessage, errorList, setErrorList)
	
  }, [token, setToken]);

  //
  //
  useEffect(() => {
	fetchDataCallback();
  }, [fetchDataCallback, reloadData])
  
  
  // do not allow to proceed if not logged in
  //
  if (!tokenIsValid(token)) {
    return <NotLoggedInMessage />;
  }
  // nor if subscription expired and page is restricted
  //
  if (restrictPage(subscriptionStatus, "SupportItemsPage")) {
    return <SubscriptionExpiredMessage />;
  }
  
  //
  //
  const refreshHandler = async (event) => {
	console.log("refreshHandler");
    event.preventDefault();
	
    setCreatedDialogOpenState(true);
    setTimeout(() => {
	  setCreatedDialogOpenState(false);
	  window.scrollTo({top: 0, left: 0, behavior: "smooth" });
	  }, 500);
	
	fetchDataCallback();
  }
  
  //
  //
  const rowClickHandler = ((params, event, details) => {
    console.log("row clicked - ID="+params.id)
    console.log(params)
    console.log(details)
	
	navigate("/support/support_items/" + params.id);
  });
  
  //
  //
  const createHandler = (event) => {
	console.log("createHandler");
    event.preventDefault();
	
	navigate("/support/support_items/create");
  }
  
  //
  //
  const columns = [
	{
	  field: 'id',
	  headerName: 'ID',
	  width: 200,
	  resizable: true,
	  headerClassName: 'datagrid-column-header'
	},
	{
	  field: 'issuetype',
	  headerName: 'Type',
	  width: 200,
	  resizable: true,
	  headerClassName: 'datagrid-column-header'
	},
	{
	  field: 'title',
	  headerName: 'Title',
	  width: 200,
	  resizable: true,
	  headerClassName: 'datagrid-column-header'
	},
	{
	  field: 'status',
	  headerName: 'Status',
	  width: 200,
	  resizable: true,
	  headerClassName: 'datagrid-column-header'
	},
  ];
  
  return (
    <Grid container justifyContent="center">
      <Stack spacing={2}>
        <Grid container justifyContent="center">
	        <h1>Your Support - Contact Us</h1>
	    </Grid>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
		    <h4>Messages</h4>
		    <Button variant="contained" onClick={createHandler}>Create</Button>
            <IconButton aria-label="refresh" onClick={refreshHandler}>
              <RefreshIcon />
		     </IconButton>
        </Stack>
        <Grid container justifyContent="center">
		  <div style={{ height: 400, width: '100%' }}>
			<DataGrid
			  initialState={{
				pagination: {
				  paginationModel: {
					pageSize: 10,
				  }
				},
			  }}
			  slots={{ columnMenu: CustomColumnMenu }}
			  rows={entityData}
			  columns={columns}
			  loading={!dataLoaded}
			  pageSize={3}
			  pagination
			  pageSizeOptions={
				[10, 20, 50, 100]
			  }
			  onRowClick = {rowClickHandler}
		      sx={{ bgcolor: '#ffffff' }}
			/>
		  </div>
	    </Grid>
	  </Stack>
	  <AlertDialog
	    openState={createdDialogOpenState}
	    setOpenState={setCreatedDialogOpenState}
	    showButtons={false}
	    optionalDialog={null}
	    actionFunction={null}
	    title=""
	    text="Updating..."
	  />
	</Grid>
  );
}

export default SupportItemsPage;
