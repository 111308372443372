import React, { useState, useContext, useEffect, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import callApi from '../api/api';
import { signOut, getCurrentUser } from "../auth/auth";
import { tokenIsValid, TokenContext } from '../auth/token'
import { ErrorListContext } from '../error/errorList'

import { environmentString } from '../config/config'
import { SubscriptionStatusContext } from '../context/subscriptionStatus'

import BreadcrumbBar from '../components/BreadcrumbBar';
import StyledCard from '../components/StyledCard'

import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import icon_logo from '../icons/yco-logo-small.png';
  
const MINUTE_MS = 60000;


function MainNavigation() {

  const [token, setToken] = useContext(TokenContext);
  const [errorList, /*setErrorList*/] = useContext(ErrorListContext);
  const [subscriptionStatus, setSubscriptionStatus] = useContext(SubscriptionStatusContext);
  const [userName, setUserName] = useState("");
  const [intervalID, setIntervalID] = useState(null);

  const navigate = useNavigate();
  
  const fetchDataCallback = useCallback( async () => {
	console.log("MainNavigation: fetchDataCallback: start");
	const data = {
	  "action": "get_subscription_status",
	  "payload": { }
	};
	
	const retVal = await callApi(token, setToken, data);
	console.log(retVal)
	setSubscriptionStatus(retVal.data);
	//logErrorString(retVal.errorMessage, errorList, setErrorList)
	
  }, [token, setToken, setSubscriptionStatus]);
  
  useEffect(() => {
	console.log("MainNavigation: useEffect: userName=" + userName + " interval=" + intervalID)
	if (userName === "") {
	  // not logged in, so check if need to kill previous interval
	  console.log("MainNavigation: useEffect: NOT LOGGED IN")
	  if (intervalID !== null) {
	    console.log("MainNavigation: useEffect: STOPPING INETRVAL")
	    clearInterval(intervalID);
	    console.log("MainNavigation: useEffect: interval stopped intervalID=" + intervalID)
	    setIntervalID(null);
	  }
	} else {
	  // logged in, so make sure periodic checking of subscription status is running
	  console.log("MainNavigation: useEffect: LOGGED IN")
	  if (intervalID === null) {
	    console.log("MainNavigation: useEffect: STARTING INETRVAL")
	    
		// do an initial call to get updated data ASAP
		fetchDataCallback();
		
		//  then schedule one to run every MINUTE_MS
        const interval = setInterval(() => {
	      fetchDataCallback();
          }, MINUTE_MS);
	    
	    // and save the returned ID so we can kill it again when the user is logged out
        setIntervalID(interval);
	    console.log("MainNavigation: useEffect: new interval started intervalID=" + interval)
	  }
	}
  }, [userName, fetchDataCallback, intervalID])
  
  const logoutHandler = async (event) => {

    try {
      await signOut();
	  setUserName("");
      navigate("/login");
    } catch (err) {
    }

	setToken("");
  };
  
  // environment indicator (if any)
  //
  let envTextColour = "";
  let envText = "";
  let showEnvText = true;
  if (environmentString.startsWith("DEV")) {
    envTextColour = "#FFA000" // organge
	envText = environmentString
  } else if (environmentString.startsWith("TST")) {
    envTextColour = "#0000FF" // blue
	envText = environmentString
  } else if (environmentString.startsWith("PRD")) {
    showEnvText = false;
  } else {
    envTextColour = "#FF0000" // red
	envText = "(invalid)"
  }
  let environmentJSX = "";
  if (showEnvText) {
    environmentJSX = (
	  <>
	    <StyledCard>
	      <Grid container justifyContent="center">
	        <Typography sx={{ color: envTextColour }}>
		      {envText}
		    </Typography>
	      </Grid>
	    </StyledCard>
	    <br/>
	  </>
	);
  }
  
  // user name
  //
  getCurrentUser()
  .then(result => {
    const loggedInUser = result;
	const name = loggedInUser.name;
	const email = loggedInUser.email;
	console.log("MainNavigation: user seems to be logged in.")
	if (tokenIsValid(token)) {
	  console.log("MainNavigation: and token is valid.")
	  if (userName === "") {
	    setUserName(name.concat(" (", email, ")"));
	  }
	} else {
	  console.log("MainNavigation: but token is not valid.")
	  logoutHandler();
	}
  })
  .catch(err => {
	// no user is logged in (anymore...)
	console.log("MainNavigation: user is NOT logged in.")
	if (userName !== "") {
	  setUserName("");
	}
  });
  let userNameJSX = ""
  if (userName !== "") {
    userNameJSX = (
	  <Typography>
	    {userName}
	  </Typography>
    );
  }
  
  // subscription status message (if any)
  //
  let subscriptionHasExpired = false;
  let subscriptionJSX = "";
  if (userName !== "") {
    if ((subscriptionStatus.subs_end !== null) && (subscriptionStatus.subs_end !== "")) {
	  const now = new Date();
	  const end = new Date(subscriptionStatus.subs_end);
	  let statusText = ""
	  if (end < now) {
		subscriptionHasExpired = true;
	    statusText = "expired";
	  } else {
	    statusText = "will expire";
	  }
	  const message = "Your " + subscriptionStatus.subs_type + " subscription " + statusText + " on " + subscriptionStatus.subs_end + ". Please go to ";
	  subscriptionJSX = (
	    <Typography sx={{ color: 'red' }}>
		  {message} <Link to="/account" color="inherit">Account</Link>
	    </Typography>
	  );
	}
  }
  
  // error line (if any)
  //
  let errorDisplayText = " ";
  if (errorList.length > 0) {
    errorDisplayText = errorList[0]['text'];
  }
  
  // links
  //
  let linksJSX = null;
  if (tokenIsValid(token)) {
	linksJSX = (
	  <Stack direction="row" spacing={2} alignItems="center">
	    {subscriptionHasExpired ? <Typography>Collections</Typography>
	                            : <Link to="/collections">Collections</Link>}
	    <Link to="/account">Account</Link>
	    <Link to="/support">Support</Link>
	    {subscriptionHasExpired ? <Typography>Reports</Typography>
	                            : <Link to="/reports">Reports</Link>}
	    {subscriptionHasExpired ? <Typography>Tasks</Typography>
	                            : <Link to="/tasks">Tasks</Link>}
	    <button onClick={logoutHandler}>Logout</button>
	  </Stack>
	);
  } else {
	 linksJSX = (
	  <Stack direction="row" spacing={2} alignItems="center">
	     <Link to="/">Home</Link>
		 <Link to="/register">Register</Link>
		 <Link to="/login">Login</Link>
	  </Stack>
	 );
  }
  
  return (
	<Stack spacing={0}>
	  {environmentJSX}
      <Grid container justifyContent="center">
	    <StyledCard>
	      <Stack direction="row" spacing={4}>
		    <img src={icon_logo} alt="" width="100"/>
		    <Stack>
	          <Stack direction="row" spacing={2} sx={{ justifyContent: 'center' }}>
	            {linksJSX}
              </Stack>
	          <Stack direction="row" spacing={2} sx={{ justifyContent: 'center' }}>
	            <BreadcrumbBar />
              </Stack>
		      <Stack direction="row" spacing={2} sx={{ justifyContent: 'center' }}>
			    {userNameJSX}
		      </Stack>
	          <Stack direction="row" spacing={2} sx={{ justifyContent: 'center' }}>
		        {subscriptionJSX}
              </Stack>
	          <Stack direction="row" spacing={2} sx={{ justifyContent: 'center' }}>
		        <Typography sx={{ color: 'red' }}>
		         {errorDisplayText}
		        </Typography>
              </Stack>
		    </Stack>
		    <img src={icon_logo} alt="" width="100"/>
	      </Stack>
	    </StyledCard>
	  </Grid>
	  <br/>
	  <Divider />
    </Stack>
  );
}

export default MainNavigation;
