import React, { useState, useEffect, useContext, useCallback, useLayoutEffect } from 'react';
import { useNavigate } from "react-router-dom"

import './EntityList.css'

import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { DataGridPro as DataGrid, useGridApiRef } from '@mui/x-data-grid-pro';

import callApi from '../api/api';
import { TokenContext } from '../auth/token';
import { ErrorListContext, logErrorString } from '../error/errorList'
import { ReloadDataContext } from '../context/reloadData'

import CustomColumnMenu from '../components/CustomColumnMenu'


function EntityList(props) {
  
  const [entityData, setEntityData] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [columns, setColumns] = useState([]);
  const [selectionModel, setSelectionModel] = useState([]);
  const [initialSelectItemsDone, setInitialSelectItemsDone] = useState(false);
  const [columnInitialState, setColumnInitialState] = useState({
          columnVisibilityModel: {
          // Hide id column, the other columns will remain visible
          id: false,
          },
        });

  const [initialState, setInitialState] = useState();
  
  const [token, setToken] = useContext(TokenContext);
  const [errorList, setErrorList] = useContext(ErrorListContext);
  const [reloadData, /*setReloadData*/] = useContext(ReloadDataContext);

  const navigate = useNavigate();
  
  const apiRef = useGridApiRef();
  
  //
  //
  const fetchDataCallback = useCallback( async () => {
	const data = {
	  "action": props.getDataAction,
	  "payload": { 'id': props.entityId }
	};
	if (props.collectionId !== null) {
	  data["payload"]["collection_id"] = props.collectionId;
	}
	
	const retVal = await callApi(token, setToken, data);
	console.log("EntityList: retVal=...")
	console.log(retVal);
	
	setEntityData(retVal.data);
	logErrorString(retVal.errorMessage, errorList, setErrorList)
	
	if (retVal.data.length !== 0) {
	  console.log("EntityList: have data...");
	  console.log(retVal.data);
   	  // set up columns found for the entity
	  const the_keys = Object.keys(retVal.data[0]);
      const columns_found = the_keys.map((key, i) => {
	    return {
		  field: key,
		  headerName: key,
		  width: 200,
		  resizable: true,
		  headerClassName: 'datagrid-column-header',
		  valueGetter: (params) => {
		    if (!params.value) {
		      return '\u2014';
		    }
		    return params.value;
		  },
		};
	  });
	  
	  console.log("columns_found.length = " + columns_found.length);
	  if (columns_found.length === 1) {
	    console.log("about to call setColumnInitialState()");
        setColumnInitialState({});
	  } 
	  
	  setColumns(columns_found);
	  
      if ('initialSelectItems' in props) {
	    if (props.initialSelectItems && !initialSelectItemsDone) {
		  console.log("initialSelectItems");
		  console.log(retVal.data);
		  let itemIds = retVal.data.map((item) => {
		    return item['id'];
		  });
		  setSelectionModel(itemIds);
          if ('setSelectedItemsList' in props) {
            props.setSelectedItemsList(itemIds);
	      }
		  console.log(itemIds);
		  setInitialSelectItemsDone(true);
        }
	  }
	} else {
      // bit of fudge here - need a non-empty list of columns for DataGrid not to throw error/errorList
	  // as we build the columns dynamically from the rows we've got, if we have no rows
	  // then we can't build any columns - so we have to manufacture one to keep DataGrid happy...
	  //
	  console.log("EntityList: no data found...");
	  setColumns( [{ field: 'id', headerName: 'id', width: 200 }] );
	}
	setDataLoaded(true);
	
  }, [token, setToken, props, initialSelectItemsDone]);
  
  //
  //
  const saveSnapshot = useCallback(() => {
    if (apiRef.current.exportState && sessionStorage) {
	  const itemName = 'YCO_DGState_' + props.entityType;
      const currentState = apiRef.current.exportState();
	  const itemData = {
	    'collectionId': props.collectionId,
		'state': currentState,
	  };
      sessionStorage.setItem(itemName, JSON.stringify(itemData));
    }
  }, [apiRef, props.collectionId, props.entityType]);
  
  //
  //
  useLayoutEffect(() => {
    const itemName = 'YCO_DGState_' + props.entityType;
	const itemDataFromStorage = sessionStorage.getItem(itemName);
	let newInitialState = {};
	if (itemDataFromStorage != null) {
	  const itemData = JSON.parse(itemDataFromStorage);
	  if (itemData['collectionId'] === props.collectionId) {
		newInitialState = itemData['state'];
	  }
	}
    setInitialState(newInitialState);

    // handle refresh and navigating away/refreshing
    window.addEventListener('beforeunload', saveSnapshot);

    return () => {
      // in case of an SPA remove the event-listener
      window.removeEventListener('beforeunload', saveSnapshot);
      saveSnapshot();
    };
  }, [saveSnapshot, props.collectionId, props.entityType]);

  //
  //
  useEffect(() => {
	fetchDataCallback();
  }, [fetchDataCallback, reloadData])
  
  // Force a scroll to the top of the window on initial entry to the page
  //
  useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: "smooth" });
  });
  
  if (dataLoaded === false || !initialState) {
	return (
	  <Grid container justifyContent="center">
	    <CircularProgress />
	  </Grid>
	);
  }
  
  //
  //
  const rowClickHandler = ((params, event, details) => {
    console.log("row clicked - ID="+params.id)
    console.log(params)
    console.log(details)
	
	if (props.baseUrlPath !== null) {
	  
	  // Navigate to child entry using params.id
	  let id = params.id;
	  if ('getNavigatableId' in props) {
	    id = props.getNavigatableId(id);
	  }
	  navigate(props.baseUrlPath + id);
	}
  });
  
  console.log("columnInitialState = " + JSON.stringify(columnInitialState));
  let noAttributesMessage = ""
  if (JSON.stringify(columnInitialState) === "{}") {
	noAttributesMessage = (
	  <Typography style={{ fontWeight: 300 }}>
	    Note - as there are no displayable attributes defined, the system generated "id" column is displayed instead. 
	  </Typography>
	  );
  }
  console.log("noAttributesMessage = " + noAttributesMessage);
  
  //
  //
  return (
	<Grid container justifyContent="center">
	  <Typography style={{ fontWeight: 600 }}>
		  {props.title}
	  </Typography>
     {noAttributesMessage}
      <div style={{ height: props.height, width: '100%' }}>
        <DataGrid
          apiRef={apiRef}
	      initialState={{
            ...initialState,
            columns: columnInitialState,
			pagination: {
			  paginationModel: {
				pageSize: 10,
			  }
			},
          }}
		  slots={{ columnMenu: CustomColumnMenu }}
          rows={entityData}
          columns={columns}
		  loading={!dataLoaded}
          pageSize={3}
		  pagination
		  pageSizeOptions={
		    [10, 20, 50, 100]
		  }
		  onRowClick = {rowClickHandler}
		  checkboxSelection={props.enableCheckboxSelection}
		  rowSelectionModel = {selectionModel}
		  onRowSelectionModelChange={(ids) => {
			setSelectionModel(ids);
            if ('setSelectedItemsList' in props) {
              props.setSelectedItemsList(ids);
			}
          }}
		  sx={{ bgcolor: '#ffffff' }}
        />
      </div>
	</Grid>
  )
}

export default EntityList;




