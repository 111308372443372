import { Outlet } from 'react-router-dom';

import MainNavigation from '../components/MainNavigation';
import PageFooter from '../components/PageFooter';

import usePageTracking from '../components/usePageTracking'


function RootLayout(props) {
  usePageTracking();
  
  return (
    <>
      <MainNavigation />
      <main>
        <Outlet />
      </main>
	  <PageFooter />
    </>
  );
}

export default RootLayout;
