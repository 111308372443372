import React, { useContext, useState, useCallback, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';


import callApi from '../api/api';
import { tokenIsValid, TokenContext } from '../auth/token'
import { ErrorListContext, logErrorString } from '../error/errorList'
import { ReloadDataContext, triggerDataReload } from '../context/reloadData'

import { SubscriptionStatusContext, restrictPage } from '../context/subscriptionStatus'
import SubscriptionExpiredMessage from '../components/SubscriptionExpiredMessage'

import NotLoggedInMessage from '../components/NotLoggedInMessage'
import AttributeConfiguration, { validAttributeTypes } from '../attributes/AttributeConfiguration'

import Constants from '../Constants'
import AlertDialog from '../components/AlertDialog'


function AttributeDefinitionDetailPage(props) {

  const [attributeData, setAttributeData] = useState({
	  'id':0,
	  'Name':'',
	  'Description':'',
	  'Type':'Text',
	  'IsDisplayed':true,
	  'IsMandatory':false,
	  'ConfigInfo':'',
	  });
  const [dataLoaded, setDataLoaded] = useState(false);
  const [updatedValues, setUpdatedValues] = useState({});
  const [attributeType, setAttributeType] = useState("Text");
  const [nameError, setNameError] = useState(true);
  const [descriptionError, setDescriptionError] = useState(true);
  const [urlDestination, setUrlDestination] = useState(undefined);
  const [deleteWarningDialogOpenState, setDeleteWarningDialogOpenState] = useState(false);
  
  const [token, setToken] = useContext(TokenContext);
  const [errorList, setErrorList] = useContext(ErrorListContext);
  const [reloadData, setReloadData] = useContext(ReloadDataContext);
  const [subscriptionStatus, /*setSubscriptionStatus*/] = useContext(SubscriptionStatusContext);
  
  const params = useParams();

  const navigate = useNavigate();
  
  const location = useLocation();
  
  //
  //
  const fetchDataCallback = useCallback( async () => {
	console.log("fetchDataCallback");

	let data = {
	  "action": "get_attribute_definition",
	  "payload": {
		'id' : params.attributeDefinitionId,
		'collection_id': params.collectionId,
	  }
	};
	
	const retVal = await callApi(token, setToken, data);
	console.log(retVal);
	setAttributeData(retVal.data);
	logErrorString(retVal.errorMessage, errorList, setErrorList);
	setAttributeType(retVal.data['Type']);
	setNameError(retVal.data['Name'].length === 0);
	setDescriptionError(retVal.data['Description'].length === 0);
	setDataLoaded(true);
  }, [token, setToken, params.attributeDefinitionId, params.collectionId]);

  //
  //
  useEffect(() => {
	console.log("useEffect - fetchDataCallback");
	if (props.isCreating !== true) {
	  fetchDataCallback();
	}
  }, [fetchDataCallback, props.isCreating, reloadData])
  
  //
  //
  useEffect(() => {
	if (urlDestination !== undefined) {
	  navigate(urlDestination);
	}
  }, [urlDestination, navigate]);

  //
  //
  if (!tokenIsValid(token)) {
    return <NotLoggedInMessage />;
  }
  // nor if subscription expired and page is restricted
  //
  if (restrictPage(subscriptionStatus, "AttributeDefinitionDetailPage")) {
    return <SubscriptionExpiredMessage />;
  }
  
  //
  // only proceed if we are in view or edit mode and have data to show
  // wont have loaded data if creating do proceed anyway in that case
  //
  if ((dataLoaded === false) && (props.isCreating !== true)) {
	return (
	  <CircularProgress />
	);
  }
  
  //
  //
  const editHandler = (event) => {
	console.log("editHandler");
    event.preventDefault();
	
	navigate("/collections/"+params.collectionId+"/attributes/"+params.attributeDefinitionId+"/update");
  }
  
  //
  //
  const saveHandler = async (event) => {
	console.log("saveHandler");
    event.preventDefault();
	
	if (updatedValues !== {}) {
	  // we have somethong change to save

	  // add in id for the entity to be updated
	  updatedValues['id'] = params.attributeDefinitionId;
	  if (props.collectionId !== null) {
	    updatedValues["collection_id"] = params.collectionId;
	  }
	  if (attributeType === 'Image') {
		// force not showing images in list of entities
	    updatedValues["IsDisplayed"] = false;
	  }
	  console.log(updatedValues);
	  
	  // send request to update the entity to the backend
	  let data = {
	    "action": 'update_attribute_definition',
	    "payload": updatedValues
	  };
	  
	  const retVal = await callApi(token, setToken, data);
	  console.log(retVal)
	  logErrorString(retVal.errorMessage, errorList, setErrorList)
	  
	  const success = retVal.data['success']
	  console.log(success);
	  
	  if (success === false) {
	    const msg = "Failed to update attribute - ensure attribute name is unique.";
	    logErrorString(msg, errorList, setErrorList);
	    return;
	  }
      
	  // clear tracking of changes
	  setUpdatedValues({});
	  
	  // trigger reload of data from the database
	  triggerDataReload(reloadData, setReloadData);
	  
	  // now go back to view mode
	  setUrlDestination("/collections/"+params.collectionId+"/attributes/"+params.attributeDefinitionId);
    }
  }
  
  //
  //
  const createHandler = async (event) => {
	console.log("createHandler");
    event.preventDefault();
	
	if (updatedValues !== {}) {
	  // we have something change to save

	  // add in id for the entity to be updated
	  let dataToSend = {}
	  dataToSend["collection_id"] = params.collectionId;
	  dataToSend["table_definition_id"] = params.entityType
	  dataToSend["attribute_type"] = attributeType
	  dataToSend["name"] = updatedValues["Name"]
	  dataToSend["config_info"] = updatedValues["ConfigInfo"]
	  if (attributeType === 'Image') {
		// force not showing images in list of entities
	    dataToSend["is_displayed"] = false
	  } else {
	    dataToSend["is_displayed"] = updatedValues["IsDisplayed"]
	  }
	  dataToSend["is_mandatory"] = updatedValues["IsMandatory"]
	  dataToSend["description"] = updatedValues["Description"]
	  console.log(dataToSend);
	  
	  // send request to update the entity to the backend
	  let data = {
	    "action": 'create_attribute_definition',
	    "payload": dataToSend
	  };
	  
	  const retVal = await callApi(token, setToken, data);
	  console.log(retVal)
	  logErrorString(retVal.errorMessage, errorList, setErrorList)
	
	  const attributeId = retVal.data['id']
	  console.log(attributeId);
	  
	  if (attributeId === null) {
	    const msg = "Failed to create attribute - ensure attribute name is unique.";
	    logErrorString(msg, errorList, setErrorList);
	    return;
	  }
      
	  // clear tracking of changes
	  setUpdatedValues({});
	  
	  // trigger reload of data from the database
	  triggerDataReload(reloadData, setReloadData);
	  
	  // Use the "tab" value from state (if it exists) to go back to the list for the entityType
	  let url = "/collections/"+params.collectionId+"/attributes"
	  if (location.state !== null) {
		if ('tabIndex' in location.state) {
		  url = url.concat("?tab=", location.state['tabIndex']);
		}
	  }
	  setUrlDestination(url);
    }
  }
  
  //
  //
  const cancelHandler = (event) => {
	console.log("cancelHandler");
    event.preventDefault();
	
	if (props.isCreating === true) {
	  // now go back to attributes list 
	  // use the "tab" value from state (if it exists) to go back to the list for the entityType
	  let url = "/collections/"+params.collectionId+"/attributes"
	  if (location.state !== null) {
		if ('tabIndex' in location.state) {
		  url = url.concat("?tab=", location.state['tabIndex']);
		}
	  }
      navigate(url);
	  
	} else {
	  
	  // reset tracking of changes and put fields back to the state they were
	  setUpdatedValues({});
	  fetchDataCallback();
	  
	  // now go back to view mode
      navigate("/collections/"+params.collectionId+"/attributes/"+params.attributeDefinitionId);
	  
	  // trigger reload of data from the database
	  // do this after call to 'navigate'
	  triggerDataReload(reloadData, setReloadData);
	}
  }
  
  //
  //
  const deleteHandler = (event) => {
	console.log("deleteHandler");
    event.preventDefault();
	
	setDeleteWarningDialogOpenState(true);
  }
	
  const deleteAction = async () => {
	console.log("deleteAction");
	
	// send request to update the entity to the backend
	let data = {
	  "action": 'delete_attribute_definition',
	  "payload": {
		  'id': params.attributeDefinitionId
	  }
	};
	console.log(data);
	
	const retVal = await callApi(token, setToken, data);
	console.log(retVal)
	logErrorString(retVal.errorMessage, errorList, setErrorList)

	// trigger reload of data from the database
	//triggerDataReload(reloadData, setReloadData);
	
	// Use the "tab" value from state (if it exists) to go back to the list for the entityType
	let url = "/collections/"+params.collectionId+"/attributes"
	if (location.state !== null) {
	  if ('tabIndex' in location.state) {
		url = url.concat("?tab=", location.state['tabIndex']);
	  }
	}
	setUrlDestination(url);
  }
  
  //
  //
  const backHandler = (event) => {
	console.log("backHandler");
    event.preventDefault();
	
	// now go back to attributes list 
	// use the "tab" value from state (if it exists) to go back to the list for the entityType
	let url = "/collections/"+params.collectionId+"/attributes"
	if (location.state !== null) {
	  if ('tabIndex' in location.state) {
		url = url.concat("?tab=", location.state['tabIndex']);
	  }
	}
    navigate(url);
  }


  // set up variables for View mode
  //
  let isReadOnly = true;
  let card_border_colour = '1px solid black';
  let editOrSaveButtonJSX = <Button variant="contained" type='submit' onClick={editHandler}>Edit</Button>
  let cancelButtonJSX = "";
  let headerText = "View";
  let deleteButtonJSX = <Button variant="contained" type='submit' onClick={deleteHandler}>Delete</Button>
  let attribteTypeSelectionJSX = ""
  let backButtonJSX = <Button variant="contained" type='submit' onClick={backHandler}>Back</Button>

  //
  // or for Edit mode
  //
  if (props.editMode === true) {
	  isReadOnly = false;
	  card_border_colour = '2px solid red';
	  cancelButtonJSX = <Button variant="contained" type='submit' onClick={cancelHandler}>Cancel</Button>
	  deleteButtonJSX = "";
	  backButtonJSX = "";
	  
	  // are we creating or updating...?
	  if (props.isCreating === true) {
	    headerText = "Create";
	    editOrSaveButtonJSX = <Button variant="contained" type='submit' disabled={nameError || descriptionError} onClick={createHandler}>Create</Button>
		attribteTypeSelectionJSX = (
		    <Tooltip arrow title="Select the type for the attribute from the options available.">
			<FormControl sx={{ m: 1, minWidth: 120 }}>
			  <InputLabel id="attribute-type-label">Attribute Type</InputLabel>
			  <Select
			    labelId="attribute-type-label"
                id="attribute-type"
                defaultValue={attributeData['Type']}
				onChange = {(e) => {
				  let dupUpdatedValues = updatedValues;
				  dupUpdatedValues['Type'] = e.target.value;
				  setUpdatedValues(dupUpdatedValues);
				  setAttributeType(e.target.value);
				  }}
                label="Attribute Type"
              >
                {Object.keys(validAttributeTypes).map((attributeType, i) => (
                  <MenuItem value={attributeType}>{attributeType}</MenuItem>
                ))}
              </Select>
            </FormControl>
			</Tooltip>
		)
	  } else {
	    headerText = "Update";
	    editOrSaveButtonJSX = <Button variant="contained" type='submit' disabled={nameError || descriptionError} onClick={saveHandler}>Save</Button>
		attribteTypeSelectionJSX = ""
	  }
  }
  
  // get appropriate name of the entity for this attribute 
  //
  let entityType = 999
  if (props.isCreating === true) {
	entityType = params.entityType;
  } else {
	entityType = attributeData['EntityType']
  }	
  const entityTypeName = Constants.EntityTypeName(entityType);
  
  // set up helper text if needed
  //
  let nameRequired = false;
  let nameHelperText = "";
  let descriptionRequired = false;
  let descriptionHelperText = "";
  if ((props.editMode === true) || (props.isCreating === true)) {
    nameRequired = true;
	descriptionRequired = true;
	if (nameError === true) {
	  nameHelperText = "A unique name must be specified.";
	}
	if (descriptionError === true) {
	  descriptionHelperText = "A Description must be specified.";
	}
  }
  
  let defaultCheckedValue = false;
  if (attributeType !== 'Image') {
	defaultCheckedValue = attributeData['IsDisplayed'];	
  }
  
  return (
    <Stack spacing={2}>
      <Grid container justifyContent="center">
        <h1>{headerText} Attribute Definition - {entityTypeName}</h1>
	  </Grid>
      <Grid container justifyContent="center">
	    <Card variant="outlined" sx={{ border: card_border_colour }}>
	      <Box m={2} >
            <form>
	          <Stack sx={{width: '50ch'}} spacing={2} noValidate autoComplete="off">
			    
				<Typography style={{ fontWeight: 600 }} >
				  Attribute Definition
				</Typography>
				
				<Tooltip arrow title="Specify the name for the attribute. Note that this has to be unique for the attributes of this item type.">
			    <TextField
			      id='Name'
                  label='Name'
			      defaultValue={attributeData['Name']}
                  InputProps={{
                    readOnly: isReadOnly,
                  }}
				  required={nameRequired}
				  error={nameError}
				  helperText={nameHelperText}
				  sx={{mb: 3}}
				  multiline
				  maxRows={4}
				  onChange = {(e) => {
					let dupUpdatedValues = updatedValues;
					dupUpdatedValues['Name'] = e.target.value;
					setUpdatedValues(dupUpdatedValues);
					setNameError(dupUpdatedValues['Name'].length === 0);
					}}
                  />
				</Tooltip>
				
				<Tooltip arrow title="Provide a description for the attribute.">
			    <TextField
			      id='Description'
                  label='Description'
			      defaultValue={attributeData['Description']}
                  InputProps={{
                    readOnly: isReadOnly,
                  }}
				  required={descriptionRequired}
				  error={descriptionError}
				  helperText={descriptionHelperText}
				  sx={{mb: 3}}
				  multiline
				  maxRows={4}
				  onChange = {(e) => {
					let dupUpdatedValues = updatedValues;
					dupUpdatedValues['Description'] = e.target.value;
					setUpdatedValues(dupUpdatedValues);
					setDescriptionError(dupUpdatedValues['Description'].length === 0);
					}}
                  />
				</Tooltip>
			    
				<Tooltip arrow title="Tick this box if you want the attribute to be displayed in lists. It will always be displayed when viewing or updating the item's details. Note this flag is disabled for Images and will be ignored.">
				<FormControlLabel
				  control={<Checkbox
					defaultChecked={defaultCheckedValue}
					disabled={isReadOnly || (attributeType==='Image')} />}
				  label="Show in summary"
				  onChange={(e) => {
					let dupUpdatedValues = updatedValues;
					dupUpdatedValues['IsDisplayed'] = e.target.checked;
					setUpdatedValues(dupUpdatedValues);
				  }}
				/>
				</Tooltip>
				
				<Tooltip arrow title="Tick this box if you want the attribute to be mandatory.">
				<FormControlLabel
				  control={<Checkbox
					defaultChecked={attributeData['IsMandatory']}
					disabled={isReadOnly} />}
				  label="Mandatory"
				  onChange={(e) => {
					let dupUpdatedValues = updatedValues;
					dupUpdatedValues['IsMandatory'] = e.target.checked;
					setUpdatedValues(dupUpdatedValues);
				  }}
				/>
				</Tooltip>
				
				{attribteTypeSelectionJSX}
				
			    <AttributeConfiguration
				  editMode={props.editMode}
				  attributeType={attributeType}
				  defaultConfigInfo={attributeData['ConfigInfo']}
				  onConfigInfoChange={(newConfigInfo) => {
					let dupUpdatedValues = updatedValues;
					dupUpdatedValues['ConfigInfo'] = newConfigInfo;
					setUpdatedValues(dupUpdatedValues);
				  }}
				/>
				
                <Stack direction='row' spacing={1} >
			      {cancelButtonJSX}
			      {editOrSaveButtonJSX}
			      {deleteButtonJSX}
				  {backButtonJSX}
                </Stack>
			  
    	      </Stack>
            </form>
		  </Box>
	    </Card>
	  </Grid>
	  <AlertDialog
	    openState={deleteWarningDialogOpenState}
	    setOpenState={setDeleteWarningDialogOpenState}
	    showButtons={true}
	    optionalDialog={null}
	    actionFunction={deleteAction}
	    title="Warning"
	    text="Are you sure you want to delete this?"
	  />
	</Stack>
  );
}

export default AttributeDefinitionDetailPage;
