import React, { useContext, useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import callApi from '../api/api';
import { tokenIsValid, TokenContext } from '../auth/token';
import { ErrorListContext, logErrorString } from '../error/errorList'

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import { SubscriptionStatusContext, restrictPage } from '../context/subscriptionStatus'
import SubscriptionExpiredMessage from '../components/SubscriptionExpiredMessage'

import NotLoggedInMessage from '../components/NotLoggedInMessage'


function CollectionsCreateFromTemplate() {
  
  const [originalCollectionId, setOriginalCollectionId] = useState(0);
  const [templates, setTemplates] = useState([]);
  const [templateDescription, setTemplateDescription] = useState("");
  const [dataLoaded, setDataLoaded] = useState(false);
  
  const [token, setToken] = useContext(TokenContext);
  const [errorList, setErrorList] = useContext(ErrorListContext);
  const [subscriptionStatus, /*setSubscriptionStatus*/] = useContext(SubscriptionStatusContext);
  
  const navigate = useNavigate();
  
  const location = useLocation();

  //
  // TODO - change this to get list of templates
  //
  const fetchDataCallback = useCallback( async () => {
	const data = {
	  "action": "get_template_list",
	  "payload": { }
	};
	
	const retVal = await callApi(token, setToken, data);
	console.log(retVal)
	setTemplates(retVal.data);
	if (retVal.data.length > 0) {
	  const defaultId = retVal.data[0]['id'];
	  setOriginalCollectionId(defaultId);
	  let template = retVal.data.find(t => t['id'] === defaultId);
	  setTemplateDescription(template['Description']);
	} else {
	  setOriginalCollectionId(0);
	}
		
	setDataLoaded(true);
	logErrorString(retVal.errorMessage, errorList, setErrorList)
	
  }, [token, setToken]);

  useEffect(() => {
	console.log("CollectionsCreateFromTemplate - useEffect");
	fetchDataCallback();
  }, [fetchDataCallback])
  
  // do not allow to proceed if not logged in
  //
  if (!tokenIsValid(token)) {
    return <NotLoggedInMessage />;
  }
  // nor if subscription expired and page is restricted
  //
  if (restrictPage(subscriptionStatus, "CollectionsCreateFromTemplate")) {
    return <SubscriptionExpiredMessage />;
  }
  
  const collectionName = location.state['collectionName'];
  
  //
  //
  const createHandler = async (event) => {
	console.log("createHandler");
    event.preventDefault();

	console.log(originalCollectionId);
	
	// send request to create the collection to the backend
	let data = {
	  "action": "create_job",
	  "payload": {
	    "job_name" : "copy_collection",
		"job_params": {
		  'id': originalCollectionId,
		  'Name': collectionName,
		  'Mode': 'collectables', // always definitions and collectables, never examples data
		}
	  }
	};
	console.log(data);
	
	const retVal = await callApi(token, setToken, data);
	console.log(retVal);
	logErrorString(retVal.errorMessage, errorList, setErrorList)
	
	const jobId = retVal.data['job_id']
	console.log(jobId);
	const started = retVal.data['started']
	console.log(started);
	
	if (jobId === null) {
	  console.log("FAILED TO CREATE - TODO ADD ALERT");
	} else {
	  navigate("/tasks/" + jobId);
	}
  };
  
  //
  //
  const cancelHandler = (event) => {
	console.log("cancelHandler");
    event.preventDefault();
	navigate("/collections");
  };
  
  //
  //
  if (dataLoaded === false) {
    return (
	  <Grid container justifyContent="center">
		<Stack spacing={2}>
		  <Grid container justifyContent="center">
			<h1>Create Collection: {collectionName}</h1>
		  </Grid>
		  <Grid container justifyContent="center">
            <CircularProgress />
		  </Grid>
		</Stack>
	  </Grid>
	);
  }
  
  //
  //
  let templatesJSX = (
    <Grid container justifyContent="center">
      <p>There are no templates available.</p>
	</Grid>
  );
  if (templates.length > 0) {
	templatesJSX = (
	  <>
      <FormLabel id="copy-item-select-label">Which template do you want to use:</FormLabel>
	  <Select
		labelId="copy-item-select-label"
		id="copy-item-select"
		value={originalCollectionId}
		onChange={(event) => {
		  const selectedId = event.target.value
		  setOriginalCollectionId(selectedId);
		  let template = templates.find(t => t['id'] === selectedId);
		  setTemplateDescription(template['Description']);
		  }}
	  >
	    {templates.map((template) => (
	      <MenuItem value={template['id']}>{template['Name']}</MenuItem>
		))}
	  </Select>
		<TextField
		  id="Description"
		  value={templateDescription}
		  InputProps={{
			readOnly: true,
		  }}
		  sx={{mb: 3}}
		  multiline
		  rows={4}
		/>
	  </>
	)
  }
  
  return (
    <Grid container justifyContent="center">
      <Stack spacing={2}>
        <Grid container justifyContent="center">
		  <h1>Create Collection: {collectionName}</h1>
		</Grid>
        <Grid container justifyContent="center">
	      <Card variant="outlined">
	        <Box m={2}>
		      <FormControl>
		        <Stack spacing={1} >
				  {templatesJSX}
				  <Grid container justifyContent="center">
					<Stack direction='row' spacing={1} >
					  <Button variant="contained" disabled={templates.length === 0} type='submit' onClick={createHandler}>Create</Button>
					  <Button variant="contained" type='submit' onClick={cancelHandler}>Cancel</Button>
					</Stack>
				  </Grid>
		        </Stack>
              </FormControl>
		    </Box>
		  </Card>
	    </Grid>
      </Stack>
	</Grid>
  );
}

export default CollectionsCreateFromTemplate;
