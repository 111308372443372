import React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';


function SupportReferenceManageESPage() {
  return (
	<Box sx={{ width: 550 }}>
	  <Typography>
		When you click on an entry in the list of Example Sets the page to view the individual Example Set is displayed. This page consists of the following elements
		<ul><li>A block showing the attributes for the Collectable Set which this Example Set is an example of in your collection, with a button to go and view that Collectable Set
		</li><li>A block showing the attributes for the Example Set, with buttons to be able to edit or delete it
		</li><li>A table showing the list of Example Items that make up this Example Set
		</li></ul>
	  </Typography>
	  <br/><br/>
	</Box>
  );
};

export default SupportReferenceManageESPage;
