import React, { useContext, useState, useEffect, useCallback } from 'react';
import { useParams } from "react-router-dom"

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import callApi from '../api/api';
import { TokenContext } from '../auth/token'
import { ErrorListContext, logErrorString } from '../error/errorList'
import {
  CurrentCollectionContext,
  checkCollectableSetsValidForCollection,
  checkCollectableItemsValidForCollection,
  checkExampleSetsValidForCollection,
  checkExampleItemsValidForCollection
  } from '../context/currentCollection'
import { ReloadDataContext } from '../context/reloadData'

import CollectionStatsItem from './CollectionStatsItem'


function CollectionStats(props) {
  
  console.log("CollectionStats - top");

  const [collectionStats, setCollectionStats] = useState({
	  'collectable_set': '-',
	  'collectable_item': '-',
	  'example_set': '-',
	  'example_item': '-',
	  'attribute': '-'
  });

  const params = useParams();
  const [token, setToken] = useContext(TokenContext);
  const [errorList, setErrorList] = useContext(ErrorListContext);
  const [currentCollection, /*setCurrentCollection*/] = useContext(CurrentCollectionContext);
  const [reloadData, /*setReloadData*/] = useContext(ReloadDataContext);

  const fetchDataCallback = useCallback( async () => {
	console.log("CollectionStats - fetchDataCallback");
	const data = {
	  "action": "get_collection_stats",
	  "payload": { 'id' : params.collectionId }
	};
	
	const retVal = await callApi(token, setToken, data);
	console.log(retVal)
	setCollectionStats(retVal.data);
	logErrorString(retVal.errorMessage, errorList, setErrorList)
  }, [params.collectionId, token, setToken]);

  useEffect(() => {
	console.log("CollectionStats - useEffect - fetchDataCallback");
	fetchDataCallback();
  }, [fetchDataCallback, reloadData])
  
  
  let csStatsJSX = "";
  if (checkCollectableSetsValidForCollection(currentCollection, params.collectionId)) {
    csStatsJSX = (
	  <CollectionStatsItem
	    title="Collectable Sets"
		count={collectionStats['collectable_set']}
		description="TODO - add some text about Collectable Sets..."
		jumpToPath={"/collections/"+params.collectionId+"/cs/"}
	  />
	);
  }
  
  let ciStatsJSX = "";
  if (checkCollectableItemsValidForCollection(currentCollection, params.collectionId)) {
    ciStatsJSX = (
	  <CollectionStatsItem
	    title="Collectable Items"
		count={collectionStats['collectable_item']}
		description="TODO - add some text about Collectable Items..."
		jumpToPath={"/collections/"+params.collectionId+"/ci/"}
	  />
	);
  }
  
  let esStatsJSX = "";
  if (checkExampleSetsValidForCollection(currentCollection, params.collectionId)) {
    esStatsJSX = (
	  <CollectionStatsItem
	    title="Example Sets"
		count={collectionStats['example_set']}
		description="TODO - add some text about Example Sets..."
		jumpToPath={"/collections/"+params.collectionId+"/es/"}
	  />
	);
  }
  
  let eiStatsJSX = "";
  if (checkExampleItemsValidForCollection(currentCollection, params.collectionId)) {
    eiStatsJSX = (
	  <CollectionStatsItem
	    title="Example Items"
		count={collectionStats['example_item']}
		description="TODO - add some text about Example Items..."
		jumpToPath={"/collections/"+params.collectionId+"/ei/"}
	  />
	);
  }

  let attributesStatsJSX = (
	<CollectionStatsItem
	  title="Attributes"
	  count={collectionStats['attribute']}
	  description="TODO - add some text about Attributes..."
	  jumpToPath={"/collections/"+params.collectionId+"/attributes/"}
	/>
  );
  
  console.log(collectionStats);
  
  return (
	<Box sx={{ width: '100%', maxWidth: 360 }}>
	  <Stack spacing={2}>
		  {csStatsJSX}
		  {ciStatsJSX}
		  {esStatsJSX}
		  {eiStatsJSX}
		  {attributesStatsJSX}
	  </Stack>
	</Box>
  );
}

export default CollectionStats;
