import React, { useContext, useState, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import Stack from '@mui/material/Stack';
import { DataGridPro as DataGrid } from '@mui/x-data-grid-pro';

import callApi from '../api/api';
import { tokenIsValid, TokenContext } from '../auth/token'
import { ErrorListContext, logErrorString } from '../error/errorList'

import { SubscriptionStatusContext, restrictPage } from '../context/subscriptionStatus'
import SubscriptionExpiredMessage from '../components/SubscriptionExpiredMessage'

import NotLoggedInMessage from '../components/NotLoggedInMessage'
import CustomColumnMenu from '../components/CustomColumnMenu'
import AlertDialog from '../components/AlertDialog'


function TasksPage() {
  
  const [entityData, setEntityData] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [createdDialogOpenState, setCreatedDialogOpenState] = useState(false);

  const [token, setToken] = useContext(TokenContext);
  const [errorList, setErrorList] = useContext(ErrorListContext);
  const [subscriptionStatus, /*setSubscriptionStatus*/] = useContext(SubscriptionStatusContext);

  const navigate = useNavigate();

  //
  //
  const fetchDataCallback = useCallback( async () => {
	const data = {
	  "action": 'get_job_list',
	  "payload": { }
	};
	
	const retVal = await callApi(token, setToken, data);
	console.log("TasksPage: retVal=...")
	console.log(retVal);
	
	setEntityData(retVal.data.job_info);
	setDataLoaded(true);
	logErrorString(retVal.errorMessage, errorList, setErrorList)
	
  }, [token, setToken]);

  //
  //
  useEffect(() => {
	fetchDataCallback();
  }, [fetchDataCallback])

  // do not allow to proceed if not logged in
  //
  if (!tokenIsValid(token)) {
    return <NotLoggedInMessage />;
  }
  // nor if subscription expired and page is restricted
  //
  if (restrictPage(subscriptionStatus, "TasksPage")) {
    return <SubscriptionExpiredMessage />;
  }
  
  //
  //
  const refreshHandler = async (event) => {
	console.log("refreshHandler");
    event.preventDefault();
	
    setCreatedDialogOpenState(true);
    setTimeout(() => {
	  setCreatedDialogOpenState(false);
	  window.scrollTo({top: 0, left: 0, behavior: "smooth" });
	  }, 500);
	
	fetchDataCallback();
  }
  
  //
  //
  const rowClickHandler = ((params, event, details) => {
    console.log("row clicked - ID="+params.id)
    console.log(params)
    console.log(details)
	
	navigate("/tasks/" + params.id);
  });
  
  //
  //
  // Possible columns:
  // 	'id'
  //    'Name'
  //    'Description'
  //    'Started'
  //    'Completed'
  //    'StatusCode'
  //    'StatusMessage'
  //    'URL'
  //    'LastUpdated'
  //
  const columns = [
	{
	  field: 'Name',
	  headerName: 'Name',
	  width: 200,
	  resizable: true,
	  headerClassName: 'datagrid-column-header'
	},
	{
	  field: 'Description',
	  headerName: 'Description',
	  width: 200,
	  resizable: true,
	  headerClassName: 'datagrid-column-header'
	},
	{
	  field: 'Started',
	  headerName: 'Started',
	  width: 200,
	  resizable: true,
	  headerClassName: 'datagrid-column-header'
	},
	{
	  field: 'Completed',
	  headerName: 'Completed',
	  width: 200,
	  resizable: true,
	  headerClassName: 'datagrid-column-header'
	},
	{
	  field: 'StatusCode',
	  headerName: 'StatusCode',
	  width: 200,
	  resizable: true,
	  headerClassName: 'datagrid-column-header'
	},
	{
	  field: 'StatusMessage',
	  headerName: 'StatusMessage',
	  width: 200,
	  resizable: true,
	  headerClassName: 'datagrid-column-header'
	},
  ];
  
  return (
    <Grid container justifyContent="center">
      <Stack>
        <Grid container justifyContent="center">
          <h1>Your Tasks</h1>
        </Grid>
        <Grid container justifyContent="right">
          <IconButton aria-label="refresh" onClick={refreshHandler}>
            <RefreshIcon />
		   </IconButton>
	    </Grid>
		<Grid container justifyContent="center">
		  <div style={{ height: 400, width: '100%' }}>
		    <DataGrid
			  initialState={{
			    sorting: {
				  sortModel: [{ field: 'Started', sort: 'desc' }],
				},
				pagination: {
				  paginationModel: {
					pageSize: 10,
				  }
				},
			  }}
			  slots={{ columnMenu: CustomColumnMenu }}
			  rows={entityData}
			  columns={columns}
			  loading={!dataLoaded}
			  pageSize={3}
			  pagination
			  pageSizeOptions={
				[10, 20, 50, 100]
			  }
			  onRowClick = {rowClickHandler}
		      sx={{ bgcolor: '#ffffff' }}
			/>
		  </div>
	    </Grid>
	  </Stack>
	  <AlertDialog
	    openState={createdDialogOpenState}
	    setOpenState={setCreatedDialogOpenState}
	    showButtons={false}
	    optionalDialog={null}
	    actionFunction={null}
	    title=""
	    text="Updating..."
	  />
	</Grid>
  );
}

export default TasksPage;
