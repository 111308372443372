import React from 'react';


function GenericPageHeader () {
  return (
    <h1>Your Collections, Your Way!</h1>
  );
}

export default GenericPageHeader;
