import React, { useContext, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import callApi from '../api/api';
import { tokenIsValid, TokenContext } from '../auth/token';
import { ErrorListContext, logErrorString } from '../error/errorList'

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import { SubscriptionStatusContext, restrictPage } from '../context/subscriptionStatus'
import SubscriptionExpiredMessage from '../components/SubscriptionExpiredMessage'

import NotLoggedInMessage from '../components/NotLoggedInMessage'
import { CurrentCollectionContext, changeCurrentCollectionInfo } from '../context/currentCollection'


function CollectionsCreateFromScratchPage() {

  const [newType, setNewType] = useState("Planned");
  const [newHasSets, setNewHasSets] = useState(true);
  const [newDescription, setNewDescription] = useState("");
  const [newCollectionId, setNewCollectionId] = useState(undefined);
  
  const [token, setToken] = useContext(TokenContext);
  const [errorList, setErrorList] = useContext(ErrorListContext);
  const [/*currentCollection*/, setCurrentCollection] = useContext(CurrentCollectionContext);
  const [subscriptionStatus, /*setSubscriptionStatus*/] = useContext(SubscriptionStatusContext);
  
  const navigate = useNavigate();
  
  const location = useLocation();
  
  //
  //
  useEffect(() => {
	if (newCollectionId !== undefined) {
	  navigate("/collections/" + newCollectionId + "/attributes");
	}
  }, [newCollectionId, navigate]);

  // do not allow to proceed if not logged in
  //
  if (!tokenIsValid(token)) {
    return <NotLoggedInMessage />;
  }
  // nor if subscription expired and page is restricted
  //
  if (restrictPage(subscriptionStatus, "CollectionsCreateFromScratchPage")) {
    return <SubscriptionExpiredMessage />;
  }
  
  const collectionName = location.state['collectionName'];
  
  //
  //
  const createHandler = async (event) => {
	console.log("createHandler");
    event.preventDefault();

	console.log(newType);
	console.log(newDescription);
	console.log(newHasSets);
	
	// send request to create the collection to the backend
	let data = {
	  "action": "create_collection",
	  "payload": {
	    "Name" : collectionName,
        "Type": newType,
        "Description": newDescription,
		"HasSets": newHasSets,
	  }
	};
	console.log(data);
	
	const retVal = await callApi(token, setToken, data);
	console.log(retVal);
	logErrorString(retVal.errorMessage, errorList, setErrorList)
	
	let collectionId = retVal.data['id']
	console.log(collectionId);
	
	if (collectionId === null) {
	  const msg = "Failed to create collection - ensure collection name is unique.";
	  logErrorString(msg, errorList, setErrorList);
	  return;
	}
	
	//
	// We have effectively selected a different collection, so update the global context
	// with the informaiton that we need to control page contents
	//
	changeCurrentCollectionInfo(collectionId, newType, newHasSets, setCurrentCollection)

	// trigger reload of data - NOT NEEDED...
	//triggerDataReload(reloadData, setReloadData);
	
	// trigger async navigate to attributes set up page for this new collection
	setNewCollectionId(collectionId);
  };
  
  //
  //
  const cancelHandler = (event) => {
	console.log("cancelHandler");
    event.preventDefault();
	navigate("/collections");
  };
  
  return (
    <Grid container justifyContent="center">
      <Stack spacing={2}>
        <Grid container justifyContent="center">
		  <h1>Create Collection: {collectionName}</h1>
		</Grid>
        <Grid container justifyContent="center">
	      <Card variant="outlined">
	        <Box m={2}>
              <FormControl>
		        <Stack spacing={1} >
			      <FormLabel id="collections-type-group-label">Type</FormLabel>
                  <RadioGroup
				    row
					labelId="collections-type-group-label"
					name="collection-type-group"
					defaultValue={newType}
				  >
				    <FormControlLabel
					  value="Catalog"
					  control={<Radio />}
					  label="Catalog"
					  onChange = {(e) => {
						setNewType(e.target.value);
				      }}
					/>
					<FormControlLabel
					  value="Planned"
					  control={<Radio />}
					  label="Planned"
					  onChange = {(e) => {
						setNewType(e.target.value);
				      }}
					/>
					<FormControlLabel
					  value="Ad-Hoc"
					  control={<Radio />}
					  label="Ad-Hoc"
					  onChange = {(e) => {
						setNewType(e.target.value);
				      }}
					/>
				  </RadioGroup>
			      <FormLabel id="collections-has-sets-group-label">Allow sets to be defined</FormLabel>
                  <RadioGroup
				    row
					aria-labelledby="collections-hs-sets-group-label"
					name="collection-type-group"
					defaultValue={newHasSets}
				  >
				    <FormControlLabel
					  value={true}
					  control={<Radio />}
					  label="Yes"
					  onChange = {(e) => {
						setNewHasSets(e.target.value);
				      }}
					/>
					<FormControlLabel
					  value={false}
					  control={<Radio />}
					  label="No"
					  onChange = {(e) => {
						setNewHasSets(e.target.value);
				      }}
					/>
				  </RadioGroup>
				  <TextField
				    id="Description"
				    label="Description"
				    defaultValue={newDescription}
				    sx={{mb: 3}}
				    multiline
				    maxRows={4}
				    //color="secondary"
				    onChange = {(e) => {
					  setNewDescription(e.target.value);
				    }}
				  />
		          <Stack direction='row' spacing={1} >
				    <Button variant="contained" type='submit' onClick={createHandler}>Create</Button>
				    <Button variant="contained" type='submit' onClick={cancelHandler}>Cancel</Button>
		          </Stack>
                </Stack>
              </FormControl>
		    </Box>
	      </Card>
	    </Grid>
      </Stack>
	</Grid>
  );
}

export default CollectionsCreateFromScratchPage;
