import React from 'react';

import Typography from '@mui/material/Typography';


function DateAttributeConfiguration (props) {
	// for Date we ignore the props.editMode passed in
	return (
	  <Typography>
	    No configuration required for Date attributes.
	  </Typography>
	);
}

export default DateAttributeConfiguration;
