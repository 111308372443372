import React from 'react';

import Grid from '@mui/material/Grid';

import FAQSet from '../components/FAQSet'

import conceptItems from '../support_data/ref_concepts_data.json'


function SupportReferenceConceptsPage() {
  return (
	<Grid>
	  <FAQSet faqItems={conceptItems} />
	  <br/><br/>
	</Grid>
  );
};

export default SupportReferenceConceptsPage;
