import React from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import create_6 from '../support_data/create_6.png'
import create_7 from '../support_data/create_7.png'

function SupportReferenceCreatePage() {
  return (
	<Box sx={{ width: 550 }}>
      <Grid container justifyContent="center">
        <img src={create_6} width="494" height="306" alt="" />
	  </Grid>
	  <br/>
	  <Typography>
		The collection can be created by importing information from an Excel spreadsheet using our proprietary format. Please go to the “Appendix – import/export user guide” for more details.
		<br/><br/>Click on the “Select File” button to brose to and select the file to use for import. This will populate the filename box which will go from red to black once a file has been successfully selected.
		<br/><br/>At that point the two options to either Validate or Import will be enabled.
		<br/><br/>If you are unsure if the format is correct, then you can use the Validate option which will not create the collection but check that the format of the selected file is valid.
		<br/><br/>Alternatively, you can click on Import which will check the format of the selected file is correct before proceeding to create the collection if it is.
		<br/><br/>When you click on either the Validate or Import buttons, a new task will be created that carried out the process in the background, displaying the task’s detail page like the one below.
	  </Typography>
	  <br/>
      <Grid container justifyContent="center">
        <img src={create_7} width="434" height="311" alt="" />
	  </Grid>
	  <br/>
	  <Typography>
		Once the import has completed in the background (which may take some time depending on how big a collection you are importing), the status of the task will change and the new collection will be added to your list of collections.
		<br/><br/>Note that the new collection will be available in the list before the whole process completes, but not all the information being imported will be available until the whole process has completed.
	  </Typography>
	  <br/><br/>
	</Box>
  );
};

export default SupportReferenceCreatePage;
