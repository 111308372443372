import { useNavigate, useParams } from "react-router-dom";

import CardActionArea from '@mui/material/CardActionArea';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import StyledCard from '../components/StyledCard'


function AttributeDefinitionInList({attribute, tabIndex}) {
  const params = useParams();
  
  const navigate = useNavigate();
  
  const handleClick = (event) => {
    event.preventDefault();
	// Note - passes "tabIndex" into location.state so can return
	//        back to correct tab when done creating new attribute
	navigate(
	  "/collections/"+params.collectionId+"/attributes/"+attribute['id'],
	  {state: { 'tabIndex': tabIndex}}
	);
  };
  
  return (
    <StyledCard>
	  <CardActionArea onClick={handleClick}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
	      <Typography>
		    {attribute['Name']}
		  </Typography>
	      <Typography style={{ fontWeight: 600 }} >
			{attribute['Type']}
		  </Typography>
 	    </Stack>
	  </CardActionArea>
	</StyledCard>
  );
}

export default AttributeDefinitionInList;
