import React from 'react';
import { Link } from 'react-router-dom';

import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';


function NotLoggedInMessage () {
  return (
    <Grid container justifyContent="center">
	  <Stack spacing={2}>
	    <Grid container justifyContent="center">
		  <h1>Invalid Page</h1>
	    </Grid>
	    <Grid container justifyContent="center">
		  <Stack justifyContent="space-between" alignItems="center" spacing={2}>
		    <p>Sorry you are NOT logged in.</p>
	        <p>Please <Link to="/login">login</Link> to access the portal.</p>
		  </Stack>
	    </Grid>
	  </Stack>
    </Grid>
  );
};

export default NotLoggedInMessage;
