import React, { useContext } from 'react';
import { useNavigate } from "react-router-dom";

import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Button from '@mui/material/Button';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { CurrentCollectionContext, changeCurrentCollectionInfo } from '../context/currentCollection'
import { collectionIcon } from '../icons/icons'


function CollectionInList({collection}) {
  const navigate = useNavigate();
  const [/*currentCollection*/, setCurrentCollection] = useContext(CurrentCollectionContext);
  
  const icon = collectionIcon(collection['Type'], collection['HasSets'])
  
  function buttonClickHandler (event, collection_id, collection_type, collection_has_sets) {
	//
	// We have selected a different collection, so update the global context
	// with the informaiton that we need to control page contents
	//
	changeCurrentCollectionInfo(collection_id, collection_type, collection_has_sets, setCurrentCollection)
	
	// now show the collection info
	navigate("/collections/"+collection_id);
  }

  // Extract the set of attributes each Colleciton will have
  let attributes = Object.keys(collection);
	
  // we remove the id property that we know must be present as we dont want to show that
  if (attributes.includes('id')) {
	attributes = attributes.filter( attribute => attribute !== 'id');
  }
  
  let descriptionText = collection['Description'];
  if (descriptionText === "") {
	descriptionText = "NOTE - the description would be here but has not been set - please 'View' then 'Edit' the collection to enter it.";
  };
  
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
	    <Grid container alignItems="center" spacing={2}>
		  <Grid item>
		    <img
	          src={icon}
		      width="40"
		      height="40"
		      alt=""
	        />
		  </Grid>
		  <Grid item>
	        <Typography>{collection['Name']}</Typography>
		  </Grid>
		</Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>{descriptionText}</Typography>
      </AccordionDetails>
	  <AccordionActions>
	    <Button onClick={(event) => {
		  buttonClickHandler(event, collection['id'], collection['Type'], collection['HasSets']);
		}}>
		  View
		</Button>
	  </AccordionActions>
    </Accordion>
  );
}

export default CollectionInList;
