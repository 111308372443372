import React, { useState, useContext, useCallback, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import RefreshIcon from '@mui/icons-material/Refresh';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import callApi from '../api/api';
import { tokenIsValid, TokenContext } from '../auth/token'
import { ErrorListContext, logErrorString } from '../error/errorList'

import { SubscriptionStatusContext, restrictPage } from '../context/subscriptionStatus'
import SubscriptionExpiredMessage from '../components/SubscriptionExpiredMessage'

import NotLoggedInMessage from '../components/NotLoggedInMessage'
import AlertDialog from '../components/AlertDialog'


function TaskDetailPage() {

  const [entityData, setEntityData] = useState({});
  const [dataLoaded, setDataLoaded] = useState(false);
  const [createdDialogOpenState, setCreatedDialogOpenState] = useState(false);
  
  const [token, setToken] = useContext(TokenContext);
  const [errorList, setErrorList] = useContext(ErrorListContext);
  const [subscriptionStatus, /*setSubscriptionStatus*/] = useContext(SubscriptionStatusContext);
  
  const params = useParams();

  //
  //
  const fetchDataCallback = useCallback( async () => {
	const data = {
	  "action": 'get_job',
	  "payload": {
		  'job_id': params.jobId,
	  }
	};
	
	const retVal = await callApi(token, setToken, data);
	console.log("TaskDetailPage: retVal=...")
	console.log(retVal);
	
	setEntityData(retVal.data.job_info);
	setDataLoaded(true);
	logErrorString(retVal.errorMessage, errorList, setErrorList)
	
  }, [token, setToken, params.jobId]);

  //
  //
  useEffect(() => {
	fetchDataCallback();
  }, [fetchDataCallback])

  // do not allow to proceed if not logged in
  //
  if (!tokenIsValid(token)) {
    return <NotLoggedInMessage />;
  }
  // nor if subscription expired and page is restricted
  //
  if (restrictPage(subscriptionStatus, "TaskDetailPage")) {
    return <SubscriptionExpiredMessage />;
  }
    
  if (dataLoaded === false) {
	return (
      <Grid container justifyContent="center">
        <CircularProgress />
	  </Grid>
	);
  }
  
  //
  //
  const refreshHandler = async (event) => {
	console.log("refreshHandler");
    event.preventDefault();
	
    setCreatedDialogOpenState(true);
    setTimeout(() => {
	  setCreatedDialogOpenState(false);
	  window.scrollTo({top: 0, left: 0, behavior: "smooth" });
	  }, 500);
	
	fetchDataCallback();
  }
  
  const taskJSX = (
    Object.keys(entityData).map((key, i) => (
	    <TextField
		  id={key}
		  label={key}
		  value={entityData[key] === null ? "-": (key === 'URL' ? " " : entityData[key])}
		  sx={{mb: 3}}
		  InputProps={{
		    readOnly: false,
		    startAdornment: (key === 'URL' ? (
			  (entityData[key] !== "N/A" && entityData[key] !== null) ? (
			    <InputAdornment position="start">
			      <Link to={entityData[key]}>Download...</Link>
			    </InputAdornment>
			    ) : entityData[key]
			  ) : ""),
			}}
	    />
	  )
	)
  );
  
  return (
    <Grid container justifyContent="center">
      <Stack>
        <Grid container justifyContent="center">
          <h1>Your Tasks</h1>
	    </Grid>
        <Grid container justifyContent="right">
          <IconButton aria-label="refresh" onClick={refreshHandler}>
            <RefreshIcon />
		  </IconButton>
	    </Grid>
	    <Card variant="outlined">
	      <Box m={2}>
	          <Stack sx={{width: '50ch'}} spacing={2} noValidate autoComplete="off">
			    {taskJSX}
    	      </Stack>
		  </Box>
	    </Card>
	  </Stack>
	  <AlertDialog
	    openState={createdDialogOpenState}
	    setOpenState={setCreatedDialogOpenState}
	    showButtons={false}
	    optionalDialog={null}
	    actionFunction={null}
	    title=""
	    text="Updating..."
	  />
	</Grid>
  );
}

export default TaskDetailPage;
