import React, { useState, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { setRequiredPassword, getSession } from "../auth/auth";
import { TokenContext } from '../auth/token'
import { ErrorListContext, logErrorString } from '../error/errorList'

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import GenericPageHeader from '../components/GenericPageHeader'
import AlertDialog from '../components/AlertDialog'


function RequirePasswordPage() {

  const { state } = useLocation();
  
  const [enteredPassword, setEnteredPassword] = useState('');
  const [enteredPassword2, setEnteredPassword2] = useState('');
  const [success, setSuccess] = useState(false);
  const [enteredPasswordError, setEnteredPasswordError] = useState(true);
  const [enteredPassword2Missing, setEnteredPassword2Missing] = useState(true);
  const [enteredPassword2Mismatched, setEnteredPassword2Mismatched] = useState(true);
  const [waitDialogOpenState, setWaitDialogOpenState] = useState(false);
  
  const [/*token*/, setToken] = useContext(TokenContext);
  const [errorList, setErrorList] = useContext(ErrorListContext);

  const navigate = useNavigate();

  if (state === null) {
	// wasn't called with the info we need - may have been accessed directly???
	return (
	  <h1>User information is missing...</h1>
	);
  }

  const submitHandler = async (event) => {
    event.preventDefault();
	logErrorString("", errorList, setErrorList)

    try {
		// pull in userName and currentPassword from state passed to us by login page
        setWaitDialogOpenState(true);
		await setRequiredPassword(state.userName, state.currentPassword, enteredPassword);
	    
		const session = await getSession();
	    setToken(session.idToken.jwtToken);
		
        setWaitDialogOpenState(false);
		setSuccess(true);
	} catch (err) {
        setWaitDialogOpenState(false);
		logErrorString(err.message, errorList, setErrorList)
    }
  };
  
  if (success) {
    navigate("/collections");
  }
  
  // set up helper text if needed
  //
  let enteredPasswordHelperText = "";
  if (enteredPasswordError === true) {
	enteredPasswordHelperText = "A password must be specified.";
  }
  let enteredPassword2HelperText = "";
  if (enteredPassword2Missing === true) {
	enteredPassword2HelperText = "Please re-enter the password.";
  } else if (enteredPassword2Mismatched === true) {
	enteredPassword2HelperText = "The passwords must match.";
  }
  
  const confirmDisabled = (
    enteredPasswordError || enteredPassword2Missing || enteredPassword2Mismatched
	);

  return (
    <Grid container justifyContent="center">
      <Stack spacing={2}>
        <Grid container justifyContent="center">
	      <GenericPageHeader />
		</Grid>
        <Grid container justifyContent="center">
	      <Card variant="outlined"  sx={{ width: 350 }}>
	        <Box m={2}>
              <form>
			    <Typography>
				  You must change your password before completing login.
			    </Typography>
				<p> </p>
		        <Stack spacing={1} >
				  <TextField
				    id="Password"
				    label="Password"
				    defaultValue={enteredPassword}
					type="password"
			        required={true}
			        error={enteredPasswordError}
			        helperText={enteredPasswordHelperText}
				    onChange = {(e) => {
					  setEnteredPassword(e.target.value);
			          setEnteredPasswordError(e.target.value.length === 0);
			          setEnteredPassword2Mismatched(e.target.value !== enteredPassword2);
				    }}
				  />
				  <TextField
				    id="Password2"
				    label="Re-enter Password"
				    defaultValue={enteredPassword2}
					type="password"
			        required={true}
			        error={enteredPassword2Missing || enteredPassword2Mismatched}
			        helperText={enteredPassword2HelperText}
				    onChange = {(e) => {
					  setEnteredPassword2(e.target.value);
			          setEnteredPassword2Missing(e.target.value.length === 0);
			          setEnteredPassword2Mismatched(e.target.value !== enteredPassword);
				    }}
				  />
				  <Grid container justifyContent="center">
				    <Button variant="contained" type='submit' disabled={confirmDisabled} onClick={submitHandler}>Confirm</Button>
		          </Grid>
  				</Stack>
              </form>
		    </Box>
	      </Card>
	    </Grid>
      </Stack>
	  <AlertDialog
	    openState={waitDialogOpenState}
	    setOpenState={setWaitDialogOpenState}
	    showButtons={false}
	    optionalDialog={null}
	    actionFunction={null}
	    title=""
	    text="Please wait..."
	  />
	</Grid>
  );
};

export default RequirePasswordPage;
