import React from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import attribute_3 from '../support_data/attribute_3.png'
import attribute_4 from '../support_data/attribute_4.png'


function SupportReferenceAttrCreatePage() {
  return (
	<Box sx={{ width: 550 }}>
	  <Typography>
	    Once you have selected the correct entity, click the Create button and the following page will be shown.
	  </Typography>
	  <br />
      <Grid container justifyContent="center">
        <img src={attribute_3} width="572" height="541" alt="" />
	  </Grid>
	  <br/>
	  <Typography>
		Fill in the appropriate information for the new attribute:
		<ul><li>Title
		</li><li>Description
		</li><li>Shown in summary – whether attribute is shown in the table of all of these entities – default is Yes. Selecting No can make the list of entities more compact and easier to read, so including only essential attributes can be helpful.
		</li><li>Mandatory – whether the attribute has to be completed or can be left blank – default is No.
		</li><li>Attribute type – pick from one of the options (more details on the next page of this reference).
		</li><li>Attribute configuration – additional information to set up the attribute for some of the attribute types  (more details on the next page of this reference).
		</li></ul>
		Once completed, click on Create and you will be put back to the list of attributes for this entity.
	  </Typography>
	  <br/>
      <Grid container justifyContent="center">
        <img src={attribute_4} width="473" height="300" alt="" />
	  </Grid>
	  <br/>
	  <Typography>
		NOTE – if you have a collection that you have already put information in about collectables and/or examples and you create a new attribute on Collectable Items for example, then that new attribute will by default be blank for the all the Collectable Items you have previously created. If you had made that new attribute mandatory, then the next time you edit each Collectable Item you will have to enter a value before you save your changes. So although it may be mandatory, the value will continue to be blank unless you go and set it specifically for each item.
	  </Typography>
	  <br/><br/>
	</Box>
  );
};

export default SupportReferenceAttrCreatePage;
