import { createContext } from 'react';

export const ErrorListContext = createContext();


//
// Errors will be stored in the following format:
//	'text'
//	'timestamp'
//	'comment'
//


function blankEntry() {
  return {
	  'text': "",
	  'timestamp': "",
	  'comment': "",
	};
}


export function getInitErrorList() {
  return [blankEntry()];
}


export function logErrorString (errorString, originalErrorList, saveErrorListFunc) {
	// initial new working copy of the original list
	let newList = [...originalErrorList];
	
	if (errorString === "") {
		if (newList[0]['text'] === "") {
			// do nothing
			return;
		}
		// else drop thru to just add errorString to top of list
	} else {
		if (newList[0]['text'] === "") {
			// first remove errorList[0] before dropping thru to add errorString to top of list
			newList.shift();
		}
		// else drop thru to just add errorString to top of list
	}
	
	// now add errorString to top of list
	// default to blank message
	let newEntry = blankEntry();
	if (errorString !== "") {
	  newEntry = {
		'text': errorString,
		'timestamp': new Date().toJSON(),
		'comment': "",
	  }
	}
    newList.unshift(newEntry);
	saveErrorListFunc(newList);
}
