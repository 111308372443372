import React, { useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import { s3Config } from "../config/config"

import no_image_icon from '../no-image-icon.png';

import FileUploader from '../components/FileUploader'


// props list:
//
// editMode
// attributeName
// attributeType - not used
// attributeValue
// attributeConfigInfo - not used
// attributeDescription
// attributeIsMandatory
// updatedValues
// setUpdatedValues
// updateAttributesInError
//

function ImageAttributeDisplay (props) {
  console.log("ImageAttributeDisplay");
  
  const [attributeValue, setAttributeValue] = useState(props.attributeValue);
  const [reloadedCount, setReloadedCount] = useState(0);
  const [valueInError, setValueInError] = useState(true);
  
  // make sure valueInError is initialised correctly
  //
  // NOTE - props.updateAttributesInError should be in the dependency list
  //        but if do that then propogation of valueInError doesn't work
  //        fine when goes out of error, does nothing when goes back into error
  //
  useEffect(() => {
	if ((props.attributeIsMandatory === true) && (props.editMode === true)) {
	  const isInError = props.attributeValue.length === 0
	  setValueInError(isInError);
	  props.updateAttributesInError(isInError, props.attributeName);
	}
  }, [props.attributeIsMandatory, props.editMode, props.attributeValue /*, props.updateAttributesInError*/]);
  
  // call props.updateAttributesInError to update list when valueInError changes
  //
  useEffect(() => {
	if ((props.attributeIsMandatory === true) && (props.editMode === true)) {
	  props.updateAttributesInError(valueInError, props.attributeName);
	}
  }, [valueInError, props.attributeIsMandatory, props.editMode, props.updateAttributesInError]);
  
	
  let imgURL = "";
  let altText = "";
  if (attributeValue === "") {
    imgURL = no_image_icon;
	altText="No image defined.";
  } else {
	imgURL = s3Config.imageBaseUrl;
	imgURL = imgURL.concat(attributeValue);
	// need to add changing number to force refresh of image
	// otherwise is getting cached by browser...
	imgURL = imgURL.concat("?", reloadedCount);
	altText=attributeValue;
  }
  console.log(imgURL);
  
  // Define common colour here
  //
  let textColour = "#737373"

  if (props.editMode === false) {
    return (
	  <Card variant="outlined">
	    <Stack>
		  <Typography color={textColour}>
		    <Box sx={{ textAlign: 'left', m: 1 }}> {props.attributeName} </Box>
		  </Typography>
          <Tooltip arrow title={props.attributeDescription}>
		    <img
	          src={imgURL}
		      width="150"
			  alt={altText}
	        />
          </Tooltip>
	    </Stack>
	  </Card>
	);
  }
  
  const imageChanged = (localName, remoteName) => {
	console.log("imageChanged");
	console.log(localName);
	console.log(remoteName);
	
	let count = reloadedCount;
	count = count + 1;
	console.log(count);
	setReloadedCount(count);
	
	let dupUpdatedValues = props.updatedValues;
	console.log(dupUpdatedValues[props.attributeName]);
	dupUpdatedValues[props.attributeName] = remoteName;
	props.setUpdatedValues(dupUpdatedValues);
	
	if ((props.attributeIsMandatory === true) && (props.editMode === true)) {
	  setValueInError(remoteName.length === 0);
	}
	setAttributeValue(remoteName);
  };
  
  const handleClear = event => {
	event.preventDefault();
	imageChanged("");
  };
  
  // set up helper text if needed
  //
  let valueError = false;
  let nameRequired = false;
  let nameHelperText = "";
  if (props.editMode === true) {
	nameRequired = props.attributeIsMandatory === true;
    if (nameRequired === true) {
	  valueError = valueInError
      if (valueError === true) {
	    nameHelperText = props.attributeName + " must be specified.";
	  }
	}
  }

  let cardBorderColour = 'light grey';
  let nameText = props.attributeName;
  if (valueError) {
	cardBorderColour = 'solid red';
	textColour = "#ff0000";
	nameText = nameText.concat(" *");
  }
  
  return (
    <Card variant="outlined" sx={{ border: cardBorderColour }} >
	  <Stack>
		<Typography color={textColour} >
		  <Box sx={{ textAlign: 'left', m: 1 }}> {nameText} </Box>
		</Typography>
        <Tooltip arrow title={props.attributeDescription}>
		  <Grid container direction="row" alignItems="center" >
		    <Grid>
		      <img
	            src={imgURL}
		        width="150"
				alt={altText}
	          />
			</Grid>
			<Grid>
			  <Stack spacing={2}>
			    <FileUploader
				  buttonText="Select file"
				  destination='image'
                  useSelectedFileName={false}
				  fileChanged={imageChanged}
				/>
			    <Button variant="contained" disabled={attributeValue===""} onClick={handleClear}>Remove image</Button>
			  </Stack>
			</Grid>
		  </Grid>
        </Tooltip>
		<Typography color={textColour} >
		  <Box sx={{ textAlign: 'left', m: 1 }}> {nameHelperText} </Box>
		</Typography>
	  </Stack>
    </Card>
  );
}

export default ImageAttributeDisplay;
