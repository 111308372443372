import React from 'react';
import { Link } from 'react-router-dom';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';

function Tile({title, content, width, destination, action, imageUrl, imageAlt}) {
  
  //
  //
  let imageUrlJSX = ""
  if (imageUrl !== null) {
	imageUrlJSX = (
      <CardMedia
        component="img"
        height="140"
        image={imageUrl}
        alt={imageAlt}
      />
	);
  }
  
  //
  //
  const justifyContent = "center";
  let contentJSX = (
    <>
	  {imageUrlJSX}
	  <CardContent>
        <Stack spacing={0}>
	      <Grid container justifyContent={justifyContent}>
            <Typography gutterBottom variant="h5" component="div">
		     {title}
            </Typography>
		  </Grid>
		  {content.split("\n").map((item) => (
	        <Grid container justifyContent={justifyContent}>
              <Typography display="block" variant="body2" color="text.secondary">
		        {item}
              </Typography>
		    </Grid>
		  ))}
		</Stack>
      </CardContent>
	</>
  );
  
  // set up what to do when user clicks on the tile
  //
  let cardActionAreaJSX = "";
  if (destination != null) {
	cardActionAreaJSX = (
	  <CardActionArea component={Link} to={destination}>
		{contentJSX}
	  </CardActionArea>
	);
  } else if (action != null) {
	cardActionAreaJSX = (
	  <CardActionArea onClick={action}>
		{contentJSX}
	  </CardActionArea>
	);
  } else {
	cardActionAreaJSX = contentJSX;
  }
  
  return (
    <Card sx={{ maxWidth: {width} }}>
	  {cardActionAreaJSX}
    </Card>
  );
}

export default Tile;
