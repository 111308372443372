import React, { useState, useEffect } from 'react';

import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';

import StyledCard from '../components/StyledCard';

import InformationPageControl from '../components/InformationPageControl';


function InformationBook({pages}) {
  //
  // activeContentIndex = 0 for contents page (default at start up)
  // activeContentIndex in [1, len(pages)] for the (activeContentIndex-1)th page to be displayed
  //
  const [activeContentIndex, setActiveContentIndex] = useState(0);
  
  useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: "smooth" });
  }, [activeContentIndex])
  
  let pageContentJSX = "";
  if (activeContentIndex === 0) {
	//
	// show contents page
	//
	pageContentJSX = (
	  <Stack spacing={1}>
	    {pages.map((page, idx) => (
          <Link
            component="button"
			variant="body1"
            onClick={() => {
			  setActiveContentIndex(idx+1);
            }}
          >
		    {page['title']}
          </Link>
	    ))}
	  </Stack>
	);
  } else {
	//
	// show the (activeContentIndex-1)th page
	//
	const Tag = pages[activeContentIndex-1]['content'];
	pageContentJSX = (
	  <Stack spacing={1}>
        <Grid container justifyContent="center">
	      <h2><u><b>
	        {pages[activeContentIndex-1]['title']}
	      </b></u></h2>
		</Grid>
	    <Tag />
	  </Stack>
	);
  }
  
  return (
    <Grid container justifyContent="center">
      <StyledCard>
	  <Stack spacing={2}>
        <Grid container justifyContent="center">
		  <InformationPageControl
		    pages={pages}
			activeContentIndex={activeContentIndex}
			setActiveContentIndex={setActiveContentIndex}
		  />
	    </Grid>
		<br />
        <Grid container justifyContent="center">
		  {pageContentJSX}
	    </Grid>
		<br />
        <Grid container justifyContent="center">
		  <InformationPageControl
		    pages={pages}
			activeContentIndex={activeContentIndex}
			setActiveContentIndex={setActiveContentIndex}
		  />
	    </Grid>
	  </Stack>
      </StyledCard>
	</Grid>
  );
}

export default InformationBook;
