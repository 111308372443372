import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';

import { tokenIsValid, TokenContext } from '../auth/token'

import Constants from '../Constants'

import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';

import { SubscriptionStatusContext, restrictPage } from '../context/subscriptionStatus'
import SubscriptionExpiredMessage from '../components/SubscriptionExpiredMessage'

import NotLoggedInMessage from '../components/NotLoggedInMessage'
import EntityDetailViewUpdate from '../components/EntityDetailViewUpdate'
import EntityTitle from '../components/EntityTitle'


function CollectableItemCreatePage(props) {

  const [token, /*setToken*/] = useContext(TokenContext);
  const [subscriptionStatus, /*setSubscriptionStatus*/] = useContext(SubscriptionStatusContext);

  const params = useParams();

  if (!tokenIsValid(token)) {
    return <NotLoggedInMessage />;
  }
  // nor if subscription expired and page is restricted
  //
  if (restrictPage(subscriptionStatus, "CollectableItemCreatePage")) {
    return <SubscriptionExpiredMessage />;
  }
  
  // user is logged in so proceed to display values
  //
  // CI => EntityDetailViewUpdate </p>
  //
  return (
	<Stack spacing={2}>
      <Grid container justifyContent="center">
	    <h1>Create Collectable Item</h1>
		<EntityTitle
		  title="Create Collectable Item"
		  entityType={Constants.CollectableItemEntity}
		  variant="h4"
		/>
	  </Grid>
      <Grid container justifyContent="center">
	    <EntityDetailViewUpdate
		  title="Collectable Item attributes:"
	      displayMode={Constants.DisplayModeCreate}
		  entityId={''}
		  collectionId={params.collectionId}
		  getDataActionName = "get_collectable_item"
		  saveDataActionName = "create_collectable_item"
		  deleteActionName = {null}
		  baseUrlPath = {"/collections/"+params.collectionId+"/ci/"}
	    />
	  </Grid>
	</Stack>
  );
}

export default CollectableItemCreatePage;
