import React, { useState, useContext } from 'react';

import callApi from '../api/api'
import { tokenIsValid, TokenContext } from '../auth/token'
import { ErrorListContext, logErrorString } from '../error/errorList'

import { SubscriptionStatusContext, restrictPage } from '../context/subscriptionStatus'
import SubscriptionExpiredMessage from '../components/SubscriptionExpiredMessage'

import NotLoggedInMessage from '../components/NotLoggedInMessage'


function PingPage() {
  
  const [count, setCount] = useState({"Count": "(not set)"});
  
  const [token, setToken] = useContext(TokenContext);
  const [errorList, setErrorList] = useContext(ErrorListContext);
  const [subscriptionStatus, /*setSubscriptionStatus*/] = useContext(SubscriptionStatusContext);

  // do not allow to proceed if not logged in
  //
  if (!tokenIsValid(token)) {
    return <NotLoggedInMessage />;
  }
  // nor if subscription expired and page is restricted
  //
  if (restrictPage(subscriptionStatus, "PingPage")) {
    return <SubscriptionExpiredMessage />;
  }

  const submitHandler = async (event) => {
    event.preventDefault();
	
	const data = {
	  "action": "ping",
	  "payload": { }
	};
	
	const retVal = await callApi(token, setToken, data);
	
	setCount(retVal.data);
	logErrorString(retVal.errorMessage, errorList, setErrorList)
  };

  return (
   <div>
	 <h1>The Collections Page</h1>
	 <p>Welcome to the 'Your Collections Online' portal.</p>
	 <p></p>
     <form onSubmit={submitHandler}>
	   <div className='new-expense__controls'>
	     <p className='new-expense__control'>
		   <label>Count</label>
		   <label>{count.Count}</label>
    	 </p>
         <div className='new-expense__actions'>
           <button type='submit'>Ping again!</button>
         </div>
	   </div>
     </form>
   </div>
  );
}

export default PingPage;
