import React, { useState, useEffect } from 'react';

import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';


// props list:
//
// editMode
// attributeName
// attributeType - not used
// attributeValue
// attributeConfigInfo
// attributeDescription
// attributeIsMandatory
// updatedValues
// setUpdatedValues
// updateAttributesInError
//

function DropdownAttributeDisplay (props) {
  const [currValue, setCurrValue] = useState(props.attributeValue);
  const [valueInError, setValueInError] = useState(true);
  
  // make sure valueInError is initialised correctly
  //
  // NOTE - props.updateAttributesInError should be in the dependency list
  //        but if do that then propogation of valueInError doesn't work
  //        fine when goes out of error, does nothing when goes back into error
  //
  useEffect(() => {
	if ((props.attributeIsMandatory === true) && (props.editMode === true)) {
	  const isInError = props.attributeValue.length === 0
	  setValueInError(isInError);
	  props.updateAttributesInError(isInError, props.attributeName);
	}
  }, [props.attributeIsMandatory, props.editMode, props.attributeValue /*, props.updateAttributesInError*/]);
  
  // call props.updateAttributesInError to update list when valueInError changes
  //
  useEffect(() => {
	if ((props.attributeIsMandatory === true) && (props.editMode === true)) {
	  props.updateAttributesInError(valueInError, props.attributeName);
	}
  }, [valueInError, props.attributeIsMandatory, props.editMode, props.updateAttributesInError]);
  
  //
  //
  const handleChange = (e) => {
	console.log("DropdownAttributeDisplay - handleChange");
	console.log(e.target.value);
	console.log(props.editMode);
	
	let dupUpdatedValues = props.updatedValues;
	console.log(dupUpdatedValues[props.attributeName]);
	dupUpdatedValues[props.attributeName] = e.target.value;
	props.setUpdatedValues(dupUpdatedValues);
	
	setCurrValue(e.target.value);
	
	if ((props.attributeIsMandatory === true) && (props.editMode === true)) {
	  setValueInError(e.target.value.length === 0);
	}
  };

  console.log("DropdownAttributeDisplay - rendering");
  console.log(props.attributeValue);
  console.log(currValue);
  
  if (props.editMode === false) {
    return (
      <Tooltip arrow title={props.attributeDescription}>
	  <TextField
	    id={props.attributeName}
        label={props.attributeName}
        defaultValue={props.attributeValue}
        InputProps={{
          readOnly: true,
        }}
	    sx={{mb: 3}}
	    multiline
	    maxRows={4}
      />
      </Tooltip>
	)
  }

  let options = [];
  try {
	options = JSON.parse(props.attributeConfigInfo);
	if (options === null) {
	  options = [];
	};
  } catch {
  };
  console.log("DropdownAttributeDisplay: " + options);
	
  let dropdownOptionsJSX = options.map((option) => (
		   				     <MenuItem value={option}>{option}</MenuItem>
		                   ))
  
  // set up helper text if needed
  //
  let valueError = false;
  let nameRequired = false;
  let nameHelperText = "";
  if (props.editMode === true) {
	nameRequired = props.attributeIsMandatory === true;
    if (nameRequired === true) {
	  valueError = valueInError
      if (valueError === true) {
	    nameHelperText = props.attributeName + " must be specified.";
	  }
	}
  }
  
  // note that if here then editMode is True...
  //
  return (
    <FormControl sx={{ m: 1, minWidth: 120 }} required={nameRequired} error={valueError} >
      <InputLabel id="dropdown-label">{props.attributeName}</InputLabel>
      <Tooltip arrow title={props.attributeDescription}>
      <Select
        labelId="dropdown-label"
        id={props.attributeName}
        value={currValue}
        label={props.attributeName}
        onChange={handleChange}
      >
	    {dropdownOptionsJSX}
      </Select>
      </Tooltip>
	  <FormHelperText>{nameHelperText}</FormHelperText>
	</FormControl>
  );
}

export default DropdownAttributeDisplay;
