import { createContext } from 'react';

export const CurrentCollectionContext = createContext();


const current_collection_key = "CurrentCollectionInfo"

export function initialCurrentCollectionInfo () {
  console.log("initialCurrentCollectionInfo");
  let savedInfoStr = localStorage.getItem(current_collection_key);
  console.log(savedInfoStr);
  
  let savedInfoObj = {
    'id' : 0,
	'Type': 'Planned',
	'HasSets': true
  };
  if (savedInfoStr !== null) {
    savedInfoObj = JSON.parse(savedInfoStr);
  }
  console.log(savedInfoObj);
  return savedInfoObj;
}


export function changeCurrentCollectionInfo (collection_id, collection_type, collection_has_sets, setCurrentCollectionFunc) {
  console.log("changeCurrentCollectionInfo");
  let has_sets = false;
  if ((collection_has_sets === 1) || (collection_has_sets === true)) {
	has_sets = true;
  }
  let newInfo = {
    'id': collection_id,
	'Type': collection_type,
	'HasSets': has_sets
  };	  
  console.log(newInfo);
  localStorage.setItem(current_collection_key, JSON.stringify(newInfo));
  setCurrentCollectionFunc(newInfo);
}

//
// helper function
//
function validateCollection (currentCollectionInfo, collectionId) {
  console.log("validateCollection");
  console.log(currentCollectionInfo);
  console.log(collectionId);
  let curr = currentCollectionInfo['id'].toString();
  let test = collectionId.toString();
  if (curr !== test) {
	let msg = "Mismatched collection ID: " + curr + " - " + test;
	throw new Error(msg);
  }
}

export function checkCollectableSetsValidForCollection (currentCollectionInfo, collectionId) {
  validateCollection(currentCollectionInfo, collectionId);
  
  if (((currentCollectionInfo['Type'] === "Catalog") || (currentCollectionInfo['Type'] === "Planned"))
	   && (currentCollectionInfo['HasSets'] === true)) {
    return true;
  }
  return false;
}


export function checkCollectableItemsValidForCollection (currentCollectionInfo, collectionId) {
  validateCollection(currentCollectionInfo, collectionId);
  
  if (((currentCollectionInfo['Type'] === "Catalog") || (currentCollectionInfo['Type'] === "Planned"))) {
    return true;
  }
  return false;
}


export function checkExampleSetsValidForCollection (currentCollectionInfo, collectionId) {
  validateCollection(currentCollectionInfo, collectionId);
  
  if (((currentCollectionInfo['Type'] === "Ad-Hoc") || (currentCollectionInfo['Type'] === "Planned"))
	   && (currentCollectionInfo['HasSets'] === true)) {
    return true;
  }
  return false;
}


export function checkExampleItemsValidForCollection (currentCollectionInfo, collectionId) {
  validateCollection(currentCollectionInfo, collectionId);
  
  if (((currentCollectionInfo['Type'] === "Ad-Hoc") || (currentCollectionInfo['Type'] === "Planned"))) {
    return true;
  }
  return false;
}
