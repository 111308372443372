
import { muiConfig } from "./config_common"
export { muiConfig }

// --------------------------------------------------
//	TST2 configs
// --------------------------------------------------
export const environmentString = "PRD1";
export const cognitoConfig = {
  UserPoolId: "ap-southeast-2_rtfhEJ9Na",
  ClientId: "4365t3o5f7aftq4lqj7n6m019t",
}
export const apiConfig = {
  apiGatewayUrl: "https://ydotveutdc.execute-api.ap-southeast-2.amazonaws.com/prd1/yourcollections",
}
export const s3Config = {
  publicBaseUrl: "https://yourcollections-download-prd1.s3.ap-southeast-2.amazonaws.com/",
  imageBaseUrl: "https://yourcollections-images-prd1.s3.ap-southeast-2.amazonaws.com/",
}
export const analyticsConfig = {
	trackingId: "G-VBKY5XWJZV",
}
export const stripeConfig = {
	stripePublishableKey: "pk_live_51OXQHqAdMSKQTpruYeUQJ5q9cironTPsLtBURqf1n90acfNMNGgBtDL5ECs7GGQHKiVIQEfFXwVSkbhg9vj6OyAX00mc7UCcz3",
}
