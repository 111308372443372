import React from 'react';

import Typography from '@mui/material/Typography';


function ImageAttributeConfiguration (props) {
	// for Image we ignore the props.editMode passed in
	return (
	  <Typography>
	    No configuration required for Image attributes.
	  </Typography>
	);
}

export default ImageAttributeConfiguration;
