import React, { useContext } from 'react';

import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';

import { tokenIsValid, TokenContext } from '../auth/token'

import { SubscriptionStatusContext, restrictPage } from '../context/subscriptionStatus'
import SubscriptionExpiredMessage from '../components/SubscriptionExpiredMessage'

import NotLoggedInMessage from '../components/NotLoggedInMessage'

import InformationBook from '../components/InformationBook'

import SupportReferenceConceptsPage from '../components/SupportReferenceConceptsPage'

import SupportReferenceCreatePage from '../components/SupportReferenceCreatePage'
import SupportReferenceCreateScratchPage from '../components/SupportReferenceCreateScratchPage'
import SupportReferenceCreateTemplatePage from '../components/SupportReferenceCreateTemplatePage'
import SupportReferenceCreateCopyPage from '../components/SupportReferenceCreateCopyPage'
import SupportReferenceCreateImportPage from '../components/SupportReferenceCreateImportPage'

import SupportReferenceConfigPage from '../components/SupportReferenceConfigPage'
import SupportReferenceAttrViewPage from '../components/SupportReferenceAttrViewPage'
import SupportReferenceAttrCreatePage from '../components/SupportReferenceAttrCreatePage'
import SupportReferenceAttrChangePage from '../components/SupportReferenceAttrChangePage'
import SupportReferenceAttrTypesPage from '../components/SupportReferenceAttrTypesPage'

import SupportReferenceManagePage from '../components/SupportReferenceManagePage'
import SupportReferenceManageCSPage from '../components/SupportReferenceManageCSPage'
import SupportReferenceManageCIPage from '../components/SupportReferenceManageCIPage'
import SupportReferenceManageESPage from '../components/SupportReferenceManageESPage'
import SupportReferenceManageEIPage from '../components/SupportReferenceManageEIPage'

//	Dont put these in for now...
//
//import SupportReferenceAdHocNoSetsPage from '../components/SupportReferenceAdHocNoSetsPage'
//import SupportReferencePlannedNoSetsPage from '../components/SupportReferencePlannedNoSetsPage'
//import SupportReferenceCatalogNoSetsPage from '../components/SupportReferenceCatalogNoSetsPage'
//import SupportReferencePlannedSetsPage from '../components/SupportReferencePlannedSetsPage'

import SupportReferenceAppendixContextIconsPage from '../components/SupportReferenceAppendixContextIconsPage'
import SupportReferenceAppendixImportExportPage from '../components/SupportReferenceAppendixImportExportPage'


function SupportReferencePage() {
  
  const [token, /*setToken*/] = useContext(TokenContext);
  const [subscriptionStatus, /*setSubscriptionStatus*/] = useContext(SubscriptionStatusContext);
  
  // do not allow to proceed if not logged in
  //
  if (!tokenIsValid(token)) {
    return <NotLoggedInMessage />;
  }
  // nor if subscription expired and page is restricted
  //
  if (restrictPage(subscriptionStatus, "SupportReferencePage")) {
    return <SubscriptionExpiredMessage />;
  }
  
  const pages = [
    {"title":"Concepts",                           "content": SupportReferenceConceptsPage},
    {"title":"Create your collection",             "content": SupportReferenceCreatePage},
    {"title":"Create collection from scratch",     "content": SupportReferenceCreateScratchPage},
    {"title":"Create collection using a template", "content": SupportReferenceCreateTemplatePage},
    {"title":"Create collection as a copy",        "content": SupportReferenceCreateCopyPage},
    {"title":"Create collection by importing",     "content": SupportReferenceCreateImportPage},
	
    {"title":"Configure your new collection",    "content": SupportReferenceConfigPage},
    {"title":"Viewing attribute definitions",    "content": SupportReferenceAttrViewPage},
    {"title":"Creating an attribute",            "content": SupportReferenceAttrCreatePage},
    {"title":"Changing / deleting an attribute", "content": SupportReferenceAttrChangePage},
    {"title":"Attribute types",                  "content": SupportReferenceAttrTypesPage},

    {"title":"Manage your collection",   "content": SupportReferenceManagePage},
    {"title":"Manage Collectable Sets",  "content": SupportReferenceManageCSPage},
    {"title":"Manage Collectable Items", "content": SupportReferenceManageCIPage},
    {"title":"Manage Example Sets",      "content": SupportReferenceManageESPage},
    {"title":"Manage Example Items",     "content": SupportReferenceManageEIPage},
	
	/*
	//	Dont put these in for now...
	//
    {"title":"Walk-thru: ad-hoc, no sets",   "content": SupportReferenceAdHocNoSetsPage},
    {"title":"Walk-thru: planned, no sets",  "content": SupportReferencePlannedNoSetsPage},
    {"title":"Walk-thru: catalog, no sets",  "content": SupportReferenceCatalogNoSetsPage},
    {"title":"Walk-thru: planned with sets", "content": SupportReferencePlannedSetsPage},
	*/
	
    {"title":"Appendix: context icons",             "content": SupportReferenceAppendixContextIconsPage},
    {"title":"Appendix: inport/export user guide",  "content": SupportReferenceAppendixImportExportPage},
  ];
  
  return (
    <Grid container justifyContent="center">
      <Stack spacing={2}>
        <Grid container justifyContent="center">
	      <h1>Your Support - Reference</h1>
	    </Grid>
		<Grid container justifyContent="center">
		  <InformationBook
		    pages={pages}
		  />
		</Grid>
		<br/>
	  </Stack>
	</Grid>
  );
}

export default SupportReferencePage;
