import React, { useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import Constants from '../Constants'

import { tokenIsValid, TokenContext } from '../auth/token'

import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

import { SubscriptionStatusContext, restrictPage } from '../context/subscriptionStatus'
import SubscriptionExpiredMessage from '../components/SubscriptionExpiredMessage'

import NotLoggedInMessage from '../components/NotLoggedInMessage'
import EntityList from '../components/EntityList'
import EntityTitle from '../components/EntityTitle'


function CollectableSetsPage() {

  const [token, /*setToken*/] = useContext(TokenContext);
  const [subscriptionStatus, /*setSubscriptionStatus*/] = useContext(SubscriptionStatusContext);

  const navigate = useNavigate();
  
  const params = useParams();

  if (!tokenIsValid(token)) {
    return <NotLoggedInMessage />;
  }
  // nor if subscription expired and page is restricted
  //
  if (restrictPage(subscriptionStatus, "CollectableSetsPage")) {
    return <SubscriptionExpiredMessage />;
  }
  
  //
  //
  const createHandler = async (event) => {
	console.log("createHandler");
    event.preventDefault();
	
	navigate("/collections/" + params.collectionId + "/cs/create");
  };
  
  const baseUrlPath = "/collections/" + params.collectionId + "/cs/"
  return (
	<>
        <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
		  <EntityTitle
			title={"Collectable Sets"}
			entityType={Constants.CollectableSetEntity}
			variant="h4"
		  />
		  <Button variant="contained" onClick={createHandler}>Create</Button>
        </Stack>
		<EntityList
		  entityType="CSforC"
		  title=""
		  getDataAction="get_collectable_set_list"
		  entityId={params.collectionId}
          collectionId={params.collectionId}
		  baseUrlPath={baseUrlPath}
		  height={700}
		  enableCheckboxSelection={false}
		/>
	</>
  );
}

export default CollectableSetsPage;




