import React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';


function SupportReferenceManageCIPage() {
  return (
	<Box sx={{ width: 550 }}>
	  <Typography>
		When you click on an entry in the list of Collectable Items the page to view the individual Collectable Item is displayed. This page consists of the following elements
		<ul><li>A block showing the attributes for the Collectable Item, with buttons to be able to edit or delete it
		</li><li>A table showing the list of Collectable Sets that this Collectable Item is a part of
		</li><li>A second table showing the list of Example Items that are examples in your collection of this Collectable Item, with a button to allow you to create a new one
		</li></ul>
		NOTE – to create a new Collectable Item go back to the list of all Collectable Items for this Collection and click on the create button just above the table.
	  </Typography>
	  <br/><br/>
	</Box>
  );
};

export default SupportReferenceManageCIPage;
