import React, { useState, useContext, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { confirmSignUp, resendCode } from "../auth/auth";
import { ErrorListContext, logErrorString } from '../error/errorList'

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import GenericPageHeader from '../components/GenericPageHeader'
import AlertDialog from '../components/AlertDialog'


function VerifyPage() {

  const { state } = useLocation();

  const [enteredUsername, setEnteredUsername] = useState("");
  const [enteredCode, setEnteredCode] = useState("");
  const [success, setSuccess] = useState(false);
  const [enteredEmailError, setEnteredEmailError] = useState(true);
  const [enteredCodeError, setEnteredCodeError] = useState(true);
  const [waitDialogOpenState, setWaitDialogOpenState] = useState(false);

  const [errorList, setErrorList] = useContext(ErrorListContext);

  const navigate = useNavigate();

  useEffect(() => {
    if (state !== null) {
	  setEnteredUsername(state.email);
    }
  }, []); // NOTE - don't add "state" to this dependency - ignore the LINT warning for this.
  
  const resendHandler = async (event) => {
    event.preventDefault();
	logErrorString("", errorList, setErrorList)

    try {
		// note user name is email.
        setWaitDialogOpenState(true);
		await resendCode(enteredUsername);
        setWaitDialogOpenState(false);
		//setSuccess(true);
	} catch (err) {
        setWaitDialogOpenState(false);
		logErrorString(err.message, errorList, setErrorList)
    }
  };

  const submitHandler = async (event) => {
    event.preventDefault();
	logErrorString("", errorList, setErrorList)

    try {
		// note user name is email.
        setWaitDialogOpenState(true);
		await confirmSignUp(enteredUsername, enteredCode);
        setWaitDialogOpenState(false);
		setSuccess(true);
	} catch (err) {
        setWaitDialogOpenState(false);
		logErrorString(err.message, errorList, setErrorList)
    }

    setEnteredCode('');
  };
  
  if (success) {
	let msg = "Your account was successfully verified - please login using your email address and password."
	navigate("/login/", { state: { 'email': enteredUsername, 'message': msg } } );
  }
  
  // set up helper text if needed
  //
  let enteredEmailHelperText = "";
  if (enteredEmailError === true) {
	enteredEmailHelperText = "An email must be specified.";
  }
  let enteredCodeHelperText = "";
  if (enteredCodeError === true) {
	enteredCodeHelperText = "A code must be specified.";
  }
  
  const resendDisabled = (enteredEmailError);
  
  const confirmDisabled = (enteredEmailError || enteredCodeError);

  return (
    <Grid container justifyContent="center">
      <Stack spacing={2}>
        <Grid container justifyContent="center">
	      <GenericPageHeader />
		</Grid>
        <Grid container justifyContent="center">
	      <Card variant="outlined"  sx={{ width: 350 }}>
	        <Box m={2}>
              <form>
			    <Typography>
				  To complete the creation of your account, please enter your email address and the verification code that we emailed you.
			    </Typography>
				<p> </p>
		        <Stack spacing={1} >
				  <TextField
				    id="Email"
				    label="Email (user name)"
				    defaultValue={enteredUsername}
			        required={true}
			        error={enteredEmailError}
			        helperText={enteredEmailHelperText}
				    onChange = {(e) => {
					  setEnteredUsername(e.target.value);
			          setEnteredEmailError(e.target.value.length === 0);
				    }}
				  />
				  <TextField
				    id="Code"
				    label="Code"
				    defaultValue={enteredCode}
					type="password"
			        required={true}
			        error={enteredCodeError}
			        helperText={enteredCodeHelperText}
				    onChange = {(e) => {
					  setEnteredCode(e.target.value);
			          setEnteredCodeError(e.target.value.length === 0);
				    }}
				  />
				  <Grid container justifyContent="center">
                    <Stack direction='row' spacing={1} >
				      <Button variant="contained" type='submit' disabled={resendDisabled} onClick={resendHandler}>Resend</Button>
				      <Button variant="contained" type='submit' disabled={confirmDisabled} onClick={submitHandler}>Confirm</Button>
					</Stack>
		          </Grid>
  				</Stack>
				<p> </p>
			    <Typography>
				  Not received an email? Try checking spam/junk/quarantine or click on Resend.
			    </Typography>
              </form>
		    </Box>
	      </Card>
	    </Grid>
      </Stack>
	  <AlertDialog
	    openState={waitDialogOpenState}
	    setOpenState={setWaitDialogOpenState}
	    showButtons={false}
	    optionalDialog={null}
	    actionFunction={null}
	    title=""
	    text="Please wait..."
	  />
	</Grid>
  );
};

export default VerifyPage;
