import React from 'react';

import TileGrid from '../components/TileGrid'


// How much
// Provide pricing information.

function HomeInfoPricing () {
  const content = [
    [
	  ["Subscriptions", "Access is by ongoing subscription until you choose cancel. At Your Collections Online we keep things simple - all our subscriptions give you access to everything.\n-\nOnce registered you have 7 days free access without any obligation - no credit card needed!\n-\nCancel your subscription anytime with no further costs - continue to use what you've already paid for.", 425, ],
	],
	[
	  ["Payments", "You have the option to pay the subscription either monthly, or annually at a significant discount, choosing the currency you want to pay in from a range of popular options.", 425, ],
	],
	[
	  ["Monthly Cost", "AUD 5.00\nEUR 3.25\nGBP 2.75\nUSD 3.50", 200, ],
	  ["Annual Cost", "AUD 50.00\nEUR 32.50\nGBP 27.50\nUSD 35.00\nThat's a 16.7% discount!", 200, ],
	],
  ];
  return (
	<TileGrid content={content} />
  );
}

export default HomeInfoPricing;
