import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import callApi from '../api/api';
import { tokenIsValid, TokenContext } from '../auth/token'
import { ErrorListContext, logErrorString } from '../error/errorList'
import { ReloadDataContext, triggerDataReload } from '../context/reloadData'

import { SubscriptionStatusContext, restrictPage } from '../context/subscriptionStatus'
import SubscriptionExpiredMessage from '../components/SubscriptionExpiredMessage'

import NotLoggedInMessage from '../components/NotLoggedInMessage'


function SupportItemCreatePage() {
  const [enteredType, setEnteredType] = useState("");
  const [enteredTitle, setEnteredTitle] = useState("");
  const [enteredDescription, setEnteredDescription] = useState("");
  const [enteredTypeError, setEnteredTypeError] = useState(true);
  const [enteredTitleError, setEnteredTitleError] = useState(true);
  const [enteredDescriptionError, setEnteredDescriptionError] = useState(true);
  
  const [token, setToken] = useContext(TokenContext);
  const [errorList, setErrorList] = useContext(ErrorListContext);
  const [reloadData, setReloadData] = useContext(ReloadDataContext);
  const [subscriptionStatus, /*setSubscriptionStatus*/] = useContext(SubscriptionStatusContext);
  
  const navigate = useNavigate();

  // do not allow to proceed if not logged in
  //
  if (!tokenIsValid(token)) {
    return <NotLoggedInMessage />;
  }
  // nor if subscription expired and page is restricted
  //
  if (restrictPage(subscriptionStatus, "SupportItemCreatePage")) {
    return <SubscriptionExpiredMessage />;
  }
  
  //
  //
  const createHandler = async (event) => {
	console.log("cancelHandler");
    event.preventDefault();
	
	console.log(enteredType);
	console.log(enteredTitle);
	console.log(enteredDescription);
	
	// send request to create the collection to the backend
	let data = {
	  "action": "create_support_item",
	  "payload": {
	    "title" : enteredTitle,
        "description": enteredDescription,
        "issue_type": enteredType,
	  }
	};
	
	const retVal = await callApi(token, setToken, data);
	console.log(retVal);
	logErrorString(retVal.errorMessage, errorList, setErrorList)
	
	let itemId = retVal.data['issue_id']
	console.log(itemId);
	
	navigate("/support/support_items/" + itemId);

	// trigger reload of data from the database
	// do this after call to 'navigate'
	triggerDataReload(reloadData, setReloadData);
  }
  
  //
  //
  const cancelHandler = (event) => {
	console.log("cancelHandler");
    event.preventDefault();
	
	navigate("/support/support_items");
  }
  
  //
  //
  const options = [
	"Problem",
	"Question",
	"Suggestion",
	];
  
  // set up helper text if needed
  //
  let enteredTypeHelperText = "";
  if (enteredTypeError === true) {
	enteredTypeHelperText = "A message type must be seected.";
  }
  let enteredTitleHelperText = "";
  if (enteredTitleError === true) {
	enteredTitleHelperText = "An title must be specified.";
  }
  let enteredDescriptionHelperText = "";
  if (enteredDescriptionError === true) {
	enteredDescriptionHelperText = "An description must be specified.";
  }
  
  const createDisabled = (enteredTypeError || enteredTitleError || enteredDescriptionError);
  
  return (
	<Stack spacing={2}>
      <Grid container justifyContent="center">
	    <h1>Your Support - Contact Us</h1>
	  </Grid>
      <Grid container justifyContent="center">
	    <Card variant="outlined">
	      <Box m={2}>
	          <Stack sx={{width: '50ch'}} spacing={2} noValidate autoComplete="off">
	            <Typography style={{ fontWeight: 600 }}>
		          Message details
			    </Typography>
				<InputLabel id="dropdown-label">Message type</InputLabel>
				<Select
				  labelId="dropdown-label"
				  id="Type"
				  value={enteredType}
				  label="Type"
			      required={true}
			      error={enteredTypeError}
			      helperText={enteredTypeHelperText}
				  onChange = {(e) => {
					setEnteredType(e.target.value);
			        setEnteredTypeError(e.target.value.length === 0);
				    }}
				  >
				  {options.map((option) => (
		            <MenuItem value={option}>{option}</MenuItem>
	              ))}
				</Select>
				<TextField
				  id="Title"
				  label="Title"
				  defaultValue={enteredTitle}
			      required={true}
			      error={enteredTitleError}
			      helperText={enteredTitleHelperText}
				  sx={{mb: 3}}
				  onChange = {(e) => {
					setEnteredTitle(e.target.value);
			        setEnteredTitleError(e.target.value.length === 0);
				  }}
				/>
				<TextField
				  id="Description"
				  label="Description"
				  defaultValue={enteredDescription}
			      required={true}
			      error={enteredDescriptionError}
			      helperText={enteredDescriptionHelperText}
				  sx={{mb: 3}}
				  multiline
				  maxRows={4}
				  onChange = {(e) => {
					setEnteredDescription(e.target.value);
			        setEnteredDescriptionError(e.target.value.length === 0);
				  }}
				/>
				<Stack spacing={2} direction="row">
			      <Button variant="contained" type='submit' onClick={cancelHandler}>Cancel</Button>
			      <Button variant="contained" type='submit' disabled={createDisabled} onClick={createHandler}>Create</Button>
				</Stack>
		      </Stack>
          </Box>
	    </Card>
	  </Grid>
	</Stack>
  );
}

export default SupportItemCreatePage;
