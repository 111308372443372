import React, { useContext } from 'react';

import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';

import { tokenIsValid, TokenContext } from '../auth/token'

import { SubscriptionStatusContext, restrictPage } from '../context/subscriptionStatus'
import SubscriptionExpiredMessage from '../components/SubscriptionExpiredMessage'

import NotLoggedInMessage from '../components/NotLoggedInMessage'

import { s3Config } from "../config/config"


function SupportTermsPage() {
  
  const [token, /*setToken*/] = useContext(TokenContext);
  const [subscriptionStatus, /*setSubscriptionStatus*/] = useContext(SubscriptionStatusContext);
  
  // do not allow to proceed if not logged in
  //
  if (!tokenIsValid(token)) {
    return <NotLoggedInMessage />;
  }
  // nor if subscription expired and page is restricted
  //
  if (restrictPage(subscriptionStatus, "SupportReferencePage")) {
    return <SubscriptionExpiredMessage />;
  }
  
  let termsUrl = s3Config.publicBaseUrl;
  termsUrl = termsUrl.concat("yourcollectionsonline_terms.pdf");
  
  return (
    <Grid container justifyContent="center">
      <Stack spacing={2}>
        <Grid container justifyContent="center">
	        <h1>Your Support - T's & C's</h1>
	    </Grid>
        <Grid container justifyContent="center">
          <iframe
		    src={termsUrl}
			width="200%"
			height="500px"
			title="Terms and Conditions"
		  />
	    </Grid>
	  </Stack>
	</Grid>
  );
}

export default SupportTermsPage;
