import React, { useState, useContext } from 'react';
import { useNavigate, Link } from 'react-router-dom';

import { signUp } from "../auth/auth";
import { ErrorListContext, logErrorString } from '../error/errorList'

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import GenericPageHeader from '../components/GenericPageHeader'
import AlertDialog from '../components/AlertDialog'

import { s3Config } from "../config/config"


function RegisterPage() {
  const [enteredName, setEnteredName] = useState('');
  const [enteredEmail, setEnteredEmail] = useState('');
  const [enteredPassword, setEnteredPassword] = useState('');
  const [enteredPassword2, setEnteredPassword2] = useState('');
  const [conditionsConfirmed, setConditionsConfirmed] = useState(false);
  const [waitDialogOpenState, setWaitDialogOpenState] = useState(false);
  
  const [enteredNameError, setEnteredNameError] = useState(true);
  const [enteredEmailError, setEnteredEmailError] = useState(true);
  const [enteredPasswordError, setEnteredPasswordError] = useState(true);
  const [enteredPassword2Missing, setEnteredPassword2Missing] = useState(true);
  const [enteredPassword2Mismatched, setEnteredPassword2Mismatched] = useState(true);
  const [conditionsConfirmedError, setConditionsConfirmedError] = useState(true);
  
  const [success, setSuccess] = useState(false);

  const [errorList, setErrorList] = useContext(ErrorListContext);

  const navigate = useNavigate();

  const submitHandler = async (event) => {
	console.log("submitHandler")
    event.preventDefault();
	
	logErrorString("", errorList, setErrorList)
	
    try {
		// note user name is email.
        setWaitDialogOpenState(true);
		await signUp(enteredEmail, enteredName, enteredPassword);
        setWaitDialogOpenState(false);
		setSuccess(true);
	} catch (err) {
        setWaitDialogOpenState(false);
		logErrorString(err.message, errorList, setErrorList)
    }
  };
  
  if (success) {
	navigate("/verify/", { state: { 'email': enteredEmail } } );
  }
  
  let termsUrl = s3Config.publicBaseUrl;
  termsUrl = termsUrl.concat("yourcollectionsonline_terms.pdf");
  
  let privacyUrl = s3Config.publicBaseUrl;
  privacyUrl = privacyUrl.concat("yourcollectionsonline_privacy.pdf");
  
  // set up helper text if needed
  //
  let enteredNameHelperText = "";
  if (enteredNameError === true) {
	enteredNameHelperText = "A unique name must be specified.";
  }
  let enteredEmailHelperText = "";
  if (enteredEmailError === true) {
	enteredEmailHelperText = "An email must be specified.";
  }
  let enteredPasswordHelperText = "";
  if (enteredPasswordError === true) {
	enteredPasswordHelperText = "A password must be specified.";
  }
  let enteredPassword2HelperText = "";
  if (enteredPassword2Missing === true) {
	enteredPassword2HelperText = "Please re-enter the password.";
  } else if (enteredPassword2Mismatched === true) {
	enteredPassword2HelperText = "The passwords must match.";
  }
  //let conditionsConfirmedHelperText = "";
  //if (conditionsConfirmedError === true) {
  //  conditionsConfirmedHelperText = "Please acknowlegde the terms and conditions to proceed.";
  //}
  
  const registerDisabled = (
    enteredNameError || enteredEmailError || enteredPasswordError ||
	enteredPassword2Missing || enteredPassword2Mismatched || conditionsConfirmedError
	);
  
  return (
    <Grid container justifyContent="center">
      <Stack spacing={2}>
        <Grid container justifyContent="center">
	      <GenericPageHeader />
		</Grid>
        <Grid container justifyContent="center">
	      <Card variant="outlined"  sx={{ width: 350 }}>
	        <Box m={2}>
              <form>
			    <Typography>
				  To register and create your account, please enter the following information - no credit card needed.
			    </Typography>
				<p> </p>
		        <Stack spacing={1} >
				  <TextField
				    id="Name"
				    label="Full Name"
				    defaultValue={enteredName}
			        required={true}
			        error={enteredNameError}
			        helperText={enteredNameHelperText}
				    onChange = {(e) => {
					  setEnteredName(e.target.value);
			          setEnteredNameError(e.target.value.length === 0);
				    }}
				  />
				  <TextField
				    id="Email"
				    label="Email (user name)"
				    defaultValue={enteredEmail}
			        required={true}
			        error={enteredEmailError}
			        helperText={enteredEmailHelperText}
				    onChange = {(e) => {
					  setEnteredEmail(e.target.value);
			          setEnteredEmailError(e.target.value.length === 0);
				    }}
				  />
				  <Stack direction="row" spacing={2}>
				    <TextField
				      id="Password"
				      label="Password"
				      defaultValue={enteredPassword}
					  type="password"
			          required={true}
			          error={enteredPasswordError}
			          helperText={enteredPasswordHelperText}
				      onChange = {(e) => {
					    setEnteredPassword(e.target.value);
			            setEnteredPasswordError(e.target.value.length === 0);
			            setEnteredPassword2Mismatched(e.target.value !== enteredPassword2);
				      }}
				    />
				    <TextField
				      id="Re-enter Password"
				      label="Password"
				      defaultValue={enteredPassword2}
					  type="password"
			          required={true}
			          error={enteredPassword2Missing || enteredPassword2Mismatched}
			          helperText={enteredPassword2HelperText}
				      onChange = {(e) => {
					    setEnteredPassword2(e.target.value);
			            setEnteredPassword2Missing(e.target.value.length === 0);
			            setEnteredPassword2Mismatched(e.target.value !== enteredPassword);
				      }}
				    />
				  </Stack>
				  <Stack direction="row" spacing={0} alignItems="centre">
					<Checkbox
					  checked={conditionsConfirmed}
			          required={true}
			          //error={conditionsConfirmedError}
			          //label={conditionsConfirmedHelperText}
                      onChange={(e) => {
					    setConditionsConfirmed(e.target.checked);
			            setConditionsConfirmedError(e.target.checked === false);
				        }}
					/>
				    <Typography variant="caption">
					  By clicking here, I confirm that I have read and understood the
					  <a href={termsUrl} target="_blank" rel="noopener noreferrer">
					    terms and conditions
					  </a>
					  and our
					  <a href={privacyUrl} target="_blank" rel="noopener noreferrer">
					    privacy policy
					  </a>.
					</Typography>
				  </Stack>
				  <Grid container justifyContent="center">
				    <Button variant="contained" type='submit' disabled={registerDisabled} onClick={submitHandler}>Register</Button>
		          </Grid>
  				</Stack>
				<p> </p>
			    <Typography>
				  Click <Link to="/verify">here</Link> if you want to verify your account registration using a code you have been sent.
			    </Typography>
              </form>
		    </Box>
	      </Card>
	    </Grid>
      </Stack>
	  <AlertDialog
	    openState={waitDialogOpenState}
	    setOpenState={setWaitDialogOpenState}
	    showButtons={false}
	    optionalDialog={null}
	    actionFunction={null}
	    title=""
	    text="Please wait..."
	  />
	</Grid>
  );
};

export default RegisterPage;
