import React from 'react';
import { useNavigate } from "react-router-dom"

import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Button from '@mui/material/Button';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';


// props:
//	title
//	count
//	description
//	jumpToPath

function CollectionStatsItem(props) {
  const navigate = useNavigate();

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
	    <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
		  <Grid item>
	        <Typography>{props.title}</Typography>
		  </Grid>
		  <Grid item>
	        <Typography>[{props.count}]</Typography>
		  </Grid>
		</Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>{props.description}</Typography>
      </AccordionDetails>
	  <AccordionActions>
	    <Button onClick={(event) => {
		  navigate(props.jumpToPath);
		}}>
		  View
		</Button>
	  </AccordionActions>
    </Accordion>
  );
}

export default CollectionStatsItem;
