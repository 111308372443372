import React, { useState, useContext, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import callApi from '../api/api';
import { tokenIsValid, TokenContext } from '../auth/token'
import { ErrorListContext, logErrorString } from '../error/errorList'
import { ReloadDataContext, triggerDataReload } from '../context/reloadData'

import { SubscriptionStatusContext, restrictPage } from '../context/subscriptionStatus'
import SubscriptionExpiredMessage from '../components/SubscriptionExpiredMessage'

import NotLoggedInMessage from '../components/NotLoggedInMessage'
import Tile from '../components/Tile'
import StyledCard from '../components/StyledCard'


function PackageSelectionPage() {

  const [products, setProducts] = useState([]);
  const [/*subscriptionData*/, setSubscriptionData] = useState(null);
  const [currency, setCurrency] = useState('aud');
  
  const [token, setToken] = useContext(TokenContext);
  const [errorList, setErrorList] = useContext(ErrorListContext);
  const [reloadData, setReloadData] = useContext(ReloadDataContext);
  const [subscriptionStatus, /*setSubscriptionStatus*/] = useContext(SubscriptionStatusContext);

  const navigate = useNavigate();
  
  const fetchProductsCallback = useCallback( async () => {
	const data = {
	  "action": "get_products",
	  "payload": { }
	};
	
	const retVal = await callApi(token, setToken, data);
	console.log(retVal)
	setProducts(retVal.data)
	logErrorString(retVal.errorMessage, errorList, setErrorList)
  }, [token, setToken]);
  
  useEffect(() => {
    fetchProductsCallback()
  }, [fetchProductsCallback, reloadData])

  // do not allow to proceed if not logged in
  //
  if (!tokenIsValid(token)) {
    return <NotLoggedInMessage />;
  }
  // nor if subscription expired and page is restricted
  //
  if (restrictPage(subscriptionStatus, "PackageSelectionPage")) {
    return <SubscriptionExpiredMessage />;
  }
  
  const createSubscription = async (price_id, interval) => {
	let frequency = ""
	if (interval === 'year') {
	  frequency = "ANNUAL";
	} else if (interval === 'month') {
	  frequency = "MONTHLY";
	} else {
	  logErrorString("Invalid interval for subscription: " + interval, errorList, setErrorList);
	  return;
	}
	const data = {
	  "action": "create_subscription",
	  "payload": {
		'price_id': price_id,
		'currency': currency,
		'frequency': frequency,
	  }
	};
	console.log(data)
	
	const retVal = await callApi(token, setToken, data);
	console.log(retVal)
	console.log(retVal.data['payment_id'])
    setSubscriptionData(retVal.data);
	logErrorString(retVal.errorMessage, errorList, setErrorList)
	
	navigate("/account/payment", { state: {paymentId: retVal.data['payment_id']} });

	// trigger reload of data from the database
	// do this after call to 'navigate'
	triggerDataReload(reloadData, setReloadData);
  }
  
  const handleCurrencyChange = (event) => {
    setCurrency(event.target.value);
  };
  
  if (products.length === 0) {
    return (
      <Grid container justifyContent="center">
        <Stack spacing={2}>
          <Grid container justifyContent="center">
            <h1>Your Account</h1>
	      </Grid>
          <Grid container justifyContent="center">
            <CircularProgress />
	      </Grid>
	    </Stack>
	  </Grid>
	);
  }
  
  let productMonthly = null;
  let productAnnual = null;
  if (products[0].recurring.interval === "month") {
	productMonthly = products[0];
	productAnnual = products[1];
  } else {
	productMonthly = products[1];
	productAnnual = products[0];
  }

  const currencyString = currency.toUpperCase();  
  const monthlyPriceRaw = productMonthly.currency_options[currency].unit_amount;
  const monthlyPrice = Number(monthlyPriceRaw / 100).toFixed(2);
  const monthlyContent = "This is our standard subscription with full access to all features, at a cost of " + currencyString + " " + monthlyPrice + " each month.";
  
  const annualPriceRaw = productAnnual.currency_options[currency].unit_amount;
  const annualPrice = Number(annualPriceRaw / 100).toFixed(2);
  const discount = Math.floor(((monthlyPriceRaw * 12) - annualPriceRaw) / (monthlyPriceRaw * 12) * 100);
  const annualContent = "This provides the same benefits as our standard subscription but at a reduced cost of " + currencyString + " " + annualPrice + " each year, representing a discount of " + discount + "% against the standard suscription.";
  
  return (
    <Grid container justifyContent="center">
      <Stack spacing={2}>
        <Grid container justifyContent="center">
          <h1>Your Account</h1>
	    </Grid>
		<StyledCard>
        <Box sx={{ width: 550 }}>
		  <Grid container justifyContent="center">
		    <Typography>
		      Thank you for your continued use of Your Collections Online.
		    </Typography>
	      </Grid>
		</Box>
        <Box sx={{ width: 550 }}>
          <Grid container justifyContent="center">
		    <Typography>
		      Please select your preferred subscription model from the options below.
		    </Typography>
	      </Grid>
		</Box>
		</StyledCard>
        <Grid container justifyContent="center">
		  <Stack direction="row" spacing={2}>
		    <Tile
			  title = "Monthly"
			  content = {monthlyContent}
			  width = {250}
			  action = {() => createSubscription(productMonthly.id, productMonthly.recurring.interval, "usd")}
			  imageUrl = {null}
			  imageAlt = {null}
		    />
		    <Tile
			  title = "Annual"
			  content = {annualContent}
			  width = {250}
			  action = {() => createSubscription(productAnnual.id, productAnnual.recurring.interval, "usd")}
			  imageUrl = {null}
			  imageAlt = {null}
		    />
		  </Stack>
	    </Grid>
        <Grid container justifyContent="center">
		  <StyledCard>
		  <Stack direction="row" spacing={2} alignItems="centre">
		    <Typography>
		      Select your preferred currency from those available:
		    </Typography>
            <FormControl sx={{ m: 1, minWidth: 70 }} size="small">
              <Select
                value={currency}
                onChange={handleCurrencyChange}
              >
                <MenuItem value={"aud"}>AUD</MenuItem>
                <MenuItem value={"eur"}>EUR</MenuItem>
                <MenuItem value={"gbp"}>GBP</MenuItem>
                <MenuItem value={"usd"}>USD</MenuItem>
              </Select>
		    </FormControl>
		  </Stack>
		  </StyledCard>
	    </Grid>
        <StyledCard>
		<Box sx={{ width: 550 }}>
          <Grid container justifyContent="center">
		    <Typography>
			  When you click on an option, you will be taken to our secure payment portal to complete your initial payment and set up recurring payments to maintain your access until your choose to cancel your subscription.
		    </Typography>
	      </Grid>
		</Box>
		</StyledCard>
	  </Stack>
	</Grid>
  );
}

export default PackageSelectionPage;
