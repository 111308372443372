import React, { useState } from 'react';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';	

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";


// helper function for reordering / removing an item
//
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  if (endIndex !== null) {
    result.splice(endIndex, 0, removed);
  }

  return result;
};


// helper function to highlight items while they are being dragged
//
const getItemStyle = (isDragging, draggableStyle) => ({
  // styles we need to apply on draggables
  ...draggableStyle,

  ...(isDragging && {
    background: "rgb(235,235,235)"
  })
});


function DropdownAttributeConfiguration (props) {

  const [itemList, setItemList] = useState(() => {
    try {
	  let v = JSON.parse(props.defaultConfigInfo);
	  if (v === null) {
	    v = [];
	  }
	  return v;
    } catch {
	  return ([]);
    }
  });
  const [newItemText, setNewItemText] = useState("");

  //
  //
  const onDragEnd = (result) => {
	
	let startIndex = result.source.index;

	let endIndex = null; // default is to remove item unless have a destination
	if (result.destination) {
	  endIndex = result.destination.index;
    }

    const items = reorder(itemList, startIndex, endIndex);
	
    setItemList(items);
	props.onConfigInfoChange(JSON.stringify(items))
  }

  //
  //
  const addHandler = () => {
	let items = Array.from(itemList);
	items.push(newItemText);
	setNewItemText("");

    setItemList(items);
	props.onConfigInfoChange(JSON.stringify(items))
  }
  
  //
  // If read only then show a simple list...
  if (props.editMode === false){
	return (
	  <List dense={true}>
        {itemList.map((item, index) => (
          <ListItem>
            <ListItemText
              primary={item}
            />
		  </ListItem>
	    ))}
      </List>
	);
  }
  
  const addButtonDisabled = (newItemText.length === 0);
  
  // NOTE - ListItemSecondaryAction below is not needed really
  //        but if remove then DnD fails
  // TODO - debug why and get rid of the ListItemSecondaryAction Element
  //
  return (
    <>
	  <Stack direction='row' spacing={1} >
	    <TextField
	      id='add-item-text'
		  value={newItemText}
	      sx={{mb: 3}}
          size='small'
		  fullWidth
	      onChange = {(e) => {
			setNewItemText(e.target.value);
	      }}
        />
		<Button variant="contained" disabled={addButtonDisabled} onClick={addHandler}>Add</Button>
	  </Stack>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <List ref={provided.innerRef} >
              {itemList.map((item, index) => (
                <Draggable key={item} draggableId={item} index={index}>
                  {(provided, snapshot) => (
                    <ListItem
                      ContainerComponent="li"
                      ContainerProps={{ ref: provided.innerRef }}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
				  	  dense={true}
                    >
                      <ListItemText
                        primary={item}
                      />
                      <ListItemSecondaryAction />
                    </ListItem>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </List>
          )}
        </Droppable>
      </DragDropContext>
	</>
  );
}

export default DropdownAttributeConfiguration;
