import { createContext } from 'react';

// ====================
// Access token
//====================
export const TokenContext = createContext();

const token_key = "AWT_Token"

//
// control how store the token
//
let stayLoggedIn = false;
export function setStayLoggedIn (newSetting) {
  stayLoggedIn = newSetting;
}

export function saveToken (tokenToSave) {
  const tokenObject = {
	value: tokenToSave,
	start: Date.now(),
  }
  
  // if clearing the token, do it in both localStorage AND sessionStorage
  // else setting token, so only in the one determined by "stayLoggedIn" flag
  const setBoth = (tokenToSave === "") ? true : false;
  
  if (stayLoggedIn || setBoth) {
    localStorage.setItem(token_key, JSON.stringify(tokenObject));
  }
  if (!stayLoggedIn || setBoth) {
    sessionStorage.setItem(token_key, JSON.stringify(tokenObject));
  }
}

export function getToken () {
  let tokenData = null;
  let found = false;
  let tokenObject = null;
  
  // try and get token from sessionStorage first
  tokenData = sessionStorage.getItem(token_key);
  if (tokenData !== null && tokenData !== "") {
    tokenObject = JSON.parse(tokenData);
	if (tokenObject.value !== "") {
	  found = true;
	}
  }
  if (!found) {
    // not found in sessionStorage, so try localStorage second
    tokenData = localStorage.getItem(token_key);
	if (tokenData !== null && tokenData !== "") {
	  tokenObject = JSON.parse(tokenData);
	  if (tokenObject.value !== "") {
		found = true;
	  }
	}
  }
  if (!found) {
	// still not found...
    return null;
  }
  
  /*
  if ((Date.now() - tokenObject.start) > 3595000) {
	// expired - older than an hour - 3595000
	// (minus 5 seconds to ensure we call it expired before it actually expires)
	return null;
  }
  */
  
  return tokenObject.value;
}

export function tokenIsValid (token) {
  if (token === null) {
	return false;
  } else if (token === "") {
	return false;
  }
  return true;
}
