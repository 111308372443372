import React from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import create_3 from '../support_data/create_3.png'

function SupportReferenceCreatePage() {
  return (
	<Box sx={{ width: 550 }}>
      <Grid container justifyContent="center">
        <img src={create_3} width="495" height="334" alt="" />
	  </Grid>
	  <br/>
	  <Typography>
		When creating from scratch there are two decisions you need to make.
		<br/><br/>First what type of collection you want from 3 options: a catalog, a planned collection, or an ad-hoc (unplanned) collection.
		<br/><br/>The second decision is whether your collection will include sets and not just individual items - this would allow you to store additional information about the sets themselves as well as the items within each set.
		<br/><br/>The Description field is optional but very useful in addition to the name when looking at the list of your collections.
		<br/><br/>Click on Create button to complete the process and you will be taken to the Collection detail page to view the collection.
	  </Typography>
	  <br/><br/>
	</Box>
  );
};

export default SupportReferenceCreatePage;
