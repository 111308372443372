import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';

import { tokenIsValid, TokenContext } from '../auth/token'

import { SubscriptionStatusContext, restrictPage } from '../context/subscriptionStatus'
import SubscriptionExpiredMessage from '../components/SubscriptionExpiredMessage'

import NotLoggedInMessage from '../components/NotLoggedInMessage'
import Tile from '../components/Tile'


function SupportPage() {

  const [token, /*setToken*/] = useContext(TokenContext);
  const [subscriptionStatus, /*setSubscriptionStatus*/] = useContext(SubscriptionStatusContext);
  // do not allow to proceed if not logged in
  //
  if (!tokenIsValid(token)) {
    return <NotLoggedInMessage />;
  }
  // nor if subscription expired and page is restricted
  //
  if (restrictPage(subscriptionStatus, "SupportPage")) {
    return <SubscriptionExpiredMessage />;
  }
  
  return (
    <Grid container justifyContent="center">
      <Stack spacing={2}>
        <Grid container justifyContent="center">
          <h1>Your Support</h1>
	    </Grid>
        <Grid container justifyContent="center">
          <Stack direction="row" spacing={2}>
		    <Tile
		      title = "Reference"
			  content = "Click here to view detailed informaiton on how to use Your Collections Online to store informaiton about your collections."
			  width = {200}
			  destination = "/support/reference"
			  imageUrl = {null}
			  imageAlt = {null}
		    />
			{/*
			//
			// Remove this for now...
			//
		    <Tile
		      title = "How To"
			  content = "Click here to view step-by-step instructions on common actions within Your Collections Online."
			  width = {200}
			  destination = "/support/how_to"
			  imageUrl = {null}
			  imageAlt = {null}
		    />
			*/}
		    <Tile
		      title = "Contact Us"
			  content = "Click here to get in touch with our support team: tell us about any issues, ask questions or let us know any suggestions you have."
			  width = {200}
			  destination = "/support/support_items"
			  imageUrl = {null}
			  imageAlt = {null}
		    />
		  </Stack>
	    </Grid>
        <Grid container justifyContent="center">
          <Stack direction="row" spacing={2}>
		    <Tile
		      title = "T's & C's"
			  content = "Click here to view the terms and conditions covering your usage of Your Collections Online."
			  width = {200}
			  destination = "/support/terms"
			  imageUrl = {null}
			  imageAlt = {null}
		    />
		    <Tile
		      title = "Privacy Policy"
			  content = "Click here to view our Privacy Policy."
			  width = {200}
			  destination = "/support/privacy"
			  imageUrl = {null}
			  imageAlt = {null}
		    />
		  </Stack>
	    </Grid>
        <Grid container justifyContent="center">
          <Link to="/support/error_log">View errors log</Link>
	    </Grid>
	  </Stack>
	</Grid>
  );
}

export default SupportPage;
