import React, { useState, useContext, useEffect, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';

import callApi from '../api/api';
import { tokenIsValid, TokenContext } from '../auth/token';
import { ErrorListContext, logErrorString } from '../error/errorList'
import { ReloadDataContext } from '../context/reloadData'

import { SubscriptionStatusContext, restrictPage } from '../context/subscriptionStatus'
import SubscriptionExpiredMessage from '../components/SubscriptionExpiredMessage'

import NotLoggedInMessage from '../components/NotLoggedInMessage'
import CollectionsList from '../components/CollectionsList';


function CollectionsPage() {
  
  const { state } = useLocation();
  
  const [collections, setCollections] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);

  const [token, setToken] = useContext(TokenContext);
  const [errorList, setErrorList] = useContext(ErrorListContext);
  const [reloadData, /*setReloadData*/] = useContext(ReloadDataContext);
  const [subscriptionStatus, /*setSubscriptionStatus*/] = useContext(SubscriptionStatusContext);

  const navigate = useNavigate();

  const fetchDataCallback = useCallback( async () => {
	const data = {
	  "action": "get_collection_list",
	  "payload": { }
	};
	
	const retVal = await callApi(token, setToken, data);
	console.log(retVal)
	setCollections(retVal.data);
	setDataLoaded(true);
	logErrorString(retVal.errorMessage, errorList, setErrorList)
	
  }, [token, setToken]);

  useEffect(() => {
	console.log("CollectionsPage - useEffect");
	fetchDataCallback();
  }, [fetchDataCallback, reloadData])

  // do not allow to proceed if not logged in
  //
  if (!tokenIsValid(token)) {
    return <NotLoggedInMessage />;
  }
  // nor if subscription expired and page is restricted
  //
  if (restrictPage(subscriptionStatus, "CollectionsPage")) {
	if (state === null) {
	  // not called from Login
      return <SubscriptionExpiredMessage />;
	}
	// was called from Login but we're not allowed to access this page
	// so redirect to the Account page instead
	navigate("/account");
  }

  const createCollectionHandler = (event) => {
	console.log("createCollectionHandler");
    event.preventDefault();
	navigate("/collections/create");
  };
  
  let listJSX = (
      <CircularProgress />
	);
  if (dataLoaded === true) {
	listJSX = (
	  <CollectionsList collections={collections} />
	);
  }
  
  return (
    <Grid container justifyContent="center">
      <Stack spacing={2}>
        <Grid container justifyContent="center">
          <h1>Your Collections</h1>
	    </Grid>
        <Grid container justifyContent="center">
		  <Button variant="contained" onClick={createCollectionHandler}>Create Collection</Button>
	    </Grid>
        <Grid container justifyContent="center">
		  {listJSX}
	    </Grid>
      </Stack>
    </Grid>
  );
}

export default CollectionsPage;
