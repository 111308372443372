import React, { useState, useEffect } from 'react';

import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';


// props list
//
// editMode
// attributeName
// attributeType - not used
// attributeValue
// attributeConfigInfo - not used
// attributeDescription
// attributeIsMandatory
// updatedValues
// setUpdatedValues
// updateAttributesInError
//

function TextAttributeDisplay (props) {
  const [valueInError, setValueInError] = useState(true);
  
  console.log("TextAttributeDisplay: " + props.attributeName);
  console.log(props);
  
  // make sure valueInError is initialised correctly
  //
  // NOTE - props.updateAttributesInError should be in the dependency list
  //        but if do that then propogation of valueInError doesn't work
  //        fine when goes out of error, does nothing when goes back into error
  //
  useEffect(() => {
	if ((props.attributeIsMandatory === true) && (props.editMode === true)) {
	  const isInError = props.attributeValue.length === 0
	  setValueInError(isInError);
	  props.updateAttributesInError(isInError, props.attributeName);
	}
  }, [props.attributeIsMandatory, props.editMode, props.attributeValue /*, props.updateAttributesInError*/]);
  
  // call props.updateAttributesInError to update list when valueInError changes
  //
  useEffect(() => {
	if ((props.attributeIsMandatory === true) && (props.editMode === true)) {
	  props.updateAttributesInError(valueInError, props.attributeName);
	}
  }, [valueInError, props.attributeIsMandatory, props.editMode, props.updateAttributesInError]);
  
  // set up helper text if needed
  //
  let valueError = false;
  let nameRequired = false;
  let nameHelperText = "";
  if (props.editMode === true) {
	nameRequired = props.attributeIsMandatory === true;
    if (nameRequired === true) {
	  valueError = valueInError
      if (valueError === true) {
	    nameHelperText = props.attributeName + " must be specified.";
	  }
	}
  }
  
  return (
    <Tooltip arrow title={props.attributeDescription}>
    <TextField
	  id={props.attributeName}
      label={props.attributeName}
      defaultValue={props.attributeValue}
      InputProps={{
        readOnly: (props.editMode === false),
      }}
	  required={nameRequired}
	  error={valueError}
	  helperText={nameHelperText}
	  sx={{mb: 3}}
	  multiline
	  maxRows={4}
	  //color="secondary"
	  onChange = {(e) => {
		let dupUpdatedValues = props.updatedValues;
		dupUpdatedValues[props.attributeName] = e.target.value;
		props.setUpdatedValues(dupUpdatedValues);
		if ((props.attributeIsMandatory === true) && (props.editMode === true)) {
		  setValueInError(e.target.value.length === 0);
		}
	  }}
    />
    </Tooltip>
  );
}

export default TextAttributeDisplay;
