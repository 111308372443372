import React from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import create_4 from '../support_data/create_4.png'

function SupportReferenceCreatePage() {
  return (
	<Box sx={{ width: 550 }}>
      <Grid container justifyContent="center">
        <img src={create_4} width="491" height="338" alt="" />
	  </Grid>
	  <br/>
	  <Typography>
		When creating by using one the pre-defined templates you will need to select which template to use from the dropdown menu.
		<br/><br/>Click on Create button to complete the process and you will be taken to the Collection detail page to view the collection.
	  </Typography>
	  <br/><br/>
	</Box>
  );
};

export default SupportReferenceCreatePage;
