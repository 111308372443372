import { createContext } from 'react';

import { PageRestrictionDefinitions } from '../PageRestrictionDefinitions'


export const SubscriptionStatusContext = createContext();


export function restrictPage (subscriptionStatus, pageName) {
  console.log("restrictPage: start");
  console.log(subscriptionStatus);
  
  let expired = false;
  
  const pageEntry = PageRestrictionDefinitions.find(pageEntry => pageEntry.name === pageName);
  console.log("restrictPage: pageEntry=" + pageEntry);
  
  if (pageEntry === undefined) {
	  throw new Error('Invalid page name passed for checking: ' + pageName);
  }
  
  if (pageEntry.canBeRestricted) {
    if ((subscriptionStatus.subs_end !== null) && (subscriptionStatus.subs_end !== "")) {
	  const now = new Date();
	  const end = new Date(subscriptionStatus.subs_end);
	  expired = (end < now) ? true : false;
    }
  }
  
  console.log("restrictPage: expired=" + expired);
  return expired;
}
