import React, { useRef, useContext, useState } from 'react';

import Button from '@mui/material/Button';

import callApi from '../api/api';
import { TokenContext } from '../auth/token';
import { ErrorListContext, logErrorString } from '../error/errorList'

import AlertDialog from '../components/AlertDialog'


//
// Control which upload method is used
//
const USE_METHOD_1 = true;


const FileUploader = ({fileChanged, buttonText, destination, useSelectedFileName}) => {
  
  const [uploadingDialogOpenState, setUploadingDialogOpenState] = useState(false);
  
  const [token, setToken] = useContext(TokenContext);
  const [errorList, setErrorList] = useContext(ErrorListContext);
  
  // Create a reference to the hidden file input element
  const hiddenFileInput = useRef(null);
  
  // Programatically click the hidden file input element
  // when the Button component is clicked
  const handleClick = event => {
	event.preventDefault();
    hiddenFileInput.current.click();
  };
  
  // Call a function (passed as a prop from the parent component)
  // to handle the user-selected file 
  const handleChange = async event => {
	console.log("FileUploader::handleChange");
	event.preventDefault();
	
    const fileUploaded = event.target.files[0];
	console.log(fileUploaded);
	
	if (fileUploaded === undefined) {
	  // no file selected so nothing to Document
	  return;
	}
    
	setUploadingDialogOpenState(true);
	
	let name_to_use = ""
	if (useSelectedFileName) {
      name_to_use = fileUploaded.name
	}
	
	let file_name = ""
	
	if (USE_METHOD_1) {
	  
	  //
	  // METHOD 2 - call API to get presigned URL then upload to that
	  //
	  
	  const data = {
	    "action": "get_upload_url",
	    "payload": { 
	      "content_type": fileUploaded.type,
		  "destination": destination,
		  "name_to_use": name_to_use,
		  }
	  };
	  const retVal = await callApi(token, setToken, data);
	  console.log(retVal)
	  logErrorString(retVal.errorMessage, errorList, setErrorList)
	  
      // upload the file to the URL
	  const url = retVal.data['url']
	  file_name = retVal.data['file_name']
	  
      const result = await fetch(url, {
	    mode: 'cors',
	    method: "PUT",
	    headers:{'content-type': fileUploaded.type},
        body: fileUploaded,
      });
	  console.log(result);
	  
	} else {
	  
	  //
	  // METHOD 2 - call API to upload file directly 
	  //
	  
	  //
	  // NOTE - this API call currently does not work properly
	  //      - it will upload file but the contents is invalid
	  //
	  
	  let formData = new FormData();
      formData.append("file", fileUploaded);
      formData.append("destination", "image");
      formData.append("name_to_use", fileUploaded.name);
	  const retVal = await callApi(token, setToken, formData, "multipart/form-data");
	  console.log(retVal)
	  logErrorString(retVal.errorMessage, errorList, setErrorList)
	  
	  //
	  // TODO - process returned (success, file_name, message) from API call
	  //
	}
    
	setUploadingDialogOpenState(false);
	
	// return (localName, remoteName)
    fileChanged(fileUploaded.name, file_name);
  };

  return (
    <>
      <Button variant="contained" onClick={handleClick}>
		  {buttonText}
      </Button>
      <input
        type="file"
        onChange={handleChange}
        ref={hiddenFileInput}
        style={{display: 'none'}} // Make the file input element invisible
      />
	  <AlertDialog
	    openState={uploadingDialogOpenState}
	    setOpenState={setUploadingDialogOpenState}
	    showButtons={false}
	    optionalDialog={null}
	    actionFunction={null}
	    title=""
	    text="Uploading file, please wait..."
	  />	  
    </>
  );
};

export default FileUploader;
