import React from 'react';

import Box from '@mui/material/Box';
//import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';


function SupportReferenceConfigPage() {
  return (
	<Box sx={{ width: 550 }}>
	  <Typography>
		Once a collection is created the only configuration needed before you start using your new collection is to define or update the data fields (which we call attributes).
		<br/><br/>These define what information you want to hold for each of the entity types that are applicable for the type of collection you have created, so you will need to plan out what attributes you want for each of the entities before you start this step.
		<br/><br/>Note that you can always change your mind later and add new attributes or delete existing ones at any time in the future.
		<br/><br/><b>So when planning the attributes of your new collection what should be defined against each entity type?</b>
		<br/><br/>Below are some examples to help you plan out your own collection.
		<br/><br/><u>Collectables Items</u> should include attributes that would be correct for all examples of that item:
		<ul><li>Design used for the item
		</li><li>Who made it or the manufacturer
		</li><li>When the item was made – could be a single date for say a coin or a range for piece of furniture for example
		</li><li>How many were made
		</li><li>What the values could be – often a range based on the different conditions an example might be in
		</li></ul>
		<u>Example Items</u> should include attributes that would be specific to an actual item you own in your collection:
		<ul><li>When and where you bought it
		</li><li>What it cost to buy
		</li><li>What condition it is in
		</li><li>Where it is located – could be a specific cabinet or a room
		</li></ul>
		Similarly <u>Collectable Sets</u> would contain attributes that describes the set as a whole, that would be correct for all examples of that set, and are in addition to the attributes for the Collectable Items within the set:
		<ul><li>Description of the set housing or packaging
		</li><li>What the set is called, if it has a name at all – is it to celebrate an event for example
		</li><li>How many were made – there might have been 1000 of the items within made but only 50 put into sets for example
		</li></ul>
		And <u>Example Sets</u> would contain attributes that would be specific to an actual set you own in your collection, and are in addition to the attributes for the Example Items within the set:
		<ul><li>When and where bought 
		</li><li>What it cost to buy - these would both supersede the equivalent attributes for the individual items within the set if the set was bought as a single unit
		</li><li>Condition of the set packaging
		</li></ul>
		Hopefully that has given you some ideas to start with. Take a look at some of the standard templates that are available for ideas or Contact Us with a question if you need additional help.
		<br/><br/>
	  </Typography>
	  <br/><br/>
	</Box>
  );
};

export default SupportReferenceConfigPage;
