import React, { useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import Constants from '../Constants'

import { tokenIsValid, TokenContext } from '../auth/token'
import {
  CurrentCollectionContext,
  checkCollectableSetsValidForCollection,
  } from '../context/currentCollection'

import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

import { SubscriptionStatusContext, restrictPage } from '../context/subscriptionStatus'
import SubscriptionExpiredMessage from '../components/SubscriptionExpiredMessage'

import NotLoggedInMessage from '../components/NotLoggedInMessage'
import EntityList from '../components/EntityList'
import EntityTitle from '../components/EntityTitle'


function ExampleSetsPage() {

  const [token, /*setToken*/] = useContext(TokenContext);
  const [currentCollection, /*setCurrentCollection*/] = useContext(CurrentCollectionContext);
  const [subscriptionStatus, /*setSubscriptionStatus*/] = useContext(SubscriptionStatusContext);

  const navigate = useNavigate();
  
  const params = useParams();

  // do not allow to proceed if not logged in
  //
  if (!tokenIsValid(token)) {
    return <NotLoggedInMessage />;
  }
  // nor if subscription expired and page is restricted
  //
  if (restrictPage(subscriptionStatus, "ExampleSetsPage")) {
    return <SubscriptionExpiredMessage />;
  }
  
  //
  //
  const createExampleSetHandler = async (event) => {
	console.log("createExampleSetHandler");
    event.preventDefault();
	
	navigate("/collections/" + params.collectionId + "/es/create");
  };
  
  let createExampleSetJSX = ""
  if (!checkCollectableSetsValidForCollection(currentCollection, params.collectionId)) {
	createExampleSetJSX = (
	  <Button variant="contained" onClick={createExampleSetHandler}>Create</Button>
	);
  }
  
  const baseUrlPath = "/collections/" + params.collectionId + "/es/"
  return (
	<div>
        <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
		  <EntityTitle
			title={"Example Sets"}
			entityType={Constants.ExampleSetEntity}
			variant="h4"
		  />
		  {createExampleSetJSX}
        </Stack>
		<EntityList
		  entityType="ESforC"
		  title=""
		  getDataAction="get_example_set_list"
		  entityId={params.collectionId}
          collectionId={params.collectionId}
		  baseUrlPath={baseUrlPath}
		  height={700}
		  enableCheckboxSelection={false}
		/>
	</div>
  );
}

export default ExampleSetsPage;
