import React from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import create_1 from '../support_data/create_1.png'
import create_2 from '../support_data/create_2.png'

function SupportReferenceCreatePage() {
  return (
	<Box sx={{ width: 550 }}>
	  <Typography>
	    The starting point for creating a collection is the Your Collections page - click on the "Collections" link in the banner at the top of the page.
	  </Typography>
	  <br />
      <Grid container justifyContent="center">
        <img src={create_1} width="340" height="180" alt="" />
	  </Grid>
	  <br/>
	  <Typography>
	    Start the collection creation process by clicking the "Create Collection" button and the following page will be displayed.
	  </Typography>
	  <br/>
      <Grid container justifyContent="center">
        <img src={create_2} width="343" height="392" alt="" />
	  </Grid>
	  <br/>
	  <Typography>
		Each of your collections requires a unique name (but only across your own collections) which you must specify when you create it.
        <br/><br/>There are multiple options in how the new collection can be created:
		<ul><li>using one of our templates
		</li><li>import from Excel
		</li><li>copy some or all of an existing collection
		</li><li>or build up from scratch
		</li></ul>
        Once you have selected the option you want, click Next to take you onto one of the options shown on the following four pages.
	  </Typography>
	  <br/><br/>
	</Box>
  );
};

export default SupportReferenceCreatePage;
