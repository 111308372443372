import React from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import attribute_1 from '../support_data/attribute_1.png'
import manage_1 from '../support_data/manage_1.png'
import manage_2 from '../support_data/manage_2.png'
import manage_3 from '../support_data/manage_3.png'


function SupportReferenceManagePage() {
  return (
	<Box sx={{ width: 550 }}>
	  <Typography>
	    Now you are ready to use the Your Collections portal to create entries to record information about your collection, as well as update that information as your collection grows and changes.
	    <br/><br/>When viewing a Collection’s detail page you can view and update the attributes of the Collection as a whole.
	    <br/><br/>You access all the collectables and examples in your collection from the Collection’s detail page by clicking on the entity type you want to view.
	    </Typography>
	  <br/>
      <Grid container justifyContent="center">
        <img src={attribute_1} width="459" height="562" alt="" />
	  </Grid>
	  <br/>
	  <Typography>
	    In all cases get list of the items or sets as a table.
	  </Typography>
      <br/>
	  <Grid container justifyContent="center">
        <img src={manage_1} width="602" height="260" alt="" />
	  </Grid>
	  <br/>
	  <Typography>
	    In lists can page using arrows at bottom right corner of table, changing page sizes from range of options.
	  </Typography>
      <br/>
	  <Grid container justifyContent="center">
        <img src={manage_2} width="467" height="218" alt="" />
	  </Grid>
	  <br/>
	  <Typography>
	    You can also browse / search your collection information using the comprehensive and flexible search and filtering options across one or more column headers by clicking on the 3 vertical dots symbol on the column header fields.
	  </Typography>
      <br/>
	  <Grid container justifyContent="center">
        <img src={manage_3} width="471" height="358" alt="" />
	  </Grid>
	  <br/>
	  <Typography>
	    Can change the default order of columns using the pin left and pin right options from the column headers.
		<br/><br/>When click on line in the table then taken to page to view the item or set which will vary depending on the entity type.
	    <br/><br/>Notes:
		<ul><li>the "Show in summary" flag in an attribute definition also controls whether an attribute is included in the table
		</li><li>currently attributes of type Image cannot be displayed in the table and is not possible for you to change that
		</li><li>if no attributes defined for an entity type then defaults to showing internal ID column that is normally hidden plus a warning message above the table
		</li></ul>
	  </Typography>
	  <br/><br/>
	</Box>
  );
};

export default SupportReferenceManagePage;
