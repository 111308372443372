import React, { useState, useContext } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Tooltip from '@mui/material/Tooltip';

import { tokenIsValid, TokenContext } from '../auth/token';
import Constants from '../Constants'
import {
  CurrentCollectionContext,
  checkCollectableSetsValidForCollection,
  checkCollectableItemsValidForCollection,
  checkExampleSetsValidForCollection,
  checkExampleItemsValidForCollection
  } from '../context/currentCollection'

import { SubscriptionStatusContext, restrictPage } from '../context/subscriptionStatus'
import SubscriptionExpiredMessage from '../components/SubscriptionExpiredMessage'

import NotLoggedInMessage from '../components/NotLoggedInMessage'
import AttributeDefinitionsList from '../components/AttributeDefinitionsList';
import { contextIcon } from '../icons/icons'


function AttributeDefinitionsPage() {
  
  // Note - set initial value from the URL value passed by the caller
  //      - if not present then default to the Collection tab
  //
  const [searchParams] = useSearchParams();
  const [tabValue, setTabValue] = useState(() => {
	let initValue = '1'; // default value
    const tabIndex = searchParams.get('tab');
	if (tabIndex !== null) {
	  initValue = tabIndex;
	}
	return initValue;
  });

  const [token, /*setToken*/] = useContext(TokenContext);
  const [currentCollection, /*setCurrentCollection*/] = useContext(CurrentCollectionContext);
  const [subscriptionStatus, /*setSubscriptionStatus*/] = useContext(SubscriptionStatusContext);
  
  const params = useParams();

  if (!tokenIsValid(token)) {
    return <NotLoggedInMessage />;
  }
  // nor if subscription expired and page is restricted
  //
  if (restrictPage(subscriptionStatus, "AttributeDefinitionsPage")) {
    return <SubscriptionExpiredMessage />;
  }

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };
  
  const iconSize = 75;
  
  const icon_c = contextIcon(Constants.CollectionEntity, currentCollection['Type'], currentCollection['HasSets'])
  const collectionTabJSX = (
	  <Tab
	    value="1"
		label={<Tooltip title="Collection">
                 <img src={icon_c} width={iconSize} height={iconSize} alt=""></img>
               </Tooltip>}
	  />
	);
  const collectionTabPanelJSX = (<TabPanel value="1"> <AttributeDefinitionsList entityType={Constants.CollectionEntity} tabIndex="1" /> </TabPanel>);
  
  let csTabJSX = "";
  let csTabPanelJSX = "";
  if (checkCollectableSetsValidForCollection(currentCollection, params.collectionId)) {
	const icon_cs = contextIcon(Constants.CollectableSetEntity, currentCollection['Type'], currentCollection['HasSets'])
    csTabJSX = (
	  <Tab
		value="2"
		label={<Tooltip title="Collectable Sets">
                 <img src={icon_cs} width={iconSize} height={iconSize} alt=""></img>
               </Tooltip>}
	  />
	);
    csTabPanelJSX = (<TabPanel value="2"> <AttributeDefinitionsList entityType={Constants.CollectableSetEntity} tabIndex="2" /> </TabPanel>);
  }
  
  let ciTabJSX = "";
  let ciTabPanelJSX = "";
  if (checkCollectableItemsValidForCollection(currentCollection, params.collectionId)) {
	const icon_ci = contextIcon(Constants.CollectableItemEntity, currentCollection['Type'], currentCollection['HasSets'])
    ciTabJSX = (
	  <Tab
	    value="3"
		label={<Tooltip title="Collectable Items">
                 <img src={icon_ci} width={iconSize} height={iconSize} alt=""></img>
               </Tooltip>}
	  />
	);
    ciTabPanelJSX = (<TabPanel value="3"> <AttributeDefinitionsList entityType={Constants.CollectableItemEntity} tabIndex="3" /> </TabPanel>);
  }
  
  let esTabJSX = "";
  let esTabPanelJSX = "";
  if (checkExampleSetsValidForCollection(currentCollection, params.collectionId)) {
	const icon_es = contextIcon(Constants.ExampleSetEntity, currentCollection['Type'], currentCollection['HasSets'])
    esTabJSX = (
	  <Tab
	    value="4"
		label={<Tooltip title="Example Sets">
                 <img src={icon_es} width={iconSize} height={iconSize} alt=""></img>
               </Tooltip>}
	  />
	);
    esTabPanelJSX = (<TabPanel value="4"> <AttributeDefinitionsList entityType={Constants.ExampleSetEntity} tabIndex="4" /> </TabPanel>);
  }
  
  let eiTabJSX = "";
  let eiTabPanelJSX = "";
  if (checkExampleItemsValidForCollection(currentCollection, params.collectionId)) {
	const icon_ei = contextIcon(Constants.ExampleItemEntity, currentCollection['Type'], currentCollection['HasSets'])
    eiTabJSX = (
	  <Tab
	    value="5"
		label={<Tooltip title="Example Items">
                 <img src={icon_ei} width={iconSize} height={iconSize} alt=""></img>
               </Tooltip>}
	  />
	);
    eiTabPanelJSX = (<TabPanel value="5"> <AttributeDefinitionsList entityType={Constants.ExampleItemEntity} tabIndex="5" /> </TabPanel>);
  }
  
  return (
    <Stack spacing={2}>
      <Grid container justifyContent="center">
        <h1>Attribute Definitions</h1>
	  </Grid>
      <Grid container justifyContent="center">
        <Box sx={{ width: '100%', typography: 'body1' }}>
          <TabContext value={tabValue}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleChange} centered>
				{collectionTabJSX}
                {csTabJSX}
                {ciTabJSX}
                {esTabJSX}
                {eiTabJSX}
              </TabList>
            </Box>
            {collectionTabPanelJSX}
			{csTabPanelJSX}
			{ciTabPanelJSX}
			{esTabPanelJSX}
			{eiTabPanelJSX}
          </TabContext>
        </Box>
	  </Grid>
	</Stack>
  );
}

export default AttributeDefinitionsPage;
