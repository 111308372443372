import React from "react";
import { useLocation, Link as RouterLink } from "react-router-dom";

import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";


function BreadcrumbBar() {
  let location = useLocation();
  const pathnames = location.pathname.split("/").filter(x => x);
  return (
	<Breadcrumbs aria-label="Breadcrumb">
	  <RouterLink color="inherit" to="/">
	    Home
	  </RouterLink>
	  {pathnames.map((value, index) => {
		const last = index === pathnames.length - 1;
		const to = `/${pathnames.slice(0, index + 1).join("/")}`;

		return last ? (
		  <Typography color="textPrimary" key={to}>
		    {value}
		  </Typography>
		  ) : (
		  <RouterLink color="inherit" to={to} key={to}>
		    {value}
		  </RouterLink>
		);
	  })}
	</Breadcrumbs>
  );
}

export default BreadcrumbBar;
