import React from 'react';

import TileGrid from '../components/TileGrid'


// What next
// Provide info on what to do to start using the application.

function HomeInfoNextSteps () {
  const content = [
    [
	  ["Register", "First step is to click on the Register link above to create your account and get started.\n-\nAll new users automatically get a 7 day free trial so you can make sure Your Collections Online is right for you before you subscribe.\n-\nRegistration requires minimal info (name and email) - no credit card needed until you choose to subscribe.", 425, ],
	],
	[
	  ["Login", "Once registered login to access the online portal from any internet connected device.\n-\nAnytime during or after the end of the trial period, you can start your subscription by providing your credit card for the initial and recurring payments.\n-\nCancel your subscription anytime with no further costs - continue to use what you've already paid for.", 425, ],
	],
	[
	  ["Support", "Use the extensive support information (FAQ's and How To's) in the portal to start creating Your Collections Online.\n-\nUse our support messaging system to raise any questions, suggestions or issues you may have for our support team.", 425, ],
	],
  ];
  return (
	<TileGrid content={content} />
  );
}

export default HomeInfoNextSteps;
