import React, { useState } from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import FAQItem from '../components/FAQItem'


function FAQSet ({faqItems}) {
  
  const [expanded, setExpanded] = useState(null);
  
  const handleChange = id => (_, isExpanded) => {
    // if expanded, set id to open/expand, close it otherwise 
    setExpanded(isExpanded ? id: false);
  };

  return (
    <Box sx={{ width: 550 }}>
	  <Stack spacing={1}>
	    {faqItems.map((item, i) => (
		  <FAQItem
		    key={item['id']}
		    item={item}
		    onChange={handleChange(item['id'])}
		    expanded={expanded === item['id']}
		  />
	    ))}
	  </Stack>
    </Box>
  );
}

export default FAQSet;
