import React from 'react';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';


function FAQItem({expanded, onChange, item}) {
  
  return (
    <Accordion
	  onChange={onChange}
      expanded={expanded}
      TransitionProps={{unmountOnExit: true}}
	>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
	    <Grid container justifyContent="space-between" alignItems="center" spacing={1}>
		  <Grid item>
	        <Typography>{item['title']}</Typography>
		  </Grid>
		</Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>{item['text']}</Typography>
      </AccordionDetails>
    </Accordion>
  );
}

export default FAQItem;
