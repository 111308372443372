import React, { useState, useEffect } from 'react';

import Card from '@mui/material/Card';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';


// props list:
//
// editMode
// attributeName
// attributeType - not used
// attributeValue
// attributeConfigInfo - not used
// attributeDescription
// attributeIsMandatory
// updatedValues
// setUpdatedValues
// updateAttributesInError
//

function FlagAttributeDisplay (props) {
  const [flagValue, setFlagValue] = useState(props.attributeValue);
  const [valueInError, setValueInError] = useState(true);
  
  // make sure valueInError is initialised correctly
  //
  // NOTE - props.updateAttributesInError should be in the dependency list
  //        but if do that then propogation of valueInError doesn't work
  //        fine when goes out of error, does nothing when goes back into error
  //
  useEffect(() => {
	if ((props.attributeIsMandatory === true) && (props.editMode === true)) {
	  const isInError = props.attributeValue.length === 0
	  setValueInError(isInError);
	  props.updateAttributesInError(isInError, props.attributeName);
	}
  }, [props.attributeIsMandatory, props.editMode, props.attributeValue /*, props.updateAttributesInError*/]);
  
  // call props.updateAttributesInError to update list when valueInError changes
  //
  useEffect(() => {
	if ((props.attributeIsMandatory === true) && (props.editMode === true)) {
	  props.updateAttributesInError(valueInError, props.attributeName);
	}
  }, [valueInError, props.attributeIsMandatory, props.editMode, props.updateAttributesInError]);
  
  //
  //
  const handleChange = (e, newValue) => {
	console.log("FlagAttributeDisplay - handleChange");
	console.log(e);
	console.log(newValue);
	console.log(props.editMode);
	
	let dupUpdatedValues = props.updatedValues;
	console.log(dupUpdatedValues[props.attributeName]);
	dupUpdatedValues[props.attributeName] = newValue;
	props.setUpdatedValues(dupUpdatedValues);
	
	setFlagValue(newValue);
	
	if ((props.attributeIsMandatory === true) && (props.editMode === true)) {
	  setValueInError(newValue.length === 0);
	}
  };

  console.log("FlagAttributeDisplay - rendering");
  console.log(props.attributeValue);
  console.log(flagValue);
  
  if (props.editMode === false) {
    return (
      <Tooltip arrow title={props.attributeDescription}>
	  <TextField
	    id={props.attributeName}
        label={props.attributeName}
        defaultValue={props.attributeValue}
        InputProps={{
          readOnly: true,
        }}
	    sx={{mb: 3}}
	    multiline
	    maxRows={4}
      />
      </Tooltip>
	)
  }
  
  // set up helper text if needed
  //
  let valueError = false;
  let nameRequired = false;
  let nameHelperText = "";
  let borderColour = '1px light grey';
  if (props.editMode === true) {
	nameRequired = props.attributeIsMandatory === true;
    if (nameRequired === true) {
	  valueError = valueInError
      if (valueError === true) {
	    nameHelperText = props.attributeName + " must be specified.";
	    borderColour = '2px solid red';
	  }
	}
  }
  
  // note that if here then editMode is True...
  //
  return (
    <Card variant="outlined" sx={{ border: borderColour }}>
    <FormControl variant="outlined" required={nameRequired} error={valueError} >
      <FormLabel id="demo-row-radio-buttons-group-label">{props.attributeName}</FormLabel>
      <Tooltip arrow title={props.attributeDescription}>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          value={flagValue}
		  onChange = {handleChange}
        >
          <FormControlLabel value="Yes" control={<Radio />} label="Yes" labelPlacement="start" />
          <FormControlLabel value="No" control={<Radio />} label="No" labelPlacement="start" />
        </RadioGroup>
	    <FormHelperText>{nameHelperText}</FormHelperText>
      </Tooltip>
    </FormControl>
	</Card>
  );
}

export default FlagAttributeDisplay;
