import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';

import callApi from '../api/api';
import { TokenContext } from '../auth/token'
import { ErrorListContext, logErrorString } from '../error/errorList'

// props:
//
//	jobName
//	paramsComponent
//	getParams()
//

function StandardReport(props) {

  const [token, setToken] = useContext(TokenContext);
  const [errorList, setErrorList] = useContext(ErrorListContext);

  const navigate = useNavigate();
  
  //
  //
  const submitHandler = async (event) => {
	console.log("StandardReport: submitHandler");
    event.preventDefault();
	
	// send request to create the report and start it off
	let data = {
	  "action": "create_job",
	  "payload": {
	    "job_name" : props.jobName,
		"job_params": props.getParams(),
	  }
	};
	console.log(data);
	
	const retVal = await callApi(token, setToken, data);
	console.log(retVal);
	logErrorString(retVal.errorMessage, errorList, setErrorList)
	
	const jobId = retVal.data['job_id']
	console.log(jobId);
	const started = retVal.data['started']
	console.log(started);
	
	if (jobId === null) {
	  console.log("FAILED TO CREATE - TODO ADD ALERT");
	} else {
	  navigate("/tasks/" + jobId);
	}
  };
  
  //
  //
  const cancelHandler = async (event) => {
	console.log("StandardReport: cancelHandler");
    event.preventDefault();
	
	navigate("/reports");
  };
  
  let ParamsTag = props.paramsComponent;
  
  return (
    <Grid container justifyContent="center">
      <Stack spacing={2}>
        <Grid container justifyContent="center">
          <h1>Your Reports</h1>
	    </Grid>
		<ParamsTag />
        <Grid container justifyContent="center">
          <Stack spacing={2} direction="row" >
		    <Button variant="contained" onClick={cancelHandler}>Cancel</Button>
		    <Button variant="contained" onClick={submitHandler}>Submit</Button>
		  </Stack>
	    </Grid>
	  </Stack>
	</Grid>
  );
}

export default StandardReport;
