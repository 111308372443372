import React from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';


function InformationPageControl ({pages, activeContentIndex, setActiveContentIndex}) {
  
  const previousArrow = " <==";
  const nextArrow = "==> ";
  
  return (
    <Stack direction="row" spacing={1} alignItems="center">
	  <Box
	    height={20}
	    width={300}
	  >
	    <Grid container justifyContent="right">
		  <Link
		    component="button"
		    variant="body1"
            disabled={activeContentIndex < 2}
		    onClick={() => setActiveContentIndex(activeContentIndex-1)}
		  >
		    {activeContentIndex >= 2 ? pages[activeContentIndex-2]['title'] : ""}
		  </Link>
		  {activeContentIndex === 1 ? "(start)" : ""}
		  {activeContentIndex === 0 ? "" : previousArrow}
	    </Grid>
	  </Box>		  
	  <Link
	    component="button"
	    variant="body1"
		onClick={() => setActiveContentIndex(0)}
	  >
	    <b>CONTENTS</b>
	  </Link>
	  <Box
	    height={20}
	    width={300}
	  >
	    <Grid container justifyContent="left">
		  {activeContentIndex === 0 ? "" : nextArrow}
		  {activeContentIndex === pages.length ? "(end)" : ""}
		  <Link
			  component="button"
			  variant="body1"
			  disabled={activeContentIndex >= pages.length}
			  onClick={() => setActiveContentIndex(activeContentIndex+1)}
		  >
		    {(activeContentIndex !== 0) && (activeContentIndex < pages.length) ? pages[activeContentIndex]['title'] : ""}
		  </Link>
	    </Grid>
	  </Box>		  
    </Stack>
  );
}

export default InformationPageControl;
