import React, { useContext } from 'react';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { contextIcon } from '../icons/icons'
import { CurrentCollectionContext } from '../context/currentCollection'


function EntityTitle({title, entityType, variant}) {
  
  const [currentCollection, /*setCurrentCollection*/] = useContext(CurrentCollectionContext);
  
  const collectionType = currentCollection['Type']
  const collectionHasSets = currentCollection['HasSets']
  const icon = contextIcon(entityType, collectionType, collectionHasSets)
  
  if (variant === undefined) {
	  variant = "h4";
  }
  
  return (
	<Stack alignItems="center" direction="row" gap={1}>
	  <Typography variant={variant} style={{ fontWeight: 600 }}>
	    {title}
	  </Typography>
	  <img
	    src={icon}
		width="40"
		height="40"
		alt=""
	  />
	</Stack>
  );
}

export default EntityTitle;
