import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import { DataGridPro as DataGrid } from '@mui/x-data-grid-pro';

import callApi from '../api/api';
import { tokenIsValid, TokenContext } from '../auth/token'
import { ErrorListContext, logErrorString } from '../error/errorList'

import { SubscriptionStatusContext, restrictPage } from '../context/subscriptionStatus'
import SubscriptionExpiredMessage from '../components/SubscriptionExpiredMessage'

import NotLoggedInMessage from '../components/NotLoggedInMessage'
import CustomColumnMenu from '../components/CustomColumnMenu'


function SupportErrorLogPage() {

  const [token, setToken] = useContext(TokenContext);
  const [errorList, setErrorList] = useContext(ErrorListContext);
  const [subscriptionStatus, /*setSubscriptionStatus*/] = useContext(SubscriptionStatusContext);
  
  const navigate = useNavigate();

  // do not allow to proceed if not logged in
  //
  if (!tokenIsValid(token)) {
    return <NotLoggedInMessage />;
  }
  // nor if subscription expired and page is restricted
  //
  if (restrictPage(subscriptionStatus, "SupportPage")) {
    return <SubscriptionExpiredMessage />;
  }
  
  //
  //
  const reportHandler = async (event) => {
	console.log("reportHandler");
    event.preventDefault();
	
	const description = errorList.map(
	  (errorItem) => {
	    return errorItem.timestamp + ":" + errorItem.text;
        }
	  ).join(",");
	console.log(description);
	
	// send request to create the collection to the backend
	let data = {
	  "action": "create_support_item",
	  "payload": {
	    "title" : "Error log reported by user",
        "description": description,
        "issue_type": "Problem",
	  }
	};
	
	const retVal = await callApi(token, setToken, data);
	console.log(retVal);
	logErrorString(retVal.errorMessage, errorList, setErrorList)
	
	let itemId = retVal.data['issue_id']
	console.log(itemId);
	
	navigate("/support/support_items/" + itemId);
  }
  
  //
  //
  const getRowId = (row) => {
	return row['timestamp'];
  }
  
  //
  //
  const columns = [
	{
	  field: 'timestamp',
	  headerName: 'Timestamp',
	  width: 200,
	  resizable: true,
	  headerClassName: 'datagrid-column-header'
	},
	{
	  field: 'text',
	  headerName: 'Error',
	  width: 400,
	  resizable: true,
	  headerClassName: 'datagrid-column-header'
	},
	// skip 'comment' field for now
  ];
  
  let dataGridList = [...errorList];
  if (dataGridList[0]['text'] === "") {
    // remove first entry as its a blank
    dataGridList.shift();
  }
  
  let reportButtonDisabled = true;
  console.log("errorList = " + errorList);
  console.log("errorList.length = " + errorList.length);
  if ((errorList.length > 1) ||
      (errorList[0]['text']) !== ""){
	reportButtonDisabled = false;
  }
  return (
    <Grid container justifyContent="center">
      <Stack spacing={2}>
        <Grid container justifyContent="center">
          <h1>Your Support - Error Log</h1>
	    </Grid>
        <Grid container justifyContent="center">
		  <Card variant="outlined">
            <Grid container justifyContent="center">
		      <p>Error Log</p>
            </Grid>
			<Grid container justifyContent="center">
			  <div style={{ height: 400, width: '100%' }}>
				<DataGrid
				  initialState={{
					pagination: {
					  paginationModel: {
						pageSize: 10,
					  }
					},
				  }}
				  slots={{ columnMenu: CustomColumnMenu }}
				  rows={dataGridList}
				  columns={columns}
				  getRowId={getRowId}
				  pageSize={3}
				  pagination
				  pageSizeOptions={
					[10, 20, 50, 100]
				  }
				  sx={{ bgcolor: '#ffffff' }}
				/>
			  </div>
			</Grid>
		  </Card>
	    </Grid>
        <Grid container justifyContent="center">
		  <Button variant="contained" disabled={reportButtonDisabled} type='submit' onClick={reportHandler}>Report issues</Button>
        </Grid>
	  </Stack>
	</Grid>
  );
}

export default SupportErrorLogPage;
