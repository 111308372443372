import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

// AlertDialog props
//
//	openState
//	setOpenState
//	showButtons
//	optionalDialog
//	actionFunction
//	title
//	text
//

function NullComponent() {
  return <></>;
};

function AlertDialog(props) {

  const handleClose = () => {
    props.setOpenState(false);
  };
  
  const handleActionAndClose = () => {
	if (props.actionFunction !== null) {
	  props.actionFunction();
	}
	props.setOpenState(false);
  };

  let buttonsJSX = ""
  if (props.showButtons === true) {
	buttonsJSX = (
	  <>
        <Button onClick={handleClose}> Cancel </Button>
        <Button onClick={handleActionAndClose} autoFocus> Confirm </Button>
	  </>
	);
  }
  
  let OptionalDialogTag = NullComponent;
  if (props.optionalDialog !== null) {
	OptionalDialogTag = props.optionalDialog;
  }
  
  return (
      <Dialog
        open={props.openState}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {props.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
		  {props.text}
          </DialogContentText>
		  <OptionalDialogTag />
        </DialogContent>
        <DialogActions>
		{buttonsJSX}
        </DialogActions>
      </Dialog>
  );
}

export default AlertDialog;
