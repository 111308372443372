import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { tokenIsValid, TokenContext } from '../auth/token';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import { SubscriptionStatusContext, restrictPage } from '../context/subscriptionStatus'
import SubscriptionExpiredMessage from '../components/SubscriptionExpiredMessage'

import NotLoggedInMessage from '../components/NotLoggedInMessage'


function CollectionsCreate() {

  const [newName, setNewName] = useState("");
  const [nameError, setNameError] = useState(true);
  const [optionValue, setOptionValue] = React.useState('scratch');
  
  const [token, /*setToken*/] = useContext(TokenContext);
  const [subscriptionStatus, /*setSubscriptionStatus*/] = useContext(SubscriptionStatusContext);
  
  const navigate = useNavigate();

  // do not allow to proceed if not logged in
  //
  if (!tokenIsValid(token)) {
    return <NotLoggedInMessage />;
  }
  // nor if subscription expired and page is restricted
  //
  if (restrictPage(subscriptionStatus, "CollectionsCreate")) {
    return <SubscriptionExpiredMessage />;
  }
  
  //
  //
  const nextHandler = async (event) => {
	console.log("nextHandler");
    event.preventDefault();
	
	const nameInfo = {state: { 'collectionName': newName}}
	
	if (optionValue === 'scratch') {
	  navigate('/collections/create/from_scratch', nameInfo );
	} else if (optionValue === 'template') {
	  navigate('/collections/create/from_template', nameInfo );
	} else if (optionValue === 'import') {
	  navigate('/collections/create/from_import', nameInfo );
	} else {
	  navigate('/collections/create/from_copy', nameInfo );
	}
  };
  
  //
  //
  const cancelHandler = (event) => {
	console.log("cancelHandler");
    event.preventDefault();
	navigate("/collections");
  };
  
  // set up helper text if needed
  //
  let nameRequired = true;
  let nameHelperText = "";
  if (nameError === true) {
	nameHelperText = "A unique name must be specified.";
  }
  
  return (
    <Grid container justifyContent="center">
      <Stack spacing={2}>
        <Grid container justifyContent="center">
		  <h1>Create Collection</h1>
		</Grid>
        <Grid container justifyContent="center">
	      <Card variant="outlined">
	        <Box m={2}>
		      <FormControl>
		        <Stack spacing={1} >
		          <TextField
			        id="Name"
			        label="Name"
			        defaultValue={newName}
			        required={nameRequired}
			        error={nameError}
			        helperText={nameHelperText}
			        sx={{mb: 3}}
			        multiline
			        maxRows={4}
			        onChange = {(e) => {
			          setNewName(e.target.value);
			          setNameError(e.target.value.length === 0);
			        }}
		          />
                  <FormLabel id="create-options-group">How do you want to create the new collection:</FormLabel>
                  <RadioGroup
			        aria-labelledby="create-options-group-label"
                    value={optionValue}
                    name="create-options-group"
			        onChange={(event) => {setOptionValue(event.target.value);}}
                  >
                    <FormControlLabel value="scratch" control={<Radio />} label="Create from scratch." />
                    <FormControlLabel value="template" control={<Radio />} label="Create from a pre-defined template." />
                    <FormControlLabel value="copy" control={<Radio />} label="Create a copy of existing collection." />
                    <FormControlLabel value="import" control={<Radio />} label="Import the collection." />
                  </RadioGroup>
		          <Stack direction='row' spacing={1} >
			        <Button variant="contained" type='submit' disabled={nameError} onClick={nextHandler}>Next</Button>
			        <Button variant="contained" type='submit' onClick={cancelHandler}>Cancel</Button>
		          </Stack>
		        </Stack>
              </FormControl>
		    </Box>
		  </Card>
	    </Grid>
      </Stack>
	</Grid>
  );
}

export default CollectionsCreate;
