import React, { useContext, useRef, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { tokenIsValid, TokenContext } from '../auth/token'
import Constants from '../Constants'
import {
  CurrentCollectionContext,
  checkCollectableSetsValidForCollection,
  checkCollectableItemsValidForCollection,
  checkExampleSetsValidForCollection
  } from '../context/currentCollection'

import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';

import { SubscriptionStatusContext, restrictPage } from '../context/subscriptionStatus'
import SubscriptionExpiredMessage from '../components/SubscriptionExpiredMessage'

import NotLoggedInMessage from '../components/NotLoggedInMessage'
import EntityDetailViewUpdate from '../components/EntityDetailViewUpdate'
import EntityTitle from '../components/EntityTitle'


function ExampleItemDetailPage(props) {

  const [token, /*setToken*/] = useContext(TokenContext);
  const [currentCollection, /*setCurrentCollection*/] = useContext(CurrentCollectionContext);
  const [subscriptionStatus, /*setSubscriptionStatus*/] = useContext(SubscriptionStatusContext);
  
  const params = useParams();
  const scrollToRef = useRef(0);
  const [refAquired, setRefAquired] = useState(false);

  useEffect(() => {
	console.log("ExampleItemDetailPage: useEffect - once rednering occurs");
    setRefAquired(true)
  }, []);
  
  useEffect(() => {
	// NOTE that this doesn't work
	// ==> scrollToRef.current === 0 whenever its called ???
	//
	console.log("ExampleItemDetailPage: useEffect - window scroll");
	console.log(scrollToRef.current);
	if (scrollToRef.current !== 0) {
	  console.log("ExampleItemDetailPage: useEffect - window scroll - DOING IT NOW...");
	  scrollToRef.current.scrollIntoView({behavior: "smooth"})
	}
  }, [refAquired]);

  // do not allow to proceed if not logged in
  //
  if (!tokenIsValid(token)) {
    return <NotLoggedInMessage />;
  }
  // nor if subscription expired and page is restricted
  //
  if (restrictPage(subscriptionStatus, "ExampleItemDetailPage")) {
    return <SubscriptionExpiredMessage />;
  }
 
  // set up variables for View mode
  //
  let headerText = "View";
  let primaryDisplayMode = Constants.DisplayModeView;
  //
  // or for Edit mode
  //
  if (props.editMode === true) {
	  headerText = "Update";
	  primaryDisplayMode = Constants.DisplayModeUpdate;
  }

  let collectableSetJSX = "";
  if (checkCollectableSetsValidForCollection(currentCollection, params.collectionId)) {
    collectableSetJSX = (
	  <EntityDetailViewUpdate
	    title="Collectable Set attributes:"
	    displayMode={Constants.DisplayModeReference}
	    entityId={params.exampleItemId}
	    collectionId={params.collectionId}
	    getDataActionName = "get_example_item_linked_cs"
	    saveDataActionName = {null}
	    baseUrlPath = {"/collections/"+params.collectionId+"/cs/"}
	  />
	);
  }

  let exampleSetJSX = "";
  if (checkExampleSetsValidForCollection(currentCollection, params.collectionId)) {
    exampleSetJSX = (
	  <EntityDetailViewUpdate
	    title="Example Set attributes:"
	    displayMode={Constants.DisplayModeReference}
	    entityId={params.exampleItemId}
	    collectionId={params.collectionId}
	    getDataActionName = "get_example_item_linked_es"
	    saveDataActionName = {null}
	    baseUrlPath = {"/collections/"+params.collectionId+"/es/"}
	  />
	);
  }

  let collectableItemJSX = "";
  if (checkCollectableItemsValidForCollection(currentCollection, params.collectionId)) {
    collectableItemJSX = (
	  <EntityDetailViewUpdate
	    title="Collectable Item attributes:"
	    displayMode={Constants.DisplayModeReference}
	    entityId={params.exampleItemId}
	    collectionId={params.collectionId}
	    getDataActionName = "get_example_item_linked_ci"
	    saveDataActionName = {null}
	    baseUrlPath = {"/collections/"+params.collectionId+"/ci/"}
	  />
	);
  }
  
  // user is logged in so proceed to display values
  //
  // CS => EntityDetailViewUpdate iff EI is part of an ES
  // ES => EntityDetailViewUpdate iff EI is part of an ES
  // CI => EntityDetailViewUpdate
  // EI => EntityDetailViewUpdate
  //
  console.log("ExampleItemDetailPage: abput to return JSX")
  return (
	<Stack spacing={2}>
      <Grid container justifyContent="center">
		<EntityTitle
		  title={headerText + " Example Item"}
		  entityType={Constants.ExampleItemEntity}
		  variant="h4"
		/>
	  </Grid>
      <Grid container justifyContent="center">
		<Stack spacing={2}>
		  {collectableSetJSX}
          {exampleSetJSX}
		  {collectableItemJSX}
	      <EntityDetailViewUpdate
		    ref={scrollToRef}
		    title="Example Item attributes:"
	        displayMode={primaryDisplayMode}
		    entityId={params.exampleItemId}
		    collectionId={params.collectionId}
		    getDataActionName = "get_example_item"
		    saveDataActionName = "update_example_item"
		    deleteActionName = "delete_example_item"
		    baseUrlPath = {"/collections/"+params.collectionId+"/ei/"}
	      />
		</Stack>
	  </Grid>
	</Stack>
  );
}

export default ExampleItemDetailPage;
