import axios from "axios";
import { apiConfig } from "../config/config"
import { getSession, refreshSession } from "../auth/auth";


async function callApiBase (token, data, contentType) {
    console.log("callApiBase: start");

    // set ID Token in "Authorization" header
    const headers = {
  	  'Content-Type': contentType,
      'Authorization': token
    };
	
	let retData = {
		data: [],
		isError: false,
		retry: false,
		errorMessage: "",
	};
	
	try {
	  const res = await axios.post(apiConfig.apiGatewayUrl, data, { headers: headers });
      retData.data = res.data;
	} catch (error) {
	  console.log(error);
	  if (error.response.data.message.includes("token has expired")) {
		  console.log("================== TOKEN EXPIRED =================");
		  retData.retry = true;
	  }
      retData.data = [];
	  retData.isError = true;
      retData.errorMessage = error.message;
    };
	
    console.log("callApiBase: end");
	return retData;
}



async function callApi (token, setToken, data, contentType='application/json') {
    console.log("callApi: start");
	
	// first attempt at base API call
	let retData = await callApiBase(token, data);
	
	if (retData.isError === true) {
		console.log("callApi: errored");
		
		// can we update token and retry?
		if (retData.retry === true) {
			console.log("callApi: retrying");
			
			// yes - so refresh token first
			try {
				const session1 = await getSession();
				console.log(session1)
				const result = await refreshSession();
				console.log(result);
				const session2 = await getSession();
				console.log(session2);
				const newToken = session2.idToken.jwtToken;
				setToken(newToken);
				
				// second attempt at the base API call
				retData = await callApiBase(newToken, data);
			} catch (error) {
			  console.log(error);
			  retData.data = [];
			  retData.isError = true;
			  retData.errorMessage = error.message;
			}
		}
	}
	
    console.log("callApi: end");
	return retData;
}

export default callApi;
