import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { tokenIsValid, TokenContext } from '../auth/token'

import { SubscriptionStatusContext, restrictPage } from '../context/subscriptionStatus'
import SubscriptionExpiredMessage from '../components/SubscriptionExpiredMessage'

import NotLoggedInMessage from '../components/NotLoggedInMessage'
import Tile from '../components/Tile'


function ReportsPage() {

  const [token, /*setToken*/] = useContext(TokenContext);
  const [subscriptionStatus, /*setSubscriptionStatus*/] = useContext(SubscriptionStatusContext);

  // do not allow to proceed if not logged in
  //
  if (!tokenIsValid(token)) {
    return <NotLoggedInMessage />;
  }
  // nor if subscription expired and page is restricted
  //
  if (restrictPage(subscriptionStatus, "ReportsPage")) {
    return <SubscriptionExpiredMessage />;
  }
  
  return (
    <Grid container justifyContent="center">
      <Stack spacing={2}>
        <Grid container justifyContent="center">
          <h1>Your Reports</h1>
	    </Grid>
        <Grid container justifyContent="center">
          <Stack direction="row" spacing={2}>
		    <Tile
		      title = "Collection Statistics"
			  content = "This report summarises the stats for all  collections that you have."
			  width = {200}
			  destination = "/reports/standard/collection_statistics"
			  imageUrl = {null}
			  imageAlt = {null}
		    />
		    <Tile
		      title = "Attribute Usage"
			  content = "This report provides a summary of how the attributes are defined for your collections."
			  width = {200}
			  destination = "/reports/standard/attribute_usage"
			  imageUrl = {null}
			 imageAlt = {null}
		    />
		  </Stack>
	    </Grid>
        <Grid container justifyContent="center">
			<p> </p>
			<Typography>
			  View previous reports in <Link to="/tasks">Tasks</Link>.
			</Typography>
	    </Grid>
	  </Stack>
	</Grid>
  );
}

export default ReportsPage;
