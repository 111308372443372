import React from 'react';

import TileGrid from '../components/TileGrid'


// What and Why
// Provide overview of the application.

function HomeInfoOverview () {
  
  const content = [
	[
	  ["Collections", "You can create and manage as many collections as you want - all in one place.", 200, ],
	  ["Collectables", "No restrictions of what type of collection - use Your Collections Online for anything that can be collected!", 200, ],
	  ["Templates", "You can start from scratch, or use one of our templates to get you started, making any changes you want.", 200, ],
	],
	[
	  ["Information", "Define exactly what you want to record, exactly the way you want, with an unlimited number of data fields.", 200, ],
	  ["Data Types", "Define data fields using a range of predefined types of information, with more being added all the time.", 200, ],
	  ["Images", "Upload an unlimited number of images per item, mutiple formats supported.", 200, ],
	],
	[
	  ["Support", "Make use of the extensive information and help to get the most out of Your Collections Online.", 200, ],
	  ["Easy Access", "Access anywhere over the internet from any internet connected device, or download a copy anytime.", 200, ],
	  ["Security", "We recognise that it's your data so it's securely stored using industry standards.", 200, ],
	],
	/*
	  ["Sets", "Store additional information for sets as well as the items within.", 200, ],
	*/
  ];
  
  return (
	<TileGrid content={content} />
  );
}

export default HomeInfoOverview;
