import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { changePassword } from "../auth/auth";
import { tokenIsValid, TokenContext } from '../auth/token'
import { ErrorListContext, logErrorString } from '../error/errorList'

import { SubscriptionStatusContext, restrictPage } from '../context/subscriptionStatus'
import SubscriptionExpiredMessage from '../components/SubscriptionExpiredMessage'

import NotLoggedInMessage from '../components/NotLoggedInMessage'

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import AlertDialog from '../components/AlertDialog'


function ChangePasswordPage() {

  const [currentPassword, setCurrentPassword] = useState('');
  const [enteredPassword, setEnteredPassword] = useState('');
  const [enteredPassword2, setEnteredPassword2] = useState('');
  const [success, setSuccess] = useState(false);
  const [currentPasswordError, setCurrentPasswordError] = useState(true);
  const [enteredPasswordError, setEnteredPasswordError] = useState(true);
  const [enteredPasswordSame, setEnteredPasswordSame] = useState(false);
  const [enteredPassword2Missing, setEnteredPassword2Missing] = useState(true);
  const [enteredPassword2Mismatched, setEnteredPassword2Mismatched] = useState(true);
  const [waitDialogOpenState, setWaitDialogOpenState] = useState(false);

  const [token, /*setToken*/] = useContext(TokenContext);
  const [errorList, setErrorList] = useContext(ErrorListContext);
  const [subscriptionStatus, /*setSubscriptionStatus*/] = useContext(SubscriptionStatusContext);

  const navigate = useNavigate();

  if (!tokenIsValid(token)) {
    return <NotLoggedInMessage />;
  }
  // nor if subscription expired and page is restricted
  //
  if (restrictPage(subscriptionStatus, "ChangePasswordPage")) {
    return <SubscriptionExpiredMessage />;
  }
  
  const submitHandler = async (event) => {
    event.preventDefault();
	logErrorString("", errorList, setErrorList)

    try {
		// pull in userName and currentPassword from state passed to us by login page
        setWaitDialogOpenState(true);
		await changePassword(currentPassword, enteredPassword);
        setWaitDialogOpenState(false);
		setSuccess(true);
	} catch (err) {
        setWaitDialogOpenState(false);
		logErrorString(err.message, errorList, setErrorList)
    }
  };
  
  const cancelHandler = async (event) => {
    event.preventDefault();
	logErrorString("", errorList, setErrorList)
	setSuccess(true);
  }
  
  if (success) {
    navigate("/account");
  }
  
  // set up helper text if needed
  //
  let currentPasswordHelperText = "";
  if (currentPasswordError === true) {
	currentPasswordHelperText = "Current password must be specified.";
  }
  let enteredPasswordHelperText = "";
  if (enteredPasswordError === true) {
	enteredPasswordHelperText = "A new password must be specified.";
  } else if (enteredPasswordSame === true) {
	enteredPasswordHelperText = "The new password must not be the same as the old one.";
  }
  let enteredPassword2HelperText = "";
  if (enteredPassword2Missing === true) {
	enteredPassword2HelperText = "Please re-enter the new password.";
  } else if (enteredPassword2Mismatched === true) {
	enteredPassword2HelperText = "The new passwords must match.";
  }
  
  const confirmDisabled = (
    currentPasswordError || enteredPasswordError || enteredPasswordSame ||
	enteredPassword2Missing || enteredPassword2Mismatched
	);

  return (
    <Grid container justifyContent="center">
      <Stack spacing={2}>
        <Grid container justifyContent="center">
          <h1>Your Account</h1>
		</Grid>
        <Grid container justifyContent="center">
	      <Card variant="outlined"  sx={{ width: 300 }}>
	        <Box m={2}>
              <form>
			    <Typography>
				  Please enter both your current password and your new one.
			    </Typography>
				<p> </p>
		        <Stack spacing={1} >
				  <TextField
				    id="PasswordCurrent"
				    label="Current Password"
				    defaultValue={currentPassword}
					type="password"
			        required={true}
			        error={currentPasswordError}
			        helperText={currentPasswordHelperText}
				    onChange = {(e) => {
					  setCurrentPassword(e.target.value);
			          setCurrentPasswordError(e.target.value.length === 0);
			          setEnteredPasswordSame(e.target.value === enteredPassword);
				    }}
				  />
				  <TextField
				    id="Password"
				    label="New Password"
				    defaultValue={enteredPassword}
					type="password"
			        required={true}
			        error={enteredPasswordError || enteredPasswordSame}
			        helperText={enteredPasswordHelperText}
				    onChange = {(e) => {
					  setEnteredPassword(e.target.value);
			          setEnteredPasswordError(e.target.value.length === 0);
			          setEnteredPasswordSame(e.target.value === currentPassword);
			          setEnteredPassword2Mismatched(e.target.value !== enteredPassword2);
				    }}
				  />
				  <TextField
				    id="Password2"
				    label="Re-enter New Password"
				    defaultValue={enteredPassword2}
					type="password"
			        required={true}
			        error={enteredPassword2Missing || enteredPassword2Mismatched}
			        helperText={enteredPassword2HelperText}
				    onChange = {(e) => {
					  setEnteredPassword2(e.target.value);
			          setEnteredPassword2Missing(e.target.value.length === 0);
			          setEnteredPassword2Mismatched(e.target.value !== enteredPassword);
				    }}
				  />
				  <Grid container justifyContent="center">
                    <Stack direction='row' spacing={1} >
				      <Button variant="contained" type='submit' onClick={cancelHandler}>Cancel</Button>
				      <Button variant="contained" type='submit' disabled={confirmDisabled} onClick={submitHandler}>Confirm</Button>
		            </Stack>
				  </Grid>
  				</Stack>
              </form>
		    </Box>
	      </Card>
	    </Grid>
      </Stack>
	  <AlertDialog
	    openState={waitDialogOpenState}
	    setOpenState={setWaitDialogOpenState}
	    showButtons={false}
	    optionalDialog={null}
	    actionFunction={null}
	    title=""
	    text="Please wait..."
	  />
	</Grid>
  );
};

export default ChangePasswordPage;
