import React, { /*useState,*/ useContext } from 'react';
import { useParams, useNavigate } from "react-router-dom"

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';

import callApi from '../api/api';
import { tokenIsValid, TokenContext } from '../auth/token'
import { ErrorListContext, logErrorString } from '../error/errorList'
import Constants from '../Constants'

import { SubscriptionStatusContext, restrictPage } from '../context/subscriptionStatus'
import SubscriptionExpiredMessage from '../components/SubscriptionExpiredMessage'

import NotLoggedInMessage from '../components/NotLoggedInMessage'
import EntityDetailViewUpdate from '../components/EntityDetailViewUpdate'
import CollectionStats from '../components/CollectionStats'
import EntityTitle from '../components/EntityTitle'


function CollectionDetailPage(props) {
  //const [url, /*setUrl*/] = useState("");
  
  const [token, setToken] = useContext(TokenContext);
  const [errorList, setErrorList] = useContext(ErrorListContext);
  const [subscriptionStatus, /*setSubscriptionStatus*/] = useContext(SubscriptionStatusContext);
  
  const params = useParams();
  
  const navigate = useNavigate();
  
  // do not allow to proceed if not logged in
  //
  if (!tokenIsValid(token)) {
    return <NotLoggedInMessage />;
  }
  // nor if subscription expired and page is restricted
  //
  if (restrictPage(subscriptionStatus, "CollectionDetailPage")) {
    return <SubscriptionExpiredMessage />;
  }
  
  /*
  //
  //
  const dialogAction = () => {
	window.open(url, "_blank");
  };
  */
  
  //
  //
  const exportCollectionHandler = async (event) => {
	console.log("exportCollectionHandler");
    event.preventDefault();
	
	const data = {
	  "action": "create_job",
	  "payload": {
	    "job_name" : "export",
		"job_params": {
		  'id': params.collectionId,
		}
	  }
	};
	
	const retVal = await callApi(token, setToken, data);
	console.log(retVal.data);
	logErrorString(retVal.errorMessage, errorList, setErrorList)
	
	const jobId = retVal.data['job_id']
	console.log(jobId);
	const started = retVal.data['started']
	console.log(started);
	
	if (jobId === null) {
	  console.log("FAILED TO CREATE - TODO ADD ALERT");
	} else {
	  navigate("/tasks/" + jobId);
	}
  };
  
  // set up variables for View mode
  //
  let headerText = "View";
  let collectionStatsJSX = <CollectionStats />
  let exportButtonJSX = <Button variant="contained" onClick={exportCollectionHandler}>Export Collection</Button>
  let primaryDisplayMode = Constants.DisplayModeView;
  //
  // or for Edit mode
  //
  if (props.editMode === true) {
	  headerText = "Update";
	  collectionStatsJSX = ""
	  exportButtonJSX = ""
	  primaryDisplayMode = Constants.DisplayModeUpdate;
  }
  
  // user is logged in so proceed to DisplayNames
  //
  // note that collecitonId prop to EntityDetailViewUpdate deliberately set to "null"
  //
  return (
	<div>
		<Stack spacing={2}>
          <Grid container justifyContent="center">
		    <EntityTitle
			  title={headerText+" Collection"}
			  entityType={Constants.CollectionEntity}
			/>
		  </Grid>
          <Grid container justifyContent="center">
		    <EntityDetailViewUpdate
			  title="Collection attributes:"
		      displayMode={primaryDisplayMode}
			  entityId={params.collectionId}
			  collectionId = {null}
			  getDataActionName = "get_collection"
			  saveDataActionName = "update_collection"
			  deleteActionName = "delete_collection"
			  baseUrlPath = "/collections/"
			  attrsToRemove = {['Type', 'HasSets']}
		    />
		  </Grid>
          <Grid container justifyContent="center">
		    {collectionStatsJSX}
		  </Grid>
          <Grid container justifyContent="center">
		    {exportButtonJSX}
	      </Grid>
		</Stack>
	</div>
  );
}

export default CollectionDetailPage;
