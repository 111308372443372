import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { tokenIsValid, TokenContext } from '../auth/token'
import Constants from '../Constants'
import {
  CurrentCollectionContext,
  checkCollectableSetsValidForCollection,
  checkExampleItemsValidForCollection,
  } from '../context/currentCollection'

import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';

import { SubscriptionStatusContext, restrictPage } from '../context/subscriptionStatus'
import SubscriptionExpiredMessage from '../components/SubscriptionExpiredMessage'

import NotLoggedInMessage from '../components/NotLoggedInMessage'
import EntityDetailViewUpdate from '../components/EntityDetailViewUpdate'
import EntityList from '../components/EntityList'
import EntityTitle from '../components/EntityTitle'


function ExampleSetDetailPage(props) {

  const [token, /*setToken*/] = useContext(TokenContext);
  const [currentCollection, /*setCurrentCollection*/] = useContext(CurrentCollectionContext);
  const [subscriptionStatus, /*setSubscriptionStatus*/] = useContext(SubscriptionStatusContext);
  
  const params = useParams();

  // do not allow to proceed if not logged in
  //
  if (!tokenIsValid(token)) {
    return <NotLoggedInMessage />;
  }
  // nor if subscription expired and page is restricted
  //
  if (restrictPage(subscriptionStatus, "ExampleSetDetailPage")) {
    return <SubscriptionExpiredMessage />;
  }
 
  // set up variables for View mode
  //
  let headerText = "View";
  let primaryDisplayMode = Constants.DisplayModeView;
  //
  // or for Edit mode
  //
  if (props.editMode === true) {
	  headerText = "Update";
	  primaryDisplayMode = Constants.DisplayModeUpdate;
  }

  let collectableSetJSX = "";
  if (checkCollectableSetsValidForCollection(currentCollection, params.collectionId)) {
    collectableSetJSX = (
	  <EntityDetailViewUpdate
		title="Collectable Set attributes:"
		displayMode={Constants.DisplayModeReference}
		entityId={params.exampleSetId}
		collectionId={params.collectionId}
		getDataActionName = "get_example_set_linked_cs"
		saveDataActionName = {null}
		baseUrlPath = {"/collections/"+params.collectionId+"/cs/"}
	  />
	);
  }
  
  let exampleItemJSX = "";
  if (checkExampleItemsValidForCollection(currentCollection, params.collectionId)) {
    exampleItemJSX = (
	  <Grid container justifyContent="center">
	    <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
		  <EntityTitle
		    title="Example Items"
		    entityType={Constants.ExampleItemEntity}
			variant="h5"
		  />
		</Stack>
		<EntityList
		  entityType="EIforES"
		  title=""
		  getDataAction="get_example_set_linked_ei_list"
		  entityId={params.exampleSetId}
		  collectionId={params.collectionId}
		  baseUrlPath={"/collections/" + params.collectionId + "/ei/"}
		  height={275}
		  enableCheckboxSelection={false}
		/>
	  </Grid>
	);
  }

  // user is logged in so proceed to display values
  //
  // CS => EntityDetailViewUpdate </p>
  // ES => EntityDetailViewUpdate </p>
  // EI => EntityList </p>
  //
  return (
	<Stack spacing={2}>
      <Grid container justifyContent="center">
		<EntityTitle
		  title={headerText + " Example Set"}
		  entityType={Constants.ExampleSetEntity}
		  variant="h4"
		/>
	  </Grid>
      <Grid container justifyContent="center">
		<Stack spacing={2}>
		{collectableSetJSX}
	    <EntityDetailViewUpdate
		  title="Example Set attributes:"
	      displayMode={primaryDisplayMode}
		  entityId={params.exampleSetId}
		  collectionId={params.collectionId}
		  getDataActionName = "get_example_set"
		  saveDataActionName = "update_example_set"
		  deleteActionName = "delete_example_set"
		  baseUrlPath = {"/collections/"+params.collectionId+"/es/"}
	    />
	    </Stack>
	  </Grid>
	  {exampleItemJSX}
	</Stack>
  );
}

export default ExampleSetDetailPage;
