import React, { useContext } from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { tokenIsValid, TokenContext } from '../auth/token'

import { SubscriptionStatusContext, restrictPage } from '../context/subscriptionStatus'
import SubscriptionExpiredMessage from '../components/SubscriptionExpiredMessage'

import NotLoggedInMessage from '../components/NotLoggedInMessage'

import StandardReport from '../components/StandardReport'
import StyledCard from '../components/StyledCard'


function TestReportParams() {
  return (
    <StyledCard>
      <Grid container justifyContent="center">
		<Typography>
		  This Test report needs no params - just click on 'Submit' button.
		</Typography>
	  </Grid>
	  <br/>
      <Grid container justifyContent="center">
		<Typography>
		  Or click on 'Cancel' to go back to list of report options.
		</Typography>
	    <p></p>
	  </Grid>
	</StyledCard>
  );
}


function TestReport() {

  const [token, /*setToken*/] = useContext(TokenContext);
  const [subscriptionStatus, /*setSubscriptionStatus*/] = useContext(SubscriptionStatusContext);
  
  // do not allow to proceed if not logged in
  //
  if (!tokenIsValid(token)) {
    return <NotLoggedInMessage />;
  }
  // nor if subscription expired and page is restricted
  //
  if (restrictPage(subscriptionStatus, "TestReport")) {
    return <SubscriptionExpiredMessage />;
  }
  
  const getParams = () => {
	console.log("TestReport: getParams");
	
	return {
      "wait_time": 20,
    };
  }
  
  return (
    <StandardReport 
	  jobName = "dummy_job"
	  paramsComponent = {TestReportParams}
	  getParams = {getParams}
	/>
  );
}

export default TestReport;
