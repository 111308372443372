
class Constants {
	static get DisplayModeView() { return 0; }
	static get DisplayModeUpdate() { return 1; }
	static get DisplayModeReference() { return 2; }
	static get DisplayModeCreate() { return 3; }
	
	static get CollectionEntity() { return 0; }
	static get CollectableSetEntity() { return 1; }
	static get CollectableItemEntity() { return 2; }
	static get CIinCSEntityType() {return 3; }
	static get ExampleSetEntity() { return 4; }
	static get ExampleItemEntity() { return 5; }
	
	static EntityTypeName(entityType) {
		const EntityTypeNames = ['Collection', 'Collectable Sets', 'Collectable Items', 'CI_in_CS', 'Example Sets', 'Example Items'];
		if (entityType < 0 || entityType > 5) {
			return '(invalid)';
		}
		return EntityTypeNames[entityType];
	}
	
	static CollectionTypeName(collectionType) {
		const CollectionTypeNames = ['(unused)', 'Catlog', 'Planned', 'Ad-Hoc'];
		if (collectionType < 1 || collectionType > 3) {
			return '(invalid)';
		}
		return CollectionTypeNames[collectionType];
	}
}

export default Constants;