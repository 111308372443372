import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';

import { Elements } from '@stripe/react-stripe-js';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';

import { stripePromise } from '../StripeConstants'
import { tokenIsValid, TokenContext } from '../auth/token'
import { ErrorListContext, logErrorString } from '../error/errorList'

import { SubscriptionStatusContext, restrictPage } from '../context/subscriptionStatus'
import SubscriptionExpiredMessage from '../components/SubscriptionExpiredMessage'

import NotLoggedInMessage from '../components/NotLoggedInMessage'


function SetupPayment() {
  
  const stripe = useStripe();
  const elements = useElements();
  
  const [errorList, setErrorList] = useContext(ErrorListContext);
  
  //
  //
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded - disable form submission until it has.
      return null;
    }
	
    const {error} = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.origin.toString()}/account/payment_complete`
      },
    });

    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
	  logErrorString(error.message, errorList, setErrorList)
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };
  
  return (
    <Grid container justifyContent="center">
      <Stack spacing={2}>
        <Grid container justifyContent="center">
          <h1>Your Account</h1>
	    </Grid>
        <Grid container justifyContent="center">
	      <Card variant="outlined"  sx={{ width: 350 }}>
	        <Box m={2}>
			  <Stack spacing={2}>
				<p>Please enter your payment details below:</p>
			    <form>
				  <PaymentElement />
				  <Grid container justifyContent="center">
				    <Button variant="contained" disabled={!stripe} onClick={handleSubmit}>Submit</Button>
				  </Grid>
			    </form>
			  </Stack>
		    </Box>
	      </Card>
	    </Grid>
	  </Stack>
	</Grid>
  );
}


function AccountPaymentPage() {
  console.log("AccountPaymentPage");

  const [token, /*setToken*/] = useContext(TokenContext);
  const [subscriptionStatus, /*setSubscriptionStatus*/] = useContext(SubscriptionStatusContext);

  const { state } = useLocation();
  
  if (!tokenIsValid(token)) {
    return <NotLoggedInMessage />;
  }
  // nor if subscription expired and page is restricted
  //
  if (restrictPage(subscriptionStatus, "AccountPaymentPage")) {
    return <SubscriptionExpiredMessage />;
  }
  
  const paymentId = state.paymentId;
  const options = {
    // passing the SetupIntent's client secret
    clientSecret: paymentId,
    // Fully customizable with appearance API.
    appearance: {/*...*/},
  };
    
  return (
	<Elements stripe={stripePromise} options={options}>
	  <SetupPayment />
	</Elements>
  );
}

export default AccountPaymentPage;
