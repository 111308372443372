import React from 'react';

import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import StyledCard from '../components/StyledCard'

// configuration components
//
import TextAttributeConfiguration from './TextAttributeConfiguration'
import DateAttributeConfiguration from './DateAttributeConfiguration'
import NumberAttributeConfiguration from './NumberAttributeConfiguration'
//import DecimalAttributeConfiguration from './DecimalAttributeConfiguration'
import DropdownAttributeConfiguration from './DropdownAttributeConfiguration'
//import YearAttributeConfiguration from './YearAttributeConfiguration'
//import CurrencyAttributeConfiguration from './CurrencyAttributeConfiguration'
import FlagAttributeConfiguration from './FlagAttributeConfiguration'
import ImageAttributeConfiguration from './ImageAttributeConfiguration'

// display components
//
import TextAttributeDisplay from './TextAttributeDisplay'
import DateAttributeDisplay from './DateAttributeDisplay'
import NumberAttributeDisplay from './NumberAttributeDisplay'
//import DecimalAttributeDisplay from './DecimalAttributeDisplay'
import DropdownAttributeDisplay from './DropdownAttributeDisplay'
//import YearAttributeDisplay from './YearAttributeDisplay'
//import CurrencyAttributeDisplay from './CurrencyAttributeDisplay'
import FlagAttributeDisplay from './FlagAttributeDisplay'
import ImageAttributeDisplay from './ImageAttributeDisplay'


//
// This list used to control which attribute types can be created
//  - comment out here those that are not yet supported
//
export let validAttributeTypes = {
	'Text'     : [TextAttributeConfiguration, TextAttributeDisplay],
	'Date'     : [DateAttributeConfiguration, DateAttributeDisplay],
	'Number'   : [NumberAttributeConfiguration, NumberAttributeDisplay],
//	'Decimal'  : [DecimalAttributeConfiguration, DecimalAttributeDisplay],
	'Dropdown' : [DropdownAttributeConfiguration, DropdownAttributeDisplay],
//	'Year'     : [YearAttributeConfiguration, YearAttributeDisplay],
//	'Currency' : [CurrencyAttributeConfiguration, CurrencyAttributeDisplay],
	'Flag'     : [FlagAttributeConfiguration, FlagAttributeDisplay],
	'Image'    : [ImageAttributeConfiguration, ImageAttributeDisplay],
};


function AttributeConfiguration (props) {
  
  console.log ("AttributeConfiguration: started");
  console.log (props.editMode);
  console.log (props.attributeType);
  console.log (props.defaultConfigInfo);
  
  let configJSX = <Typography style={{ fontWeight: 600 }} > ERROR: unsupported attribute type! </Typography>;
  
  if (props.attributeType in validAttributeTypes) {
	let Tag = validAttributeTypes[props.attributeType][0]
	configJSX = <Tag {...props} />
  }
  
  return (
	<Tooltip arrow title="Specify any type specific configuration for this attribute. Note that not all attribute types need any configuration.">
    <StyledCard variant="outlined" sx={{ border: '1px solid black', textAlign: 'left' }}>
	  <Typography style={{ fontWeight: 600 }}>
	    {props.attributeType}
	  </Typography>
      {configJSX}
	</StyledCard>
	</Tooltip>
  );
}

export default AttributeConfiguration;
