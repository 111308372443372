import React from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import attribute_1 from '../support_data/attribute_1.png'
import attribute_2 from '../support_data/attribute_2.png'


function SupportReferenceAttrViewPage() {
  return (
	<Box sx={{ width: 550 }}>
	  <Typography>
	    To see the attributes already defined go into the details page for the collection. To get there, first  click on the collection in the Your Collections page and then click on view for that collection and you will see this page.
	  </Typography>
	  <br />
      <Grid container justifyContent="center">
        <img src={attribute_1} width="459" height="562" alt="" />
	  </Grid>
	  <br/>
	  <Typography>
	    At the bottom of this page, just above the Export Collection button, you’ll see Attributes. Click on that and then click on View to get to the attribute definition page.
	  </Typography>
	  <br/>
      <Grid container justifyContent="center">
        <img src={attribute_2} width="472" height="253" alt="" />
	  </Grid>
	  <br/>
	  <Typography>
		Initially there may be no attributes defined at all (if you created the collection from scratch) or there may be some attributes already defined (if you used any of the other three methods to create your collection).
		<br/><br/>In all cases you can add, update, or delete attributes.
		<br/><br/>First you need to select the entity type that you want to change. Click on one of the context icons to select the entity you want to add, update or delete attributes for. From left to right these represent Collection, Collectable Sets, Collectable Items, Example Sets and Example Items.
		<br/><br/>Note that only those applicable to the collection type and whether the collection supports sets will be presented, so you may see less than what is shown above.
	  </Typography>
	  <br/><br/>
	</Box>
  );
};

export default SupportReferenceAttrViewPage;
