import React from 'react';

import Typography from '@mui/material/Typography';

import { validAttributeTypes } from './AttributeConfiguration'


function AttributeDisplay (props) {
  
  let configJSX = <Typography style={{ fontWeight: 600 }} > ERROR: unsupported attribute type! </Typography>;
  
  if (props.attributeType in validAttributeTypes) {
	let Tag = validAttributeTypes[props.attributeType][1]
	configJSX = <Tag {...props} />
  }
  
  return (
    <>
      {configJSX}
	</>
  );
}

export default AttributeDisplay;
