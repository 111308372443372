import React, { useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import Constants from '../Constants'

import { tokenIsValid, TokenContext } from '../auth/token'
import {
  CurrentCollectionContext,
  checkCollectableItemsValidForCollection,
  } from '../context/currentCollection'

import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

import { SubscriptionStatusContext, restrictPage } from '../context/subscriptionStatus'
import SubscriptionExpiredMessage from '../components/SubscriptionExpiredMessage'

import NotLoggedInMessage from '../components/NotLoggedInMessage'
import EntityList from '../components/EntityList'
import EntityTitle from '../components/EntityTitle'


function ExampleItemsPage() {

  const [token, /*setToken*/] = useContext(TokenContext);
  const [currentCollection, /*setCurrentCollection*/] = useContext(CurrentCollectionContext);
  const [subscriptionStatus, /*setSubscriptionStatus*/] = useContext(SubscriptionStatusContext);

  const navigate = useNavigate();
  
  const params = useParams();

  // do not allow to proceed if not logged in
  //
  if (!tokenIsValid(token)) {
    return <NotLoggedInMessage />;
  }
  // nor if subscription expired and page is restricted
  //
  if (restrictPage(subscriptionStatus, "ExampleItemsPage")) {
    return <SubscriptionExpiredMessage />;
  }
  
  //
  //
  const createExampleItemHandler = async (event) => {
	console.log("createExampleItemHandler");
    event.preventDefault();
	
	navigate("/collections/" + params.collectionId + "/ei/create");
  };
  
  let createExampleItemJSX = ""
  if (!checkCollectableItemsValidForCollection(currentCollection, params.collectionId)) {
	createExampleItemJSX = (
	  <Button variant="contained" onClick={createExampleItemHandler}>Create</Button>
	);
  }
  
  const baseUrlPath = "/collections/" + params.collectionId + "/ei/"
  return (
	<div>
        <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
		  <EntityTitle
			title={"Example Items"}
			entityType={Constants.ExampleItemEntity}
			variant="h4"
		  />
		  {createExampleItemJSX}
        </Stack>
		<EntityList
		  entityType="EIforC"
		  title=""
		  getDataAction="get_example_item_list"
		  entityId={params.collectionId}
          collectionId={params.collectionId}
		  baseUrlPath={baseUrlPath}
		  height={700}
		  enableCheckboxSelection={false}
		/>
	</div>
  );
}

export default ExampleItemsPage;
