import React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';


function SupportReferenceManageCSPage() {
  return (
	<Box sx={{ width: 550 }}>
	  <Typography>
		When you click on an entry in the list of Collectable Sets the page to view the individual Collectable Set is displayed. This page consists of the following elements:
		<ul><li>A block showing the attributes for the Collectable Set, with buttons to be able to edit or delete it
		</li><li>A table showing the list of Collectable Items that make up this Collectable Set, with a button to go to a new page to change that list
		</li><li>A second table showing the list of Example Sets that are examples in your collection of this Collectable Set, with a button to allow you to create a new one
		</li></ul>
		NOTE – to create a new Collectable Set go back to the list of all Collectable Sets for this Collection and click on the create button just above the table.
		<br/><br/>If you click on the button to Change the Collectable Items, then you are taken to a new page to do that.
		<br/><br/>On that page there are the following elements:
		<ul><li>Table at the top of the page listing the Collectable Items currently in the Collectable Set, any or all of which can be selected
		</li><li>Table at the bottom of the page listing all the Collectable Items defined in your Collection, any or all of which can be selected
		</li><li>"Add" button to add the Collectable Items selected in the lower table into the upper table, therefore adding them into the set of Collectable Items that make up the Collectable Set
		</li><li>"Remove" button to take Collectable Items selected in the upper table out of the set of Collectable Items that make up the Collectable Set
		</li><li>"Done" button that takes you back to the page viewing the Collectable Set.
		</li></ul>
		<u>Warning</u> - any changes you make to the Collectable Set in this page are immediate and happen as soon as you click on the Add or Remove button.
		<br/><br/>If you click on the button to Create an Example Set, then you are taken to a new page which is in the form of a multi-page wizard to guide you through the process.
		<br/><br/>The steps in the wizard within a Planned or Catalog Collection are as follows:
		<ul><li>Enter the attributes for the new Example Set to be created
		</li><li>Select which Example Items to create – this will be based on the list of Collectable Items that make up the Collectable Set – generally you would select all (default) but if you have a partial set you would only select some.
		</li><li>Set the attributes to be used for all the Example Items that are also about to be created – you can change the values for individual Example Items after the Example Set is created if you need to
		</li><li>Final step that confirms that the Example Set (and the Example Items belonging to it) have been created.
		</li></ul>
		The steps in the wizard within an Ad-Hoc Collection are slightly different and as follows:
		<ul><li>Enter the attributes for the new Example Set to be created
		</li><li>Create each Example Item with the Example Set by filling in the attributes to be used for each Example Items and clicking on Create. Once all Example Items are created click on the Next button instead
		</li><li>Final step that confirms that the Example Set (and the Example Items belonging to it) have been created.
		</li></ul>
	  </Typography>
	  <br/><br/>
	</Box>
  );
};

export default SupportReferenceManageCSPage;
