import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';

import { tokenIsValid, TokenContext } from '../auth/token'

import Constants from '../Constants'
import {
  CurrentCollectionContext,
  checkCollectableItemsValidForCollection,
  } from '../context/currentCollection'

import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';

import { SubscriptionStatusContext, restrictPage } from '../context/subscriptionStatus'
import SubscriptionExpiredMessage from '../components/SubscriptionExpiredMessage'

import NotLoggedInMessage from '../components/NotLoggedInMessage'
import EntityDetailViewUpdate from '../components/EntityDetailViewUpdate'
import EntityTitle from '../components/EntityTitle'


function ExampleItemCreatePage(props) {

  const [token, /*setToken*/] = useContext(TokenContext);
  const [currentCollection, /*setCurrentCollection*/] = useContext(CurrentCollectionContext);
  const [subscriptionStatus, /*setSubscriptionStatus*/] = useContext(SubscriptionStatusContext);

  const params = useParams();

  // do not allow to proceed if not logged in
  //
  if (!tokenIsValid(token)) {
    return <NotLoggedInMessage />;
  }
  // nor if subscription expired and page is restricted
  //
  if (restrictPage(subscriptionStatus, "ExampleItemCreatePage")) {
    return <SubscriptionExpiredMessage />;
  }

  let collectableItemJSX = "";
  if (checkCollectableItemsValidForCollection(currentCollection, params.collectionId)) {
	// NOTE - this won't get here if not appropriate even without testing props.isAdHoc
    collectableItemJSX = (
	  <EntityDetailViewUpdate
	    title="Collectable Item attributes:"
	    displayMode={Constants.DisplayModeReference}
	    entityId={params.collectableItemId}
	    collectionId={params.collectionId}
	    getDataActionName = "get_collectable_item"
	    saveDataActionName = {null}
	    baseUrlPath = {"/collections/"+params.collectionId+"/ci/"}
	  />
	);
  }
  
  const ciId = props.isAdHoc ? null : {
	"collectable_item_id": params.collectableItemId,
  };
  
  // user is logged in so proceed to display values
  //
  // CI => EntityDetailViewUpdate
  // EI => EntityDetailViewUpdate </p>
  //
  return (
	<Stack spacing={2}>
      <Grid container justifyContent="center">
		<EntityTitle
		  title="Create Example Item"
		  entityType={Constants.ExampleItemEntity}
		  variant="h4"
		/>
	  </Grid>
      <Grid container justifyContent="center">
		<Stack spacing={2}>
		  {collectableItemJSX}
	      <EntityDetailViewUpdate
		    title="Example Item attributes:"
	        displayMode={Constants.DisplayModeCreate}
		    entityId={''}
		    collectionId={params.collectionId}
		    additionalEntityId={ciId}
		    getDataActionName = "get_example_item"
		    saveDataActionName = "create_example_item"
		    deleteActionName = {null}
		    baseUrlPath = {"/collections/"+params.collectionId+"/ei/"}
	      />
	    </Stack>
	  </Grid>
	</Stack>
  );
}

export default ExampleItemCreatePage;
