import React from 'react';

import { GridColumnMenu  } from '@mui/x-data-grid-pro'


function CustomColumnMenu(props) {
  return (
    <GridColumnMenu
      {...props}
      slots={{
        // Hide `columnMenuColumnsItem`
        columnMenuColumnsItem: null,
      }}
    />
  );
}

export default CustomColumnMenu;
