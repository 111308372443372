import React, { useContext, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import Constants from '../Constants'

import callApi from '../api/api';
import { tokenIsValid, TokenContext } from '../auth/token'
import { ErrorListContext, logErrorString } from '../error/errorList'
import { ReloadDataContext, triggerDataReload } from '../context/reloadData'

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';

import { SubscriptionStatusContext, restrictPage } from '../context/subscriptionStatus'
import SubscriptionExpiredMessage from '../components/SubscriptionExpiredMessage'

import NotLoggedInMessage from '../components/NotLoggedInMessage'
import EntityList from '../components/EntityList'
import EntityTitle from '../components/EntityTitle'


function CollectableSetChangeItemsPage(props) {

  const [itemsToAddList, setItemsToAddList] = useState([]);
  const [itemsToRemoveList, setItemsToRemoveList] = useState([]);

  const [token, setToken] = useContext(TokenContext);
  const [errorList, setErrorList] = useContext(ErrorListContext);
  const [reloadData, setReloadData] = useContext(ReloadDataContext);
  const [subscriptionStatus, /*setSubscriptionStatus*/] = useContext(SubscriptionStatusContext);

  const navigate = useNavigate();
  
  const params = useParams();

  if (!tokenIsValid(token)) {
    return <NotLoggedInMessage />;
  }
  // nor if subscription expired and page is restricted
  //
  if (restrictPage(subscriptionStatus, "CollectableSetChangeItemsPage")) {
    return <SubscriptionExpiredMessage />;
  }
  
  const addHandler = async (event) => {
	console.log("addHandler: " + itemsToAddList);
    event.preventDefault();
	
	// check if have any to add - do nothing if not
	if (itemsToAddList.length === 0) {
      return;
	}
	
	let listOfItems = itemsToAddList.map( (itemInfo) => {
		return {'id' : itemInfo};
	});
	
	// send request to create the example set to the backend
	let data = {
	  "action": "add_collectable_items_to_set",
	  "payload": {
		  "collection_id": params.collectionId,
		  "collectable_set_id": params.collectableSetId,
		  "collectable_items": listOfItems
	  }
	};
	console.log(data);
	
	const retVal = await callApi(token, setToken, data);
	console.log(retVal);
	logErrorString(retVal.errorMessage, errorList, setErrorList)
	
	// clear list
	setItemsToAddList([]);
    
    // trigger reload of data from the database
    triggerDataReload(reloadData, setReloadData);
  };
  
  const removeHandler = async (event) => {
	console.log("removeHandler: " + itemsToRemoveList);
    event.preventDefault();
	
	// check if have any to add - do nothing if not
	if (itemsToRemoveList.length === 0) {
      return;
	}
	
	let listOfItems = itemsToRemoveList.map( (itemInfo) => {
		let str = itemInfo.split("_");
		return {'id' : str[0], 'sequence': str[1]};
	});
	
	// send request to create the example set to the backend
	let data = {
	  "action": "remove_collectable_items_from_set",
	  "payload": {
		  "collection_id": params.collectionId,
		  "collectable_set_id": params.collectableSetId,
		  "collectable_items": listOfItems
	  }
	};
	console.log(data);
	
	const retVal = await callApi(token, setToken, data);
	console.log(retVal);
	logErrorString(retVal.errorMessage, errorList, setErrorList)
	
	// clear list
	setItemsToRemoveList([]);
    
    // trigger reload of data from the database
    triggerDataReload(reloadData, setReloadData);
  };
  
  const doneHandler = async (event) => {
	console.log("doneHandler");
    event.preventDefault();
	
	navigate("/collections/" + params.collectionId + "/cs/" + params.collectableSetId);
  };
 
  
  // user is logged in so proceed to display values
  //
  return (
	<Stack spacing={2}>
      <Grid container justifyContent="center">
		<EntityTitle
		  title="Collectable Set - change items"
		  entityType={Constants.CollectableSetEntity}
		  variant="h4"
		/>
	  </Grid>
      <Grid container justifyContent="center">
	    <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
		  <EntityTitle
		    title="Current Collectable Items"
		    entityType={Constants.CollectableItemEntity}
		    variant="h5"
		  />
		</Stack>
		<EntityList
		  entityType="CIforCS"
		  title=""
		  getDataAction="get_collectable_set_linked_ci_list"
		  entityId={params.collectableSetId}
		  collectionId={params.collectionId}
		  baseUrlPath={null}
		  height={275}
		  enableCheckboxSelection={true}
		  setSelectedItemsList={setItemsToRemoveList}
	    />
	  </Grid>
	    <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
		  <Button
		    variant="contained"
			startIcon={<KeyboardDoubleArrowUpIcon/>}
			onClick={addHandler}
		  >
            Add
          </Button>
		  <Button
		    variant="contained"
			onClick={doneHandler}
		  >
            Done
          </Button>
		  <Button
		    variant="contained"
			startIcon={<KeyboardDoubleArrowDownIcon />}
			onClick={removeHandler}
		  >
            Remove
          </Button>
		</Stack>
      <Grid container justifyContent="center">
        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
		  <EntityTitle
		    title="Available Collectable Items"
		    entityType={Constants.CollectableItemEntity}
		    variant="h5"
		  />
        </Stack>
		<EntityList
		  entityType="CIforC"
		  title=""
		  getDataAction="get_collectable_item_list"
		  entityId={params.collectionId}
          collectionId={params.collectionId}
		  baseUrlPath={null}
		  height={275}
		  enableCheckboxSelection={true}
		  setSelectedItemsList={setItemsToAddList}
		/>
	  </Grid>
	</Stack>
  );
}

export default CollectableSetChangeItemsPage;
