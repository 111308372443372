import React, { useContext, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import Constants from '../Constants'
import callApi from '../api/api';
import { tokenIsValid, TokenContext } from '../auth/token'
import { ErrorListContext, logErrorString } from '../error/errorList'
import { ReloadDataContext, triggerDataReload } from '../context/reloadData'

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { SubscriptionStatusContext, restrictPage } from '../context/subscriptionStatus'
import SubscriptionExpiredMessage from '../components/SubscriptionExpiredMessage'

import NotLoggedInMessage from '../components/NotLoggedInMessage'
import EntityDetailViewUpdate from '../components/EntityDetailViewUpdate'
import EntityList from '../components/EntityList'
import Wizard from '../components/Wizard'
import EntityTitle from '../components/EntityTitle'


function ExampleSetCreatePage(props) {

  const [itemsToAddList, setItemsToAddList] = useState([]);
  const [exampleItemsCreated, setExampleItemsCreated] = useState([]);
  const [updatedESValues, setUpdatedESValues] = useState({});
  const [updatedEIValues, setUpdatedEIValues] = useState({});
  const [exampleSetId, setExampleSetId] = useState(0);
  
  const [token, setToken] = useContext(TokenContext);
  const [errorList, setErrorList] = useContext(ErrorListContext);
  const [reloadData, setReloadData] = useContext(ReloadDataContext);
  const [subscriptionStatus, /*setSubscriptionStatus*/] = useContext(SubscriptionStatusContext);
  
  const params = useParams();

  const navigate = useNavigate();

  // do not allow to proceed if not logged in
  //
  if (!tokenIsValid(token)) {
    return <NotLoggedInMessage />;
  }
  // nor if subscription expired and page is restricted
  //
  if (restrictPage(subscriptionStatus, "ExampleSetCreatePage")) {
    return <SubscriptionExpiredMessage />;
  }
   
  //
  //
  const saveExampleSetAttributes = async () => {
	console.log("saveExampleSetAttributes");
    console.log(updatedESValues);
	
	// add in id for the entity to be updated
	updatedESValues['collectable_set_id'] = params.collectableSetId;
	updatedESValues["collection_id"] = params.collectionId;
	console.log(updatedESValues);
	
	// send request to update the entity to the backend
	let data = {
	  "action": 'create_example_set',
	  "payload": updatedESValues
	};
	
	const retVal = await callApi(token, setToken, data);
	console.log(retVal)
	logErrorString(retVal.errorMessage, errorList, setErrorList)
	
	let newExampleSetId = retVal.data['id']
	console.log(newExampleSetId);
	setExampleSetId(newExampleSetId);
    
    // trigger reload of data from the database
    triggerDataReload(reloadData, setReloadData);
  };
  
  //
  //
  const createExampleItems = async () => {
	console.log("createExampleItems");
	console.log(itemsToAddList);
	
	// Create the EI's using the CI ID's contained in "itemsToAddList"
	let collectable_items_list = itemsToAddList.map(item => {
	  let parts = item.split("_");
	  return {'id': parts[0], 'sequence': parts[1]};
	});
	
	let data = {
	  "action": "create_example_items_for_set",
	  "payload": {
		  "collection_id": params.collectionId,
		  "example_set_id": exampleSetId,
		  "collectable_items": collectable_items_list
	  }
	};
	console.log(data);
	
	const retVal = await callApi(token, setToken, data);
	console.log(retVal);
	logErrorString(retVal.errorMessage, errorList, setErrorList)
	
	setExampleItemsCreated(retVal.data)
	console.log(retVal.data);
      
    // trigger reload of data from the database
    triggerDataReload(reloadData, setReloadData);
}
   
  //
  //
  const saveExampleItemAttributes = async () => {
	console.log("saveExampleItemAttributes");
    console.log(updatedEIValues);
	
	// add in id for the entity to be updated
	updatedEIValues["collection_id"] = params.collectionId;
	updatedEIValues["example_item_ids"] = exampleItemsCreated;
	console.log(updatedEIValues);
	
	// send request to update the entity to the backend
	let data = {
	  "action": 'update_example_items_bulk',
	  "payload": updatedEIValues
	};
	console.log(data);
	
	const retVal = await callApi(token, setToken, data);
	console.log(retVal)
	logErrorString(retVal.errorMessage, errorList, setErrorList)
    
    // trigger reload of data from the database
    triggerDataReload(reloadData, setReloadData);
  };
  
  //
  //
  const step0JSX = () => {
	return (
	  <EntityDetailViewUpdate
	    title="Example Set attributes:"
	    displayMode={Constants.DisplayModeCreate}
	    entityId={""}
	    collectionId={params.collectionId}
	    getDataActionName = "get_example_set"
	    saveDataActionName = {null}
	    deleteActionName = {null}
	    baseUrlPath = {null}
	    setParentUpdatedValues = {setUpdatedESValues}
		noRemainFlag = {true}
		noCancelButton = {true}
	  />
	);
  }
  
  //
  //
  const step1JSX = () => {
	return (
	  <EntityList
	    entityType="ESforC"
		title=""
	    getDataAction="get_example_set_linked_ci_list"
	    entityId={exampleSetId}
	    collectionId={params.collectionId}
	    baseUrlPath={null}
	    height={275}
	    enableCheckboxSelection={true}
	    setSelectedItemsList={setItemsToAddList}
	    initialSelectItems={true}
	  />
	);
  }
  
  //
  //
  const step2JSX = () => {
	return (
	  <EntityDetailViewUpdate
	   title="Example Item attributes:"
	   displayMode={Constants.DisplayModeCreate}
	   entityId={exampleItemsCreated[0]}
	   collectionId={params.collectionId}
	   getDataActionName = "get_example_item"
	   saveDataActionName = {null}
	   deleteActionName = {null}
	   baseUrlPath = {null}
	   setParentUpdatedValues = {setUpdatedEIValues}
	   noRemainFlag = {true}
	   noCancelButton = {true}
	  />
	);
  }
  
  //
  //
  const handleGotoExampleSet = () => {
	navigate("/collections/" + params.collectionId + "/es/" + exampleSetId);
  }
  
  //
  //
  const step3JSX = () => {
	return (
	  <Stack spacing={2}>
	    <Grid container justifyContent="center">
          <Typography> </Typography>
	    </Grid>
	    <Grid container justifyContent="center">
		  <Typography>All done!</Typography>
	    </Grid>
	    <Button
		  variant="contained"
		  onClick={handleGotoExampleSet}
	    >
		  View Example Set
	    </Button>
      </Stack>
	);
  }
  
  const stepTitles = [
    'Set attributes for Example Set',
    'Select which Example Items to create',
    'Set default attributes for Example Items created',
    'Finished',
  ];
  const actionFunctions = [
    saveExampleSetAttributes, createExampleItems, saveExampleItemAttributes,
  ]
  const stepContentFunctions = [
    step0JSX, step1JSX, step2JSX, step3JSX,
  ]
  
  return (
	<Stack spacing={2}>
      <Grid container justifyContent="center">
		<EntityTitle
		  title="Create Example Set"
		  entityType={Constants.ExampleSetEntity}
		  variant="h4"
		/>
	  </Grid>
	  <Wizard
	    stepTitles={stepTitles}
	    actionFunctions={actionFunctions}
		stepContentFunctions={stepContentFunctions}
	  />
    </Stack>
  );
}

export default ExampleSetCreatePage;
