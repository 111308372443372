import React from 'react';

import Typography from '@mui/material/Typography';


function NumberAttributeConfiguration (props) {
	// for Number we ignore the props.editMode passed in
	return (
	  <Typography>
	    No configuration required for Number attributes.
	  </Typography>
	);
}

export default NumberAttributeConfiguration;
