import React, { useState, useContext, useEffect } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';

import { signIn, isNewPasswordRequired, getSession, forgotPassword } from "../auth/auth";
import { TokenContext, setStayLoggedIn } from '../auth/token'
import { ErrorListContext, logErrorString } from '../error/errorList'

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import GenericPageHeader from '../components/GenericPageHeader'
import AlertDialog from '../components/AlertDialog'


function LoginPage() {

  const { state } = useLocation();

  const [enteredEmail, setEnteredEmail] = useState("");
  const [enteredPassword, setEnteredPassword] = useState('');
  const [/*loginSuccess*/, setLoginSuccess] = useState(false);
  const [forgotPasswordStarted, setForgotPasswordStarted] = useState(false);
  const [userAttr, setUserAttr] = useState(null);
  const [enteredEmailError, setEnteredEmailError] = useState(true);
  const [enteredPasswordError, setEnteredPasswordError] = useState(true);
  const [message, setMessage] = useState("");
  const [stayLoggedInFlag, setStayLoggedInFlag] = useState(true);
  const [waitDialogOpenState, setWaitDialogOpenState] = useState(false);
  
  const [/*token*/, setToken] = useContext(TokenContext);
  const [errorList, setErrorList] = useContext(ErrorListContext);

  const navigate = useNavigate();

  useEffect(() => {
    if (state !== null) {
	  setEnteredEmail(state.email);
	  setMessage(state.message);
    }
  }, []); // NOTE - don't add "state" to this dependency - ignore the LINT warning for this.

  const loginHandler = async (event) => {
    event.preventDefault();
	logErrorString("", errorList, setErrorList)
	
	setStayLoggedIn(stayLoggedInFlag);
	
	let loggedInOK = false;
	
    try {
      setWaitDialogOpenState(true);
      const result = await signIn(enteredEmail, enteredPassword);
	  if (isNewPasswordRequired(result)) {
		console.log(result)
		setUserAttr(result);
	  } else {
	    const session = await getSession();
		console.log(session)
	    setToken(session.idToken.jwtToken);

	    setLoginSuccess(true);
		loggedInOK = true;
	  }
      setWaitDialogOpenState(false);
    } catch (err) {
	  logErrorString(err.message, errorList, setErrorList)
      setWaitDialogOpenState(false);
    }
	
    setEnteredPassword('');
	
	if (loggedInOK) {
	  //
	  // do the navigate within the handler as not working outside
	  // (has correct URL but screen shown is stil the login screen)
      //
	  navigate("/collections", { state: { 'fromLogin': true } } );
	}
  };

  const forgotPasswordHandler = async (event) => {
    event.preventDefault();
	logErrorString("", errorList, setErrorList)

    try {
      setWaitDialogOpenState(true);
      await forgotPassword(enteredEmail);
      setWaitDialogOpenState(false);
	  
	  setForgotPasswordStarted(true);
    } catch (err) {
	  logErrorString(err.message, errorList, setErrorList)
      setWaitDialogOpenState(false);
    }
	
    setEnteredPassword('');
  };
  
  /*
  if (loginSuccess) {
	//
	// do in loginHandler() instead - see above for why
	//
    navigate("/collections", { state: { 'fromLogin': true } } );
  }
  */
  
  if (forgotPasswordStarted) {
    navigate("/reset-password");
  }
  
  if (userAttr !== null) {
    navigate("/require-password", { state: { 'userName': enteredEmail, 'currentPassword': enteredPassword } } );
  }
  
  // set up helper text if needed
  //
  let enteredEmailHelperText = "";
  if (enteredEmailError === true) {
	enteredEmailHelperText = "An email must be specified.";
  }
  let enteredPasswordHelperText = "";
  if (enteredPasswordError === true) {
	enteredPasswordHelperText = "A password must be specified.";
  }
  
  const loginDisabled = (enteredEmailError || enteredPasswordError);
  const forgotDisabled = (enteredEmailError);
  
  // set up message JSX if there is one
  //
  let messageJSX = "";
  if (message !== "") {
	messageJSX = (
	  <>
	    <Typography>
	      {message}
	    </Typography>
	    <p> </p>
	  </>
	);
  }

  return (
    <Grid container justifyContent="center">
      <Stack spacing={2}>
        <Grid container justifyContent="center">
	      <GenericPageHeader />
		</Grid>
        <Grid container justifyContent="center">
	      <Card variant="outlined"  sx={{ width: 350 }}>
	        <Box m={2}>
              <form>
			    {messageJSX}
		        <Stack spacing={1} >
				  <TextField
				    id="Email"
				    label="Email"
				    defaultValue={enteredEmail}
			        required={true}
			        error={enteredEmailError}
			        helperText={enteredEmailHelperText}
				    onChange = {(e) => {
					  setEnteredEmail(e.target.value);
			          setEnteredEmailError(e.target.value.length === 0);
				    }}
				  />
				  <TextField
				    id="Password"
				    label="Password"
				    defaultValue={enteredPassword}
					type="password"
			        required={true}
			        error={enteredPasswordError}
			        helperText={enteredPasswordHelperText}
				    onChange = {(e) => {
					  setEnteredPassword(e.target.value);
			          setEnteredPasswordError(e.target.value.length === 0);
				    }}
				  />
				  <Grid container justifyContent="center">
				    <Stack direction="row" spacing={0} alignItems="centre">
					  <Checkbox
					    checked={stayLoggedInFlag}
                        onChange={(e) => {
					      setStayLoggedInFlag(e.target.checked);
				          }}
					  />
				      <p>
					    Stay logged in
					  </p>
				    </Stack>
				  </Grid>
				  <Grid container justifyContent="center">
				    <Stack spacing={1}>
				      <Button variant="contained" type='submit' disabled={loginDisabled} onClick={loginHandler}>Login</Button>
				      <Button variant="contained" type='submit' disabled={forgotDisabled} onClick={forgotPasswordHandler}>Forgot Password</Button>
  				    </Stack>
		          </Grid>
  				</Stack>
				<p> </p>
			    <Typography>
				  Click <Link to="/reset-password">here</Link> if you want to complete a password reset using a code you have been sent.
			    </Typography>
              </form>
		    </Box>
	      </Card>
	    </Grid>
      </Stack>
	  <AlertDialog
	    openState={waitDialogOpenState}
	    setOpenState={setWaitDialogOpenState}
	    showButtons={false}
	    optionalDialog={null}
	    actionFunction={null}
	    title=""
	    text="Please wait..."
	  />
	</Grid>
  );
};

export default LoginPage;
