import React, { useState, useEffect } from 'react';

//import TextField from '@mui/material/TextField';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Tooltip from '@mui/material/Tooltip';


// props list:
//
// editMode
// attributeName
// attributeType - not used
// attributeValue
// attributeConfigInfo - not used
// attributeDescription
// attributeIsMandatory
// updatedValues
// setUpdatedValues
// updateAttributesInError
//

function DateAttributeDisplay (props) {
  const [valueInError, setValueInError] = useState(true);
  
  console.log("DateAttributeDisplay - start")
  
  // make sure valueInError is initialised correctly
  //
  // NOTE - props.updateAttributesInError should be in the dependency list
  //        but if do that then propogation of valueInError doesn't work
  //        fine when goes out of error, does nothing when goes back into error
  //
  useEffect(() => {
	if ((props.attributeIsMandatory === true) && (props.editMode === true)) {
	  const isInError = props.attributeValue.length === 0
	  setValueInError(isInError);
	  props.updateAttributesInError(isInError, props.attributeName);
	}
  }, [props.attributeIsMandatory, props.editMode, props.attributeValue /*, props.updateAttributesInError*/]);
  
  // call props.updateAttributesInError to update list when valueInError changes
  //
  useEffect(() => {
	if ((props.attributeIsMandatory === true) && (props.editMode === true)) {
	  props.updateAttributesInError(valueInError, props.attributeName);
	}
  }, [valueInError, props.attributeIsMandatory, props.editMode, props.updateAttributesInError]);

  //
  //
  const saveNewValue = (newValue) => {
	let dupUpdatedValues = props.updatedValues;
	dupUpdatedValues[props.attributeName] = newValue;
	props.setUpdatedValues(dupUpdatedValues);
	if ((props.attributeIsMandatory === true) && (props.editMode === true)) {
	  setValueInError(newValue.length === 0);
	}
  }
  
  //
  //
  const handleChange = (e) => {
	console.log("NumberAttributeDisplay - handleChange");
	if (e != null) {
	  saveNewValue(e.format('MM/YY/YYYY'));
	}
  }
  
  //
  //
  const handleCleared = () => {
	console.log("NumberAttributeDisplay - handleCleared");
	saveNewValue("");
  }
  
  // set up helper text if needed
  //
  let valueError = false;
  let nameRequired = false;
  let nameHelperText = "";
  if (props.editMode === true) {
	nameRequired = props.attributeIsMandatory === true;
    if (nameRequired === true) {
	  valueError = valueInError
      if (valueError === true) {
	    nameHelperText = props.attributeName + " must be specified.";
	  }
	}
  }
  
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
    <Tooltip arrow title={props.attributeDescription}>
      <DatePicker
	    id={props.attributeName}
        label={props.attributeName}
        defaultValue={dayjs(props.attributeValue)}
		readOnly={(props.editMode === false)}
	    onChange = {handleChange}
		slotProps={{
          field: { clearable: true, onClear: handleCleared },
		  textField: {
		    required : nameRequired,
		    error : valueError,
		    helperText : nameHelperText,
		  },
        }}
      />
    </Tooltip>
	</LocalizationProvider>
  );
}

export default DateAttributeDisplay;
