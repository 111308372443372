import React from "react"
import { useLocation } from "react-router-dom"
import ReactGA from 'react-ga4';

import { analyticsConfig } from "../config/config"


function usePageTracking() {
  const location = useLocation()

  React.useEffect(() => {
    ReactGA.initialize(analyticsConfig.trackingId)
  }, [])

  React.useEffect(() => {
    const currentPath = location.pathname + location.search
    // Send pageview with a custom path	
    ReactGA.send({ hitType: "pageview", page: currentPath, title: currentPath });
  }, [location])
}

export default usePageTracking;
