import Card from '@mui/material/Card';

import { styled } from '@mui/material/styles';


// NOTE - removed following line from below
//  textAlign: 'center',

const StyledCard = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
}));

export default StyledCard;
