import React, { createContext, useContext } from 'react';

import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';


//
//
//
export const ReasonsContext = createContext();


//
//
//
function ReasonsDialog() {
  
  const [setReasonChoice, setReasonComment] = useContext(ReasonsContext);
  
  const reasons = [
    "",
	"It's too expensive",
	"It's too difficult to use",
	"There aren't enough features",
	"It's not suitable for my needs",
	"There's a better app that I'd rather use",
	"Other (please comment below)",
	"Prefer not to say"
  ];
  
  return (
    <Grid container justifyContent="center">
      <Stack spacing={2}>
	  <Grid />
	  <Grid />
	  <FormControl sx={{ m: 1, minWidth: 400 }}>
	    <InputLabel id="cancel-reason-label">Tell us why you are cancelling...</InputLabel>
	    <Select
		  labelId="cancel-reason-label"
		  id="cancel-reason"
		  defaultValue={reasons[0]}
		  onChange = {(e) => {
		    setReasonChoice(e.target.value);
		    }}
		  label="Tell us why you are cancelling..."
	    >
		  {reasons.map((reason, i) => (
		    <MenuItem value={reason}>{reason}</MenuItem>
		  ))}
	    </Select>
	  </FormControl>
	  <TextField
	    id='cancel-comment'
	    label='Please add any comments...'
	    defaultValue={""}
	    sx={{mb: 3}}
	    multiline
	    minRows={2}
	    maxRows={4}
	    onChange = {(e) => {
		  setReasonComment(e.target.value);
		}}
	  />
      </Stack>
	</Grid>
  );
}

export default ReasonsDialog;
