
import Constants from '../Constants'


import icon_invalid from '../icons/invalid.png';
//import icon_missing from '../icons/missing.png';
import icon_bad_combo from '../icons/bad_combo.png';
import icon_n_a from '../icons/not_applicable.png';

import icon_psg from '../icons/psg.png';
import icon_psc from '../icons/psc.png';
import icon_pscs from '../icons/pscs.png';
import icon_psci from '../icons/psci.png';
import icon_pses from '../icons/pses.png';
import icon_psei from '../icons/psei.png';

import icon_pnsg from '../icons/pnsg.png';
import icon_pnsc from '../icons/pnsc.png';
import icon_pnsci from '../icons/pnsci.png';
import icon_pnsei from '../icons/pnsei.png';

import icon_csg from '../icons/csg.png';
import icon_csc from '../icons/csc.png';
import icon_cscs from '../icons/cscs.png';
import icon_csci from '../icons/csci.png';

import icon_cnsg from '../icons/cnsg.png';
import icon_cnsc from '../icons/cnsc.png';
import icon_cnsci from '../icons/cnsci.png';

import icon_asg from '../icons/asg.png';
import icon_asc from '../icons/asc.png';
import icon_ases from '../icons/ases.png';
import icon_asei from '../icons/asei.png';

import icon_ansg from '../icons/ansg.png';
import icon_ansc from '../icons/ansc.png';
import icon_ansei from '../icons/ansei.png';


// Set up lookup tables
//
// "entityLookup"
// dimension 1 - entity type
// dimension 2 - collection type ["Planned", "Catalog", "Ad-Hoc"]
// dimension 3 - has sets flag [true, false]

const entityLookup = [
	[//CollectionEntity
		[// Planned
			icon_pnsc,
			icon_psc,
		],
		[//Catalog
			icon_cnsc,
			icon_csc,
		], 
		[//Ad-Hoc
			icon_ansc,
			icon_asc,
		]
	],
	[//CollectableSetEntity
		[//Planned
			icon_bad_combo,
			icon_pscs,
		],
		[//Catalog
			icon_bad_combo,
			icon_cscs,
		],
		[//Ad-Hoc
			icon_bad_combo,
			icon_bad_combo,
		]
	],
	[//CollectableItemEntity
		[//Planned
			icon_pnsci,
			icon_psci,
		],
		[//Catalog
			icon_cnsci,
			icon_csci,
		],
		[//Ad-Hoc
			icon_bad_combo,
			icon_bad_combo,
		]
	],
	[//CIinCSEntityType
	],
	[//ExampleSetEntity
		[//Planned
			icon_bad_combo,
			icon_pses,
		],
		[//Catalog
			icon_bad_combo,
			icon_bad_combo,
		],
		[//Ad-Hoc
			icon_bad_combo,
			icon_ases,
		]
	],
	[//ExampleItemEntity
		[//Planned
			icon_pnsei,
			icon_psei,
		],
		[//Catalog
			icon_bad_combo,
			icon_bad_combo,
		],
		[//Ad-Hoc
			icon_ansei,
			icon_asei,
		]
	],
];

//
// "collectionLookup"
// dimension 1 - collection type ["Planned", "Catalog", "Ad-Hoc"]
// dimension 2 - has sets flag [true, false]

const collectionLookup = [
	[// Planned
		icon_pnsg,
		icon_psg,
	],
	[//Catalog
		icon_cnsg,
		icon_csg,
	], 
	[//Ad-Hoc
		icon_ansg,
		icon_asg,
	]
];


export function contextIcon(entityType, collectionType, hasSets) {
	
	const validEntityTypes = [
		Constants.CollectionEntity, Constants.CollectableSetEntity, Constants.CollectableItemEntity,
		Constants.CIinCSEntityType, Constants.ExampleSetEntity, Constants.ExampleItemEntity
	];
	const validCollectionTypes = ["Planned", "Catalog", "Ad-Hoc"];
	const validHasSets = [false, true];
	
	// some validation first
	if (   (!validEntityTypes.includes(entityType))
		|| (!validCollectionTypes.includes(collectionType))
		|| (!validHasSets.includes(hasSets)) ) {
		return icon_invalid;
	}
	
	// convert inputs into indexes into the lookup table
	const matchCollectionType = (element) => element === collectionType;
	const collectionTypeIdx = validCollectionTypes.findIndex(matchCollectionType);
	const hasSetsIdx = (hasSets === false) ? 0 : 1;
	
	const icon = entityLookup[entityType][collectionTypeIdx][hasSetsIdx];
	return icon;
};


export function collectionIcon(collectionType, hasSets) {
	
	const validCollectionTypes = ["Planned", "Catalog", "Ad-Hoc"];
	const validHasSets = [0, 1];
	
	// some validation first
	if (   (!validCollectionTypes.includes(collectionType))
		|| (!validHasSets.includes(hasSets)) ) {
		return icon_invalid;
	}
	
	// convert inputs into indexes into the lookup table
	const matchCollectionType = (element) => element === collectionType;
	const collectionTypeIdx = validCollectionTypes.findIndex(matchCollectionType);
	const hasSetsIdx = hasSets;
	
	const icon = collectionLookup[collectionTypeIdx][hasSetsIdx];
	return icon;
};


export const allIconsLookup = [
  [	//Planned - Sets
	icon_psg,
	icon_psc,
	icon_pscs,
	icon_psci,
	icon_pses,
	icon_psei,
  ],
  [	//Planned - No Sets
	icon_pnsg,
	icon_pnsc,
	icon_n_a,
	icon_pnsci,
	icon_n_a,
	icon_pnsei,
  ],
  [ //Catalog - Sets
    icon_csg,
    icon_csc,
    icon_cscs,
    icon_csci,
    icon_n_a,
    icon_n_a,
  ],
  [ //Catalog - Sets
    icon_cnsg,
    icon_cnsc,
    icon_n_a,
    icon_cnsci,
    icon_n_a,
    icon_n_a,
  ],
  [ //Ad-hoc - Sets
    icon_asg,
    icon_asc,
    icon_n_a,
    icon_n_a,
    icon_ases,
    icon_asei,
  ],
  [ //Ad-noc - No Set
    icon_ansg,
    icon_ansc,
    icon_n_a,
    icon_n_a,
    icon_n_a,
    icon_ansei,
  ],
];
