import React from 'react';

import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';

import Tile from '../components/Tile'


function TileGrid ({content}) {
  
  return (
	<Grid container justifyContent="center">
      <Stack spacing={2}>
	    {content.map((row) => (
	      <Grid container justifyContent="center">
            <Stack direction="row" spacing={2}>
		      {row.map((item) => (
		        <Tile
		          title = {item[0]}
			      content = {item[1]}
			      width = {item[2]}
			      imageUrl = {null}
			      imageAlt = {null}
		        />
	          ))}
		    </Stack>
	      </Grid>
	    ))}
      </Stack>
	</Grid>
  );
}

export default TileGrid;
